import { pageRoutes, tokens } from "../constants/constants";

const executeApi = async (apiFunction, request) => {
    try {
        const response = await (request ? apiFunction(request) : apiFunction());
        const { data } = response;
        return data;
    } catch (ex) {
        console.log('ex', ex)
        let errorResponse = {
            success: false,
            message: ex.response?.data.message ? ex.response?.data.message : ex.response?.statusText ? ex.response?.statusText : ex?.message ? ex?.message : "",
            data: []
        }
        if (ex.response?.status === 401) {
            errorResponse.message = "Token Expired. Please login again";
            localStorage.removeItem(tokens.AUTH_TOKEN);
            localStorage.removeItem(tokens.EMAIL);
            localStorage.removeItem(tokens.NAME);
            window.location.reload(pageRoutes.LOGIN);
        }
        return errorResponse
    }
};

export const executeAssessmentAPI = async (apiFunction, request) => {
    try {
        const response = await executeApi(apiFunction, request);
        return response;
    } catch (error) {
        console.error('Error executing API:', error);
        return { success: false, message: 'Error occurred while executing API' };
    }
};
