import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FormText from "../../shared/components/form/FormText";
import { useForm } from "react-hook-form";
import { validEmail } from "../../shared/utils/validation";
import { useUserManagement } from "../../shared/hooks-context/user-management-context-hook";
import { Spinner } from "react-bootstrap";
import notify from "devextreme/ui/notify";

const AddUserModal = (props) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange", defaultValues: { id: 0 } });
  const { isLoading, createUser } = useUserManagement();

  const onClearModal = () => {
    props.onHide();
    reset();
  };

  //   const onAddUser = async (formData) => {
  //     const response = await createUser(formData);
  //     if (response?.success) {
  //       onClearModal();
  //     }
  //   };

  //   const onAddUser = async (formData) => {
  //     const response = await createUser(formData);
  //     if (response?.success) {
  //       notify({
  //         message: "User added successfully!",
  //         position: {
  //           my: "center top",
  //           at: "center top",
  //         },
  //         width: "auto",
  //         shading: false,
  //       });
  //       onClearModal();
  //     } else {
  //       notify({
  //         message: "Failed to add user.",
  //         position: {
  //           my: "center top",
  //           at: "center top",
  //         },
  //         width: "auto",
  //         shading: false,
  //       });
  //     }
  //   };

  const onAddUser = async (formData) => {
    const response = await createUser(formData);
    if (response?.success) {
      notify("User added successfully!", "success", 5000);
      onClearModal();
    } else {
      notify("Something went wrong! Try again.", 5000);
    }
  };

  return (
    <Modal
      {...props}
      onHide={onClearModal}
      dialogClassName="modal-830w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <FormText
            type="text"
            name="name"
            label="User Name"
            placeholder="Enter User Name"
            register={register}
            maxLength={50}
            validationSchema={{
              required: "Username is required",
            }}
            errors={errors}
          />
          <FormText
            type="email"
            name="username"
            label="Email"
            placeholder="Enter Email Address"
            register={register}
            maxLength={255}
            validationSchema={{
              required: "Email is required",
              validate: (value) => validEmail(value),
            }}
            errors={errors}
          />
          <div className="">
            <Button
              variant="secondary px-4 d-block me-4"
              disabled={!isDirty || !isValid || isLoading?.isLoading}
              onClick={handleSubmit(onAddUser)}
            >
              {isLoading?.isLoading && (
                <Spinner
                  className="me-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Add User
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddUserModal;
