import React, { useEffect, useState } from "react";
import "./App.css";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "./components/auth/Login";
import Layout from "./components/layout/Layout";
import { routes } from "./routes";
import { pageRoutes, tokens } from "./shared/constants/constants";
import ForgotPassword from "./components/auth/ForgotPassword";
import PasswordSuccessfully from "./components/auth/PasswordSuccessfully";
import ChangePassword from "./components/auth/ChangePassword";
import AppLoading from "./shared/components/loader/AppLoader";

function App() {
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem(tokens.AUTH_TOKEN);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleBeforeUnload = () => {
      setLoading(true);
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const handleLoad = () => {
      setLoading(false);
    };
    window.addEventListener("load", handleLoad);
    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  useEffect(() => {
    if (location.pathname) {
      setLoading(false);
    }
  }, [location.pathname])

  useEffect(() => {
    if (!token) {
      if (location?.pathname === pageRoutes.FORGOT_PASSWORD) {
        navigate(pageRoutes.FORGOT_PASSWORD);
      } else if (location?.pathname === pageRoutes.CHANGED_PASSWORD) {
        navigate(pageRoutes.CHANGED_PASSWORD);
      } else if (location?.pathname === pageRoutes.CHANGED_PASSWORD_SUCCESSFULLY) {
        navigate(pageRoutes.CHANGED_PASSWORD_SUCCESSFULLY);
      } else {
        navigate(pageRoutes.LOGIN);
      }
    } else if (token && location?.pathname === pageRoutes.LOGIN) {
      navigate(pageRoutes.COCKPIT);
    }
  }, [token, location?.pathname, navigate])

  return (
    <Routes>
      <Route path={pageRoutes.LOGIN} exact={true} element={<Login />} />
      <Route path={pageRoutes.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={pageRoutes.CHANGED_PASSWORD_SUCCESSFULLY} element={<PasswordSuccessfully />} />
      <Route path={pageRoutes.CHANGED_PASSWORD} element={<ChangePassword />} />
      {routes.map((route) => {
        return (
          <Route
            key={route.id}
            path={route.path}
            element={loading ? <AppLoading /> : (
              <Layout>
                <route.component />
              </Layout>
            )}
          />
        );
      })}
      <Route path="*" element={<Navigate replace to={token ? pageRoutes.COCKPIT : pageRoutes.LOGIN} />} />
    </Routes>
  );
}

export default App;
