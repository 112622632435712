import FormText from "../../../shared/components/form/FormText";

const Sftp = ({ register, errors, isHidePwd, setIsHidePwd }) => {
    return (
        <>
            <FormText
                type="text"
                name="host"
                label="Host"
                placeholder="Enter Host Address"
                maxLength={260}
                register={register}
                validationSchema={{
                    required: "Host is required.",
                }}
                errors={errors}
                inlineForm />
            <FormText
                type="text"
                name="path"
                label="Path"
                placeholder="Enter Path Address"
                maxLength={260}
                register={register}
                validationSchema={{
                    required: "Path is required.",
                }}
                errors={errors}
                inlineForm />
            <FormText
                type="text"
                name="username"
                label="Username"
                placeholder="Enter Username"
                maxLength={50}
                register={register}
                validationSchema={{
                    required: "Username is required.",
                }}
                errors={errors}
                inlineForm />
            <FormText
                type="password"
                name="password"
                label="Password"
                placeholder="Enter Password"
                register={register}
                validationSchema={{
                    required: "Password is required.",
                }}
                errors={errors}
                isHidePwd={isHidePwd}
                setIsHidePwd={setIsHidePwd}
                inlineForm />
        </>
    )
}

export default Sftp;