import { Button, Form } from "react-bootstrap"
import FormText from "../../shared/components/form/FormText"
import { useForm } from "react-hook-form";
import { useAuth } from "../../shared/hooks-context/auth-context-hook";

const ChangePassword = () => {
    const {
        register,
        watch,
        reset,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = useForm({ mode: "onChange" });
    const { isLoading, settingChangePassword } = useAuth();

    const onHandleUpdatePassword = async (formData) => {
        const { confirmNewPassword, ...newPayload } = formData;
        const response = await settingChangePassword({ ...newPayload });
        if (response?.success) {
            reset();
        }
    }

    return (
        <>
            <h3 className="mb-5 fs-5">Change Password</h3>
            <Form className="change-password-form">
                <div className="d-flex align-items-center mb-4">
                    <span className="change-password-form__user-label"><span>Email Address</span><span>:</span></span>
                    <span className="change-password-form__user-input">{localStorage.getItem("email")}</span>
                </div>
                <FormText
                    type="password"
                    name="currentpassword"
                    label="Current Password"
                    placeholder="Enter Current Password"
                    register={register}
                    validationSchema={{
                        required: "Current password is required.",
                    }}
                    errors={errors}
                    inlineForm
                />
                <FormText
                    type="password"
                    name="changepassword"
                    label="New Password"
                    placeholder="Enter New Password"
                    register={register}
                    validationSchema={{
                        required: "New password is required.",
                        validate: (value) => {
                            if (value.length < 8) {
                                return "Password must be at least 8 characters long.";
                            } else if (!/(?=.*[!@#$%^&*])/.test(value)) {
                                return "Password must contain symbols.";
                            }
                            return true; // Return true for valid input
                        },
                    }}
                    errors={errors}
                    inlineForm
                />
                <FormText
                    type="password"
                    name="confirmNewPassword"
                    label="Confirm New Password"
                    placeholder="Enter Confirm New Password"
                    register={register}
                    validationSchema={{
                        required: "Confirm new password is required.",
                        validate: (value) => {
                            if (value !== watch("changepassword")) {
                                return "Must watch the Previous entry";
                            }
                        },
                    }}
                    errors={errors}
                    inlineForm
                />
                <p className="change-password-form__pwd-note">Password must be at least 8 character and contain symbols</p>
                <div className="mt-5">
                    <Button type="submit" variant="secondary" disabled={!isDirty || !isValid || isLoading} onClick={handleSubmit(onHandleUpdatePassword)}>Update Password</Button>
                </div>
            </Form>
        </>
    )
}

export default ChangePassword