import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { reportsTabs } from "../../shared/config/array-config";
import Title from "../../shared/components/Title";
import "../../assets/scss/reports.scss";
import ReportsTableFilter from "./ReportsTableFilter";
import { useEffect, useState } from "react";
import { useScheduler } from "../../shared/hooks-context/scheduler-context-hook";

const Reports = () => {
  const { jobSchedulerLists, getJobScheduler } = useScheduler();
  const [isRefresh, setIsRefresh] = useState(false);

  useEffect(() => {
    let getFetchData = async () => {
      await getJobScheduler();
    };
    getFetchData();
  }, []);

  useEffect(() => {
    if (isRefresh) {
      let getFetchData = async () => {
        await getJobScheduler();
      };
      getFetchData();
    }

    return () => {
      setIsRefresh(false);
    };
  }, [isRefresh]);

  const onRefresh = () => {
    setIsRefresh(true);
  };

  const reports = reportsTabs({ jobSchedulerLists });

  return (
    <>
      <Title title="Reports" />
      <Tab.Container id="left-tabs-example" defaultActiveKey="All">
        <div className="report-tabs d-flex">
          <Nav variant="pills" className="flex-column report-tabs__tab-card">
            <h3 className="report-tabs__tab-title">Jobs</h3>
            {reports?.map((reportsTab, index) => {
              return (
                <Nav.Item key={index}>
                  <Nav.Link
                    eventKey={reportsTab?.tabName}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <span>{reportsTab?.tabName}</span>
                    <span className="report-tabs__records-box">
                      {reportsTab?.records}
                    </span>
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
          <Tab.Content className="report-tabs__tab-content">
            {reports?.map((reportsTab, index) => {
              return (
                <Tab.Pane key={index} eventKey={reportsTab?.tabName}>
                  <ReportsTableFilter
                    data={reportsTab?.data}
                    tabName={reportsTab?.tabName}
                    onRefresh={onRefresh}
                  />
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </div>
      </Tab.Container>
    </>
  );
};

export default Reports;
