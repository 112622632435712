import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ActionModal from '../../shared/components/modal/ActionModal';
import { useEffect, useState } from 'react';
import { useScheduler } from '../../shared/hooks-context/scheduler-context-hook';
import FormRadio from '../../shared/components/form/FormRadio';
import { frequencyOptions, radioOptions, repeatModeLists, weekly } from '../../shared/config/array-config';
import FormSelect from '../../shared/components/form/FormSelect';
import FormDateTimePicker from '../../shared/components/form/FormDateTimePicker';
import FormText from '../../shared/components/form/FormText';
import { useForm } from 'react-hook-form';
import { convertBooleanValue, getCurrentDateTime } from '../../shared/shared-method';
import { useDistribution } from '../../shared/hooks-context/distribution-context-hook';
import FormReactSelect from '../../shared/components/form/FormReactSelect';
import { useReportDesigner } from '../../shared/hooks-context/report-designer-context-hook';
import ComposeEmailModal from '../scheduler/ComposeEmailModal';
import AppLoading from '../../shared/components/loader/AppLoader';
import { Spinner } from 'react-bootstrap';

const EditReportModal = (props) => {
    const { id } = props
    const { watch, register, reset, setValue, handleSubmit, formState: { errors } } = useForm({ mode: "onChange", defaultValues: { weekDaysList: [], interval: 0, } });
    const [isUpdateModal, setIsUpdateModal] = useState(false);
    const { sftpLists, getDistributionSFTP } = useDistribution();
    const { isSchedulerLoading, isSingleSchedulerLoading, singleJobScheduler, getSingleJobScheduler, updateJobScheduler } = useScheduler();
    const { timezonesLists, getTimezones } = useReportDesigner();
    const [isSendEmailModal, setIsSendEmailModal] = useState(false);
    const [isClear, setIsClear] = useState(false);
    const [distributionError, setDistributionError] = useState("");
    const distributionStringy = singleJobScheduler?.distributionList && JSON.parse(singleJobScheduler.distributionList);
    const weeklyStringy = singleJobScheduler?.weekDays && JSON.parse(singleJobScheduler.weekDays);

    useEffect(() => {
        if (id) {
            getSingleJobScheduler({ id })
        }
    }, [id])

    useEffect(() => {
        let getFetchData = async () => {
            await getDistributionSFTP();
            await getTimezones();
        };
        getFetchData();
    }, [getDistributionSFTP]);

    useEffect(() => {
        if (singleJobScheduler) {
            reset({
                ...singleJobScheduler,
                isMail: singleJobScheduler.isMail ? "Yes" : "No"
            });
        }
    }, [singleJobScheduler, reset]);


    useEffect(() => {
        if (singleJobScheduler) {
            setValue("weekDaysList", weeklyStringy)
        }
    }, [singleJobScheduler])

    const sftpOptions = sftpLists?.map((data) => ({
        value: data.id,
        label: data.name,
        subValue: "sftp"
    }))

    const distributionLocations = [
        {
            label: "SFTP",
            options: sftpOptions
        }
    ];

    const timezoneList = timezonesLists?.map((data) => ({
        value: data.value,
        label: data.text,
    }))

    const selectedButtons = watch("weekDaysList", []);

    const onResetFormData = () => {
        setIsClear(true);
        reset();
    }

    const onUpdateScheduleReport = async (formData) => {
        console.log('formData', formData)
        let mailDistribution = formData?.mailDistribution;
        if (formData?.isMail === "No" || !formData?.isMail) {
            mailDistribution = {
                to: [],
                subject: "",
                message: "",
                cc: [],
                bcc: []
            };
        }
        const { report, jobHistory, ...newPayload } = formData;
        let response;
        if (((!formData?.distributionStringy || formData?.distributionStringy?.sftp?.length === 0) || (!formData?.Distribution || formData?.Distribution?.sftp?.length === 0)) && (formData.isMail === "No")) {
            setDistributionError("Please select distribution location or email")
        }

        if (formData?.frequency === "ONCE") {
            if (((!formData?.distributionStringy || formData?.distributionStringy?.sftp?.length === 0) || (!formData?.Distribution || formData?.Distribution?.sftp?.length === 0)) && (formData.isMail === "No")) {
                setDistributionError("Please select distribution location or email")
            } else {
                response = await updateJobScheduler({
                    ...newPayload,
                    mailDistribution: mailDistribution,
                    startTime: formData?.startTime ? getCurrentDateTime(formData?.startTime) : null,
                    endTime: formData?.endTime ? getCurrentDateTime(formData?.endTime) : null,
                    reportGenerationTime: formData?.reportGenerationTime ? getCurrentDateTime(formData?.reportGenerationTime) : "",
                    ...convertBooleanValue(['isMail'], watch),
                });
            }
        }

        if (formData?.frequency === "ONCE") {
            if (((!formData?.distributionStringy || formData?.distributionStringy?.sftp?.length === 0) || (!formData?.Distribution || formData?.Distribution?.sftp?.length === 0)) && (formData.isMail === "No")) {
                setDistributionError("Please select distribution location or email")
            } else {
                response = await updateJobScheduler({
                    ...newPayload,
                    mailDistribution: mailDistribution,
                    startTime: formData?.startTime ? getCurrentDateTime(formData?.startTime) : null,
                    endTime: formData?.endTime ? getCurrentDateTime(formData?.endTime) : null,
                    reportGenerationTime: formData?.reportGenerationTime ? getCurrentDateTime(formData?.reportGenerationTime) : "",
                    ...convertBooleanValue(['isMail'], watch),
                });
            }
        }

        if (response?.success) {
            onResetFormData();
            props.onHide();
        }
    };

    const onChangeRadio = (value) => {
        if (value === "Yes") {
            setIsSendEmailModal(true);
            setDistributionError("");
        }
    }

    const setEmailFormData = (data) => {
        setValue("mailDistribution[to]", data?.to)
        setValue("mailDistribution[subject]", data?.subject)
        setValue("mailDistribution[message]", data?.message)
        setValue("mailDistribution[cc]", data?.cc ? data?.cc : [])
        setValue("mailDistribution[bcc]", data?.bcc ? data?.bcc : [])
    }

    return (
        <>
            <Modal
                {...props}
                dialogClassName="modal-100w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Job
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${isSingleSchedulerLoading && "d-flex align-items-center justify-content-center"}`} style={{ height: isSingleSchedulerLoading && "500px" }}>
                    {isSingleSchedulerLoading ? <AppLoading /> : <>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-12 card-view">
                                <h5 className="card-label">Report Template</h5>
                                <h6 className="card-data">{singleJobScheduler?.report?.reportName}</h6>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12 card-view">
                                <h5 className="card-label">Report Format</h5>
                                <h6 className="card-data">{singleJobScheduler?.format}</h6>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12 card-view">
                                <h5 className="card-label">Report Name</h5>
                                <h6 className="card-data">{singleJobScheduler?.custom_report_name}</h6>
                            </div>
                        </div>
                        <div className="schedule-report-job-form">
                            <h2 className="schedule-report-job-form__title">Schedule Report</h2>
                            <div className="row pt-5">
                                <div className="col-lg-4 col-12 pb-5">
                                    <FormRadio
                                        type="radio"
                                        name="frequency"
                                        label="Frequency"
                                        radioOptions={frequencyOptions}
                                        setValue={setValue}
                                        register={register}
                                        validationSchema={{
                                            required: "Frequency is required.",
                                        }}
                                        errors={errors}
                                        inlineForm
                                        defaultValues={singleJobScheduler?.frequency}
                                    />
                                    {watch("frequency") === "RECURRING" &&
                                        <>
                                            <FormSelect
                                                name="timezone"
                                                label="Time Zone"
                                                options={timezoneList}
                                                register={register}
                                                validationSchema={{
                                                    required: "TimeZone is required.",
                                                }}
                                                errors={errors}
                                                inlineForm
                                            />
                                            <FormDateTimePicker label="Start Time" inlineForm name="startTime" setValue={setValue} register={register} isClear={isClear} defaultValues={singleJobScheduler?.startTime} />
                                            <FormDateTimePicker label="End Time" inlineForm name="endTime" setValue={setValue} register={register} isClear={isClear} defaultValues={singleJobScheduler?.endTime} />
                                        </>
                                    }
                                </div>
                                <div className="col-lg-4 col-12 pb-5">
                                    {watch("frequency") === "RECURRING" ? <>
                                        <FormSelect
                                            name="repeatMode"
                                            label="Repeat"
                                            options={repeatModeLists}
                                            register={register}
                                            validationSchema={{
                                                required: "Repeat is required.",
                                            }}
                                            errors={errors}
                                            inlineForm
                                        />
                                        <div className=" d-flex align-items-center">
                                            <FormText type="number" widthClass="w-120" label="Repeat Every" name="interval" register={register} inlineForm />
                                            <span className="ms-3 fw-bold mb-4" style={{ color: "#757575" }}>{watch("repeatMode") === "Weekly" ? "Week(s)" : watch("repeatMode") === "Daily" ? "Day(s)" : "Hour(s)"}</span>
                                        </div>
                                        {watch("repeatMode") === "Weekly" &&
                                            <Form.Group className="d-flex align-items-start mb-4 inline-form">
                                                <Form.Label className="inline-form__label">
                                                    <span>Repeat On</span><span>:</span>
                                                </Form.Label>
                                                <div className="schedule-report-job-form__controls d-flex align-items-center">
                                                    <div className="weekly-name d-flex align-items-center flex-wrap">
                                                        {weekly.map((week, index) => (
                                                            <Button
                                                                key={index}
                                                                className={`weekly-name__btn ${selectedButtons?.includes(week.value) ? "active" : ""}`}
                                                                variant="outline-primary"
                                                                onClick={() => {
                                                                    const newSelectedButtons = selectedButtons?.includes(week.value)
                                                                        ? selectedButtons.filter((btn) => btn !== week.value)
                                                                        : [...selectedButtons, week.value];
                                                                    setValue("weekDaysList", newSelectedButtons);
                                                                }}
                                                            >
                                                                {week.label}
                                                            </Button>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        }
                                    </> :
                                        <>
                                            <FormSelect
                                                name="timezone"
                                                label="Time Zone"
                                                options={timezoneList}
                                                register={register}
                                                validationSchema={{
                                                    required: "TimeZone is required.",
                                                }}
                                                errors={errors}
                                                inlineForm
                                            />
                                            <FormDateTimePicker label="Report Generation Time" inlineForm name="reportGenerationTime" setValue={setValue} register={register} isClear={isClear} defaultValues={singleJobScheduler?.reportGenerationTime} />
                                        </>
                                    }
                                </div>
                                <div className="col-lg-4 col-12 pb-5">
                                    <FormReactSelect label="Distribution Location" inlineForm name="Distribution" options={distributionLocations} placeholder="Select Distribution" setValue={setValue} register={register} setError={setDistributionError} isClear={isClear} setIsClear={setIsClear} defaultValues={distributionStringy} />
                                    <FormRadio
                                        type="radio"
                                        name="isMail"
                                        label="Send Email"
                                        radioOptions={radioOptions}
                                        register={register}
                                        inlineForm
                                        setValue={setValue}
                                        onChangeRadio={onChangeRadio}
                                        isReset={isClear}
                                        defaultValues={singleJobScheduler?.isMail ? "Yes" : "No"}
                                    />
                                    {distributionError && (
                                        <p className={`text-danger text-sm pt-1`}>{distributionError}</p>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 d-flex align-items-center">
                                    <Button variant="secondary me-4" onClick={handleSubmit(onUpdateScheduleReport)} disabled={isSchedulerLoading.isLoading}>{isSchedulerLoading.isLoading && <Spinner
                                        className="me-2"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />}Update</Button>
                                    <Button variant="secondary" onClick={() => onResetFormData()}>Reset</Button>
                                </div>
                            </div>
                        </div>
                    </>}

                </Modal.Body>
            </Modal>
            <ComposeEmailModal show={isSendEmailModal} setEmailFormData={setEmailFormData} setIsSendEmailModal={setIsSendEmailModal} isMail={watch("isMail")}
                onHide={() => {
                    setIsSendEmailModal(false);
                    setValue("isMail", "No")
                }} />
            <ActionModal show={isUpdateModal}
                onHide={() => setIsUpdateModal(false)} />
        </>
    )
}

export default EditReportModal