import PropTypes from 'prop-types';
import React, { createContext, useContext, useCallback, useState } from "react";
import { executeAssessmentAPI } from "./base-context";
import { distributionAPI } from "../api/apis";
import { notificationType } from '../constants/constants';
import { useNotification } from './notification-context-hook';

export const DistributionContext = createContext();

export const DistributionProvider = ({ children }) => {
  const { addMessage } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [sftpLists, setSftpLists] = useState([]);
  const [sftpData, setSftpData] = useState(null);
  const [mailLists, setMailLists] = useState([]);
  const [mailData, setMailData] = useState(null);

  const getDistributionSFTP = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(distributionAPI.getDistributionSFTP, request);
      if (result?.success) {
        setSftpLists(result?.data);
      } else {
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.error('Error fetching report designer list:', error);
    }
  }, []);
  const getSingleDistributionSFTP = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(distributionAPI.getDistributionSFTP, request);
      if (result?.success) {
        setSftpData(result?.data);
      } else {
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.error('Error fetching report designer list:', error);
    }
  }, []);

  const createDistributionSFTP = useCallback(async (request) => {
    setIsLoading(true);
    try {
      const result = await executeAssessmentAPI(distributionAPI.createDistributionSFTP, request);
      if (result?.success) {
        getDistributionSFTP();
        setIsLoading(false);
        return result;
      } else {
        setIsLoading(false);
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.error('Error fetching report designer list:', error);
    }
  }, [getDistributionSFTP]);

  const updateDistributionSFTP = useCallback(async (request) => {
    setIsLoading(true);
    try {
      const result = await executeAssessmentAPI(distributionAPI.updateDistributionSFTP, request);
      if (result?.success) {
        setIsLoading(false);
        getDistributionSFTP();
        return result;
      } else {
        setIsLoading(false);
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.error('Error fetching report designer list:', error);
    }
  }, [getDistributionSFTP]);

  const deleteDistributionSFTP = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(distributionAPI.deleteDistributionSFTP, request);
      if (result?.success) {
        getDistributionSFTP();
        return result;
      } else {
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.error('Error fetching report designer list:', error);
    }
  }, [getDistributionSFTP]);

  const getDistributionMail = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(distributionAPI.getDistributionMail, request);
      if (result?.success) {
        setMailLists(result?.data);
      } else {
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.error('Error fetching report designer list:', error);
    }
  }, []);

  const getSingleDistributionMail = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(distributionAPI.getDistributionMail, request);
      if (result?.success) {
        setMailData(result?.data);
      } else {
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.error('Error fetching report designer list:', error);
    }
  }, []);

  const createDistributionMail = useCallback(async (request) => {
    setIsLoading(true);
    try {
      const result = await executeAssessmentAPI(distributionAPI.createDistributionMail, request);
      if (result?.success) {
        setIsLoading(false);
        getDistributionMail();
        return result;
      } else {
        setIsLoading(false);
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.error('Error fetching report designer list:', error);
    }
  }, [getDistributionMail]);

  const updateDistributionMail = useCallback(async (request) => {
    setIsLoading(true);
    try {
      const result = await executeAssessmentAPI(distributionAPI.updateDistributionMail, request);
      if (result?.success) {
        setIsLoading(false);
        getDistributionMail();
        return result;
      } else {
        setIsLoading(false);
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.error('Error fetching report designer list:', error);
    }
  }, [getDistributionMail]);

  const defaultDistributionMail = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(distributionAPI.defaultDistributionMail, request);
      if (result?.success) {
        getDistributionMail();
        return result;
      } else {
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.error('Error fetching report designer list:', error);
    }
  }, [getDistributionMail]);

  const deleteDistributionMail = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(distributionAPI.deleteDistributionMail, request);
      if (result?.success) {
        getDistributionMail();
        return result;
      } else {
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.error('Error fetching report designer list:', error);
    }
  }, [getDistributionMail]);


  const contextValue = {
    isLoading,
    sftpLists,
    sftpData,
    mailLists,
    mailData,
    getDistributionSFTP,
    getSingleDistributionSFTP,
    createDistributionSFTP,
    updateDistributionSFTP,
    deleteDistributionSFTP,
    getDistributionMail,
    getSingleDistributionMail,
    createDistributionMail,
    updateDistributionMail,
    defaultDistributionMail,
    deleteDistributionMail
  };

  return (
    <DistributionContext.Provider value={contextValue}>
      {children}
    </DistributionContext.Provider>
  )
};

DistributionProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useDistribution = () => useContext(DistributionContext);
