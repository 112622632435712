import PropTypes from 'prop-types';
import React, { createContext, useContext, useState, useCallback } from "react";
import { executeAssessmentAPI } from "./base-context";
import { connectionAPI, reportDesignerAPI } from "../api/apis";
import { useNotification } from './notification-context-hook';
import { notificationType } from '../constants/constants';

export const ReportDesignerContext = createContext();

export const ReportDesignerProvider = ({ children }) => {
  const { addMessage } = useNotification();
  const [reportDesignerData, setReportDesignerData] = useState([]);
  const [userReportsDesignerLists, setUserReportsDesignerLists] = useState([]);
  const [reportCheckConnection, setReportCheckConnection] = useState(null);
  const [timezonesLists, setTimezonesLists] = useState([]);
  const [isReportDesignerLoading, setIsReportDesignerLoading] = useState({ isList: false, isLoading: false });

  const getReportDesignerList = useCallback(async (request) => {
    setIsReportDesignerLoading({ isList: true, isLoading: false });
    try {
      const result = await executeAssessmentAPI(reportDesignerAPI.getReportDesigner, request);
      if (result?.success) {
        setIsReportDesignerLoading({ isList: false, isLoading: false });
        setReportDesignerData(result?.data);
      } else {
        setIsReportDesignerLoading({ isList: false, isLoading: false });
        setReportDesignerData([]);
        addMessage({ message: result?.message, type: notificationType.ERROR });
      }
    } catch (error) {
      setIsReportDesignerLoading({ isList: false, isLoading: false });
      console.error('Error fetching report designer list:', error);
    }
  }, []);

  const getUserReportsDesigner = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(reportDesignerAPI.getUserReportsDesigner, request);
      if (result?.success) {
        setUserReportsDesignerLists(result?.data);
      } else {
        setUserReportsDesignerLists([]);
        addMessage({ message: result?.message, type: notificationType.ERROR });
      }
    } catch (error) {
      console.error('Error fetching report user Reports list:', error);
    }
  }, []);

  const getCheckConnection = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(reportDesignerAPI.getCheckConnection, request);
      if (result) {
        setReportCheckConnection(result);
        return result;
      }
    } catch (error) {
      console.error('Error fetching report user Reports list:', error);
    }
  }, []);

  const getTimezones = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(reportDesignerAPI.getTimezones, request);
      if (result?.success) {
        setTimezonesLists(result?.data);
      } else {
        setTimezonesLists([]);
        addMessage({ message: result?.message, type: notificationType.ERROR });
      }
    } catch (error) {
      console.error('Error fetching report user Reports list:', error);
    }
  }, []);

  const copyReportDesigner = useCallback(async (request) => {
    setIsReportDesignerLoading({ isList: false, isLoading: true });
    try {
      const result = await executeAssessmentAPI(reportDesignerAPI.copyReportDesigner, request);
      if (result.success) {
        setIsReportDesignerLoading({ isList: false, isLoading: false });
        await getReportDesignerList();
        return result;
      } else {
        setIsReportDesignerLoading({ isList: false, isLoading: false });
        addMessage({ message: result?.message, type: notificationType.ERROR });
      }
    } catch (error) {
      setIsReportDesignerLoading({ isList: false, isLoading: false });
      console.error('Error copying report designer:', error);
    }
  }, [getReportDesignerList]);

  const sendMailReportDesigner = useCallback(async (request) => {
    setIsReportDesignerLoading({ isList: false, isLoading: true });
    try {
      const result = await executeAssessmentAPI(reportDesignerAPI.sendMailReportDesigner, request);
      if (result.success) {
        setIsReportDesignerLoading({ isList: false, isLoading: false });
        addMessage({ message: result?.message, type: notificationType.SUCCESS });
        return result;
      } else {
        setIsReportDesignerLoading({ isList: false, isLoading: false });
        addMessage({ message: result?.message, type: notificationType.ERROR });
      }
    } catch (error) {
      setIsReportDesignerLoading({ isList: false, isLoading: false });
      console.error('Error copying report designer:', error);
    }
  }, [getReportDesignerList]);

  const deleteReportDesigner = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(reportDesignerAPI.deleteReportDesigner, request);
      if (result.success) {
        await getReportDesignerList();
        return result;
      } else {
        addMessage({ message: result?.message, type: notificationType.ERROR });
      }
    } catch (error) {
      console.error('Error deleting report designer:', error);
    }
  }, [getReportDesignerList]);

  const updateConnection = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(connectionAPI.updateConnection, request);
      if (result.success) {
        return result;
      } else {
        addMessage({ message: result?.message, type: notificationType.ERROR });
      }
    } catch (error) {
      console.error('Error copying report designer:', error);
    }
  })

  const contextValue = {
    reportDesignerData,
    userReportsDesignerLists,
    timezonesLists,
    isReportDesignerLoading,
    reportCheckConnection,
    setReportCheckConnection,
    getReportDesignerList,
    getUserReportsDesigner,
    getCheckConnection,
    getTimezones,
    copyReportDesigner,
    sendMailReportDesigner,
    deleteReportDesigner,
    updateConnection
  };

  return (
    <ReportDesignerContext.Provider value={contextValue}>
      {children}
    </ReportDesignerContext.Provider>
  )
};

ReportDesignerProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useReportDesigner = () => useContext(ReportDesignerContext);
