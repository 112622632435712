import { Button, Form } from "react-bootstrap";
import deleteIcon from "../../assets/images/delete-icon.svg";
import ReactDataTable from "../../shared/components/table/ReactDataTable";
import { SMTPConfigurationsColumns, lookupDatabaseColumns } from "../../shared/constants/data-columns";
import { useCallback, useEffect, useMemo, useState } from "react";
import SearchFilter from "../../shared/components/table/SearchFilter";
import FormText from "../../shared/components/form/FormText";
import { useForm } from "react-hook-form";
import ReactSweetAlert from "../../shared/components/ReactSweetAlert";
import { validEmail } from "../../shared/utils/validation";
import { useDistribution } from "../../shared/hooks-context/distribution-context-hook";
import { useLookupDatabase } from "../../shared/hooks-context/lookup-database-context-hook";
import AddLookupDatabaseModal from "./AddLookupDatabaseModal";
import { Spinner } from "react-bootstrap";

const SMTP = () => {
    const {
        register, reset, handleSubmit,
        formState: { errors, isDirty, isValid },
    } = useForm({ mode: "onChange" });
    const { isLoading, mailLists, mailData, getDistributionMail, createDistributionMail, updateDistributionMail, deleteDistributionMail, defaultDistributionMail, getSingleDistributionMail } = useDistribution();
    const { isLookupDatabaseLoading, lookDatabaseLists, getLookupDatabase, deleteLookupDatabase } = useLookupDatabase();
    const [isLookupDatabaseDeleteModal, setIsLookupDatabaseDeleteModal] = useState(false);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [lookupDatabaseId, setLookupDatabaseId] = useState([]);
    const [sMTPDeleteModal, setSMTPDeleteModal] = useState({ isModal: false, id: null, name: "" });
    const [sMTPId, setSMTPId] = useState(null);
    const [filterText, setFilterText] = useState("");
    const [isAddUserModal, setIsAddUserModal] = useState(false);
    const [isHidePwd, setIsHidePwd] = useState(false);

    const filteredItems = lookDatabaseLists?.filter((item) => {
        return item?.username?.toLowerCase().includes(filterText.toLowerCase()) ||
            item?.name?.toLowerCase().includes(filterText.toLowerCase());
    });

    useEffect(() => {
        let getFetchData = async () => {
            await getDistributionMail();
            await getLookupDatabase();
        };
        getFetchData();
    }, [])

    useEffect(() => {
        if (sMTPId) {
            let getFetchData = async () => {
                await getSingleDistributionMail({ id: sMTPId });
            };
            getFetchData();
        }
    }, [sMTPId])

    useEffect(() => {
        if (mailData) {
            reset(mailData)
        }
    }, [mailData])

    const onFilter = (value) => {
        setFilterText(value);
    };

    const onResetFormData = () => {
        reset({
            host: "",
            port: "",
            mailtype: "",
            maxsize: "",
            username: "",
            password: "",
            frommail: "",
            name: "",
        })
        setSMTPId(null);
        setIsHidePwd(true);
    }

    const onCreateSMTP = async (formData) => {
        const response = await createDistributionMail(formData)
        if (response?.success) {
            reset();
        }
    }

    const onEditSMTP = (id) => {
        setSMTPId(id);
        setIsHidePwd(true);
    }

    const onUpdateSMTP = async (formData) => {
        const response = await updateDistributionMail(formData)
        if (response?.success) {
            onResetFormData();
        }
    }

    const onDeleteSMTP = (item) => {
        setSMTPDeleteModal({
            isModal: true,
            id: item?.id,
            name: item?.name
        })
    }

    const onCancelSMTPDelete = async () => {
        setSMTPDeleteModal({ isModal: false, id: null, name: "" })
    }

    const onConfirmSMTPDelete = async () => {
        const response = await deleteDistributionMail(sMTPDeleteModal?.id);
        if (response?.success) {
            onCancelSMTPDelete();
        }
    }

    const onOpenLookupDatabaseDeleteModal = (id) => {
        if (id) {
            setLookupDatabaseId([id]);
        }
        setIsLookupDatabaseDeleteModal(true);
    }

    const handleRowSelected = useCallback(state => {
        setLookupDatabaseId(state.selectedRows.map(selectedRow => selectedRow.id));
    }, []);

    const onOpenLookupDatabaseMultipleDeleteModal = () => {
        setIsLookupDatabaseDeleteModal(true)
    }

    const onCancelLookupDatabaseModal = () => {
        setIsLookupDatabaseDeleteModal(false);
        setToggleCleared(!toggleCleared);
        setLookupDatabaseId([]);
    }

    const onDeleteLookupDatabase = async () => {
        const response = await deleteLookupDatabase(lookupDatabaseId);
        if (response?.success) {
            onCancelLookupDatabaseModal();
        }
    }

    const onDefaultDistributionSMTP = async (id, isDefault) => {
        if (!isDefault) {
            await defaultDistributionMail({ id });
        }
    }

    const subHeaderComponent = useMemo(() => {
        return (
            <div className="d-flex align-items-center justify-content-between w-100">
                <div className="text-start pe-3">
                    <Button variant="secondary" onClick={() => setIsAddUserModal(true)}>Add User</Button>
                </div>
                <div className="d-flex align-items center justify-content-end">
                    <Button className="delete-btn" title="Delete" disabled={lookupDatabaseId?.length === 0 ? true : false} onClick={onOpenLookupDatabaseMultipleDeleteModal}><img src={deleteIcon} alt="Delete Icon" /></Button>
                    <Form className="d-flex align-items center">
                        <SearchFilter
                            onFilter={onFilter}
                            filterText={filterText}
                            placeholder="Search Name/Email Address"
                        />
                    </Form>
                </div>
            </div>
        );
    }, [filterText, lookupDatabaseId, isLookupDatabaseDeleteModal]);

    const columns = lookupDatabaseColumns({ onOpenLookupDatabaseDeleteModal });
    const SMTPColumns = SMTPConfigurationsColumns({ onEditSMTP, onDeleteSMTP, onDefaultDistributionSMTP });

    return (
        <>
            <div className="row">
                <div className="col-lg-5 col-md-4 col-12">
                    <h2 className="sub-title mb-3">Add New SMTP</h2>
                    <Form className="add-distribution-form" autoComplete="off">
                        <FormText
                            type="text"
                            name="host"
                            label="Host"
                            placeholder="Enter Host Address"
                            maxLength={260}
                            register={register}
                            validationSchema={{
                                required: "Host is required.",
                            }}
                            errors={errors}
                            inlineForm />
                        <FormText
                            type="number"
                            name="port"
                            label="Port"
                            placeholder="Enter Port Number"
                            maxLength={65535}
                            register={register}
                            validationSchema={{
                                required: "Port is required.",
                            }}
                            errors={errors}
                            inlineForm />
                        <FormText
                            type="text"
                            name="mailtype"
                            label="Type"
                            placeholder="Enter Type"
                            register={register}
                            validationSchema={{
                                required: "Type is required.",
                            }}
                            errors={errors}
                            inlineForm />
                        <FormText
                            type="number"
                            name="maxsize"
                            label="Maximum File Size (MB)"
                            placeholder="Enter File Size"
                            register={register}
                            validationSchema={{
                                required: "File size is required.",
                            }}
                            errors={errors}
                            inlineForm />
                        <FormText
                            type="text"
                            name="username"
                            label="Username"
                            placeholder="Enter Username"
                            maxLength={50}
                            register={register}
                            validationSchema={{
                                required: "Username is required.",
                            }}
                            errors={errors}
                            inlineForm />
                        <FormText
                            type="password"
                            name="password"
                            label="Password"
                            placeholder="Enter Password"
                            register={register}
                            validationSchema={{
                                required: "Password is required.",
                            }}
                            errors={errors}
                            isHidePwd={isHidePwd}
                            setIsHidePwd={setIsHidePwd}
                            inlineForm />
                        <FormText
                            type="email"
                            name="frommail"
                            label="From Email"
                            placeholder="Enter From Email"
                            maxLength={255}
                            register={register}
                            validationSchema={{
                                required: "Email is required.",
                                validate: (value) => validEmail(value),
                            }}
                            errors={errors}
                            inlineForm />
                        <FormText
                            type="text"
                            name="name"
                            label="Save Configuration As"
                            placeholder="Enter Configuration Name"
                            maxLength={50}
                            register={register}
                            validationSchema={{
                                required: "Configuration name is required.",
                            }}
                            errors={errors}
                            inlineForm />
                        <div className="d-flex align-items-center mt-4">
                            <Button type="submit" variant="secondary me-3 px-3" disabled={!isDirty || !isValid || isLoading} onClick={handleSubmit(sMTPId ? onUpdateSMTP : onCreateSMTP)}>
                                {sMTPId ? (
                                    <>
                                        {isLoading && (
                                            <Spinner
                                                className="me-2"
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        )}
                                        Update
                                    </>
                                ) : (
                                    <>
                                        {isLoading && (
                                            <Spinner
                                                className="me-2"
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        )}
                                        Save
                                    </>
                                )}
                            </Button>
                            <Button variant="secondary px-3" onClick={onResetFormData}>Reset</Button>
                        </div>
                    </Form>
                </div>
                <div className="col-lg-7 col-md-8 col-12">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="sub-title mb-3">Saved Configurations</h2>
                            <div className="custom-table">
                                <ReactDataTable
                                    columns={SMTPColumns}
                                    data={mailLists}
                                />
                            </div>
                        </div>
                        <div className="col-12 lookup-database">
                            <h2 className="sub-title">Lookup Database</h2>
                            <div className="card-table boxshadow-none p-0">
                                <ReactDataTable
                                    columns={columns}
                                    data={filteredItems}
                                    subHeader={true}
                                    selectableRows
                                    onSelectedRowsChange={handleRowSelected}
                                    clearSelectedRows={toggleCleared}
                                    subHeaderComponent={subHeaderComponent}
                                    progressPending={isLookupDatabaseLoading?.isList}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddLookupDatabaseModal show={isAddUserModal} onHide={() => setIsAddUserModal(false)} />
            {sMTPDeleteModal.isModal && <ReactSweetAlert onDeleteModal={onConfirmSMTPDelete} onCancelModal={onCancelSMTPDelete} name={sMTPDeleteModal?.name} />}
            {isLookupDatabaseDeleteModal && <ReactSweetAlert onDeleteModal={onDeleteLookupDatabase} onCancelModal={onCancelLookupDatabaseModal} name="Lookup Database" />}
        </>
    )
}

export default SMTP;