import cockpitIcon from "../../assets/images/cockpit.png";
import reportsIcon from "../../assets/images/reports.png";
import schedulerIcon from "../../assets/images/scheduler.png";
import reportResignerIcon from "../../assets/images/report-designer.png";
import configureIcon from "../../assets/images/configure.png";
import userManagementIcon from "../../assets/images/user-management.png";
import { pageRoutes } from "../constants/constants";

export const SidebarConfig = [
    {
        name: "Cockpit",
        icon: cockpitIcon,
        path: pageRoutes.COCKPIT,
    },
    {
        name: "Reports",
        icon: reportsIcon,
        path: pageRoutes.REPORTS,
    },
    {
        name: "Scheduler",
        icon: schedulerIcon,
        path: pageRoutes.SCHEDULER,
    },
    {
        name: "Report Designer",
        icon: reportResignerIcon,
        path: pageRoutes.REPORT_DESIGNER,
    },
    {
        name: "Configure",
        icon: configureIcon,
        path: pageRoutes.CONFIGURE,
    },
    {
        name: "User Management",
        icon: userManagementIcon,
        path: pageRoutes.USER_MANAGEMENT,
    },
]