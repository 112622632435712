import Table from "react-bootstrap/Table";
import tickIcon from "../../assets/images/tick.png";
import copyIcon from "../../assets/images/copy.png";
import closeIcon from "../../assets/images/close.png";
import moment from "moment";
import { convertFileSize, getDataTime } from "../../shared/shared-method";

const ViewReport = ({ jobHistoryScheduler }) => {
  // Sort job history by lastUpdatedOn in descending order
  const sortedJobHistory = jobHistoryScheduler?.jobHistory
    ?.slice()
    .sort((a, b) => new Date(b.id) - new Date(a.id));

  return (
    <div className="px-4">
      <div className="row">
        <div className="col-lg-3 col-md-6 col-12 card-view">
          <h5 className="card-label">Job ID</h5>
          <h6 className="card-data">{jobHistoryScheduler?.id}</h6>
        </div>
        <div className="col-lg-3 col-md-6 col-12 card-view">
          <h5 className="card-label">Report Name</h5>
          <h6 className="card-data">
            {jobHistoryScheduler?.custom_report_name
              ? jobHistoryScheduler?.custom_report_name
              : "__"}
          </h6>
        </div>
        <div className="col-lg-3 col-md-6 col-12 card-view">
          <h5 className="card-label">Report Format </h5>
          <h6 className="card-data">
            {jobHistoryScheduler?.format ? jobHistoryScheduler?.format : "__"}
          </h6>
        </div>
        <div className="col-lg-3 col-md-6 col-12 card-view">
          <h5 className="card-label">Created by</h5>
          <h6 className="card-data">
            {jobHistoryScheduler?.createdby
              ? jobHistoryScheduler?.createdby
              : "__"}
          </h6>
        </div>
        <div className="col-lg-3 col-md-6 col-12 card-view">
          <h5 className="card-label">Job Created on</h5>
          <h6 className="card-data">
            {jobHistoryScheduler?.lastupdatedon
              ? getDataTime(jobHistoryScheduler?.lastupdatedon)
              : "__"}
          </h6>
        </div>
        {jobHistoryScheduler?.frequency === "RECURRING" && (
          <>
            <div className="col-lg-3 col-md-6 col-12 card-view">
              <h5 className="card-label">Start Date</h5>
              <h6 className="card-data">
                {jobHistoryScheduler?.startTime
                  ? moment(jobHistoryScheduler?.startTime).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  : "__"}
              </h6>
            </div>
            <div className="col-lg-3 col-md-6 col-12 card-view">
              <h5 className="card-label">End Date</h5>
              <h6 className="card-data">
                {jobHistoryScheduler?.endTime
                  ? moment(jobHistoryScheduler?.endTime).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  : "__"}
              </h6>
            </div>
          </>
        )}
        <div className="col-lg-3 col-md-6 col-12 card-view">
          <h5 className="card-label">Next Scheduled on</h5>
          <h6 className="card-data">
            {jobHistoryScheduler?.nextSchedule
              ? moment(jobHistoryScheduler?.nextSchedule).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : "__"}
          </h6>
        </div>
        <div className="col-lg-3 col-md-6 col-12 card-view">
          <h5 className="card-label">TimeZone</h5>
          <h6 className="card-data">
            {jobHistoryScheduler?.timezone
              ? jobHistoryScheduler?.timezone
              : "__"}
          </h6>
        </div>
        <div className="col-lg-3 col-md-6 col-12 card-view">
          <h5 className="card-label">Database Name</h5>
          <h6 className="card-data">
            {jobHistoryScheduler?.report?.dataSourceConnection?.database
              ? jobHistoryScheduler?.report?.dataSourceConnection?.database
              : "__"}
          </h6>
        </div>
        <div className="col-lg-3 col-md-6 col-12 card-view">
          <h5 className="card-label">Schema Name</h5>
          <h6 className="card-data">
            {jobHistoryScheduler?.report?.dataSourceConnection?.schema
              ? jobHistoryScheduler?.report?.dataSourceConnection?.schema
              : "__"}
          </h6>
        </div>
        <div className="col-lg-3 col-md-6 col-12 card-view">
          <h5 className="card-label">Table Name</h5>
          <h6 className="card-data">
            {jobHistoryScheduler?.report?.dataSourceConnection?.tableList
              ? jobHistoryScheduler?.report?.dataSourceConnection?.tableList?.join(
                  ", "
                )
              : "__"}
          </h6>
        </div>
        <div className="col-12">
          <div className="job-history mt-4">
            <h2 className="job-history__title">Job History</h2>
            <div className="custom-table job-history__schedule-report-table">
              <Table className="mb-0">
                <thead>
                  <tr>
                    <th>Generation time</th>
                    <th>File Size</th>
                    <th>Sent to Email</th>
                    <th>Sent to Distribution</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedJobHistory?.length > 0 ? (
                    sortedJobHistory.map((job, index) => {
                      return (
                        <tr
                          key={index}
                          className={`${
                            job?.status === "SUCCESS"
                              ? "report-success"
                              : "report-failed"
                          }`}
                        >
                          <td>
                            {job?.lastUpdatedOn
                              ? getDataTime(job?.lastUpdatedOn)
                              : "__"}
                          </td>
                          <td>
                            {job?.distributionResultObj?.fileSize
                              ? convertFileSize(
                                  job?.distributionResultObj?.fileSize
                                )
                              : "NA"}
                          </td>
                          <td>
                            {job?.distributionResultObj?.smpt
                              ? Object.keys(
                                  job?.distributionResultObj?.smpt
                                ).map((email) => (
                                  <div
                                    className="d-flex align-items-center mb-2"
                                    key={email}
                                  >
                                    <img
                                      src={
                                        Boolean(
                                          job?.distributionResultObj?.smpt[
                                            email
                                          ]
                                        )
                                          ? tickIcon
                                          : closeIcon
                                      }
                                      alt="Tick Icon"
                                      className="me-3"
                                    />
                                    <img
                                      onClick={() =>
                                        navigator.clipboard.writeText(email)
                                      }
                                      title="Copy"
                                      src={copyIcon}
                                      alt="Copy Icon"
                                      className="me-3 cursor-pointer"
                                    />
                                    <span className="fw-bold">{email}</span>
                                  </div>
                                ))
                              : "NA"}
                          </td>
                          <td>
                            {job?.distributionResultObj?.distributions?.sftp
                              ? Object.keys(
                                  job?.distributionResultObj?.distributions
                                    ?.sftp
                                ).map((sftpKey) => {
                                  return (
                                    <div
                                      className="d-flex align-items-center mb-2"
                                      key={sftpKey}
                                    >
                                      <img
                                        src={
                                          Boolean(
                                            job?.distributionResultObj
                                              ?.distributions?.sftp[sftpKey]
                                          )
                                            ? tickIcon
                                            : closeIcon
                                        }
                                        alt="Tick Icon"
                                        className="me-3"
                                      />
                                      <img
                                        onClick={() =>
                                          navigator.clipboard.writeText(sftpKey)
                                        }
                                        title="Copy"
                                        src={copyIcon}
                                        alt="Copy Icon"
                                        className="me-3 cursor-pointer"
                                      />
                                      <span className="fw-bold">{sftpKey}</span>
                                    </div>
                                  );
                                })
                              : "NA"}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <td colspan="4">
                      <tr className="d-block text-center">
                        There are no records to display
                      </tr>
                    </td>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewReport;
