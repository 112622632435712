const apiUrls = {};

const baseUrl = process.env.REACT_APP_API_URL;

const authenticate = {
  userLogin: `${baseUrl}/user/login`,
  userForgotPassword: `${baseUrl}/user/ForgotPassword`,
  userChangePassword: `${baseUrl}/user/ChangePassword`,
};
apiUrls.authenticate = authenticate;

//Connection
const connection = {
  connection: `${baseUrl}/connection`,
};
apiUrls.connection = connection;

//Report-Designer
const reportDesigner = {
  report: `${baseUrl}/report`,
  copyReportDesigner: `${baseUrl}/report/clone`,
  sendMailReportDesigner: `${baseUrl}/report/sendmail`,
  userReportsDesigner: `${baseUrl}/report/UserReports`,
  timezones: `${baseUrl}/report/TimeZones`,
  checkConnection: `${baseUrl}/report/CheckConnection`,
};
apiUrls.reportDesigner = reportDesigner;

//Distribution
const distribution = {
  distributionSFTP: `${baseUrl}/Distribution/Sftp`,
  distributionMail: `${baseUrl}/Distribution/Mail`,
};
apiUrls.distribution = distribution;

//Lookup Database
const lookupDatabase = {
  lookupDatabase: `${baseUrl}/MailLookup/mailLookup`,
  SearchMailLookupDatabase: `${baseUrl}/MailLookup/SearchMailLookups`,
};
apiUrls.lookupDatabase = lookupDatabase;

//Scheduler
const scheduler = {
  scheduler: `${baseUrl}/scheduler`,
  jobScheduler: `${baseUrl}/Scheduler/Job`,
  pauseJobScheduler: `${baseUrl}/Scheduler/Pause`,
  resumeJobScheduler: `${baseUrl}/Scheduler/Resume`,
  runJobScheduler: `${baseUrl}/Scheduler/Run`,
  ChangeOwnerScheduler: `${baseUrl}/Scheduler/ChangeOwner`,
};
apiUrls.scheduler = scheduler;

//User
const user = {
  userManagement: `${baseUrl}/User/User`,
  userProfile: `${baseUrl}/User/UserProfile`,
  userProfileUpdate: `${baseUrl}/User/UpdateUserProfile`,
  userRefreshToken: `${baseUrl}/User/RefreshToken`,
  userList: `${baseUrl}/User/UserList`,
}
apiUrls.user = user;

//Dashboard
const dashboard = {
  globalSearch: `${baseUrl}/Dashboard/GlobalSearch`,
  cockpitWidget: `${baseUrl}/Dashboard/CockpitTopData`,
  cockpitReportGenerationTrend: `${baseUrl}/Dashboard/CockpitReportGenerationTrend`,
  cockpitRecentReportActivity: `${baseUrl}/Dashboard/CockpitRecentReportActivity`,
  cockpitUpcomingReportSchedules: `${baseUrl}/Dashboard/CockpitUpcomingReportSchedules`,
}
apiUrls.dashboard = dashboard;

export default apiUrls;
