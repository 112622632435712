import PropTypes from 'prop-types';
import React, { createContext, useContext, useState, useCallback } from "react";
import { executeAssessmentAPI } from "./base-context";
import { schedulerAPI, userAPI } from "../api/apis";
import { useNotification } from './notification-context-hook';
import { notificationType, pageRoutes } from '../constants/constants';
import { useNavigate } from "react-router-dom";

export const SchedulerContext = createContext();

export const SchedulerProvider = ({ children }) => {
  const navigate = useNavigate();
  const { addMessage } = useNotification();
  const [isSchedulerLoading, setIsSchedulerLoading] = useState({ isList: false, isLoading: false });
  const [isSingleSchedulerLoading, setIsSingleSchedulerLoading] = useState(false);
  const [jobSchedulerLists, setJobSchedulerLists] = useState([]);
  const [jobHistoryScheduler, setJobHistoryScheduler] = useState(null);
  const [singleJobScheduler, setSingleJobScheduler] = useState(null);
  const [userLists, setUserLists] = useState([]);

  const scheduler = useCallback(async (request) => {
    setIsSchedulerLoading({ isList: false, isLoading: true });
    try {
      const result = await executeAssessmentAPI(schedulerAPI.scheduler, request);
      if (result.success) {
        setIsSchedulerLoading({ isList: false, isLoading: false });
        navigate(pageRoutes.REPORTS);
        return result;
      } else {
        setIsSchedulerLoading({ isList: false, isLoading: false });
        addMessage({ message: result?.message, type: notificationType.ERROR });
      }
    } catch (error) {
      setIsSchedulerLoading({ isList: false, isLoading: false });
      console.error('Error scheduler:', error);
    }
  });

  const getJobScheduler = useCallback(async (request) => {
    setIsSchedulerLoading({ isList: true, isLoading: false });
    try {
      const result = await executeAssessmentAPI(schedulerAPI.getJobScheduler, request);
      if (result.success) {
        setIsSchedulerLoading({ isList: false, isLoading: false });
        setJobSchedulerLists(result?.data);
      } else {
        setIsSchedulerLoading({ isList: false, isLoading: false });
        addMessage({ message: result?.message, type: notificationType.ERROR });
      }
    } catch (error) {
      setIsSchedulerLoading({ isList: false, isLoading: false });
      console.error('Error scheduler:', error);
    }
  });

  const getSingleJobScheduler = useCallback(async (request) => {
    setIsSingleSchedulerLoading(true);
    try {
      const result = await executeAssessmentAPI(schedulerAPI.getJobScheduler, request);
      if (result.success) {
        setIsSingleSchedulerLoading(false);
        setSingleJobScheduler(result?.data)
      } else {
        setIsSingleSchedulerLoading(false);
        addMessage({ message: result?.message, type: notificationType.ERROR });
      }
    } catch (error) {
      setIsSingleSchedulerLoading(false);
      console.error('Error scheduler:', error);
    }
  });

  const getJobHistoryScheduler = useCallback(async (request) => {
    setIsSchedulerLoading({ isList: true, isLoading: false });
    try {
      const result = await executeAssessmentAPI(schedulerAPI.getJobScheduler, request);
      if (result.success) {
        setIsSchedulerLoading({ isList: false, isLoading: false });
        setJobHistoryScheduler(result?.data);
      } else {
        setIsSchedulerLoading({ isList: false, isLoading: false });
        addMessage({ message: result?.message, type: notificationType.ERROR });
      }
    } catch (error) {
      setIsSchedulerLoading({ isList: false, isLoading: false });
      console.error('Error scheduler:', error);
    }
  });

  const updateJobScheduler = useCallback(async (request) => {
    console.log('request', JSON.stringify(request))
    setIsSchedulerLoading({ isList: false, isLoading: true });
    try {
      const result = await executeAssessmentAPI(schedulerAPI.updateJobScheduler, request);
      if (result.success) {
        setIsSchedulerLoading({ isList: false, isLoading: false });
        getJobScheduler();
        return result;
      } else {
        setIsSchedulerLoading({ isList: false, isLoading: false });
        addMessage({ message: result?.message, type: notificationType.ERROR });
      }
    } catch (error) {
      setIsSchedulerLoading({ isList: false, isLoading: false });
      console.error('Error scheduler:', error);
    }
  });

  const pauseJobScheduler = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(schedulerAPI.pauseJobScheduler, request);
      if (result.success) {
        getJobScheduler();
        return result;
      } else {
        addMessage({ message: result?.message, type: notificationType.ERROR });
      }
    } catch (error) {
      console.error('Error scheduler:', error);
    }
  });

  const resumeJobScheduler = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(schedulerAPI.resumeJobScheduler, request);
      if (result.success) {
        getJobScheduler();
        return result;
      } else {
        addMessage({ message: result?.message, type: notificationType.ERROR });
      }
    } catch (error) {
      console.error('Error scheduler:', error);
    }
  });

  const runJobScheduler = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(schedulerAPI.runJobScheduler, request);
      if (result.success) {
        getJobScheduler();
        return result;
      } else {
        addMessage({ message: result?.message, type: notificationType.ERROR });
      }
    } catch (error) {
      console.error('Error scheduler:', error);
    }
  });

  const deleteJobScheduler = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(schedulerAPI.deleteJobScheduler, request);
      if (result.success) {
        getJobScheduler();
        return result;
      } else {
        addMessage({ message: result?.message, type: notificationType.ERROR });
      }
    } catch (error) {
      console.error('Error scheduler:', error);
    }
  });

  const getUserList = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(userAPI.getUserList, request);
      if (result.success) {
        setUserLists(result?.data)
      } else {
        addMessage({ message: result?.message, type: notificationType.ERROR });
      }
    } catch (error) {
      console.error('Error scheduler:', error);
    }
  });

  const ChangeOwnerScheduler = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(schedulerAPI.ChangeOwnerScheduler, request);
      if (result.success) {
        getJobScheduler();
        addMessage({ message: result?.message, type: notificationType.SUCCESS });
        return result;
      } else {
        addMessage({ message: result?.message, type: notificationType.ERROR });
      }
    } catch (error) {
      console.error('Error scheduler:', error);
    }
  });

  const contextValue = {
    isSchedulerLoading,
    isSingleSchedulerLoading,
    jobHistoryScheduler,
    jobSchedulerLists,
    singleJobScheduler,
    setSingleJobScheduler,
    scheduler,
    userLists,
    getJobScheduler,
    getJobHistoryScheduler,
    getSingleJobScheduler,
    updateJobScheduler,
    pauseJobScheduler,
    resumeJobScheduler,
    runJobScheduler,
    deleteJobScheduler,
    getUserList,
    ChangeOwnerScheduler
  };

  return (
    <SchedulerContext.Provider value={contextValue}>
      {children}
    </SchedulerContext.Provider>
  )
};

SchedulerProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useScheduler = () => useContext(SchedulerContext);
