import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Title from '../../shared/components/Title';
import "../../assets/scss/settings.scss";
import Overview from './Overview';
import ChangePassword from './ChangePassword';

const Setting = () => {
    return (
        <>
            <Title title="Settings" />
            <div className="card">
                <Tabs
                    defaultActiveKey="overview"
                    id="uncontrolled-tab-example"
                    className="mb-5 setting-tabs"
                >
                    <Tab eventKey="overview" title="Overview">
                        <Overview />
                    </Tab>
                    <Tab eventKey="password" title="Password">
                        <ChangePassword />
                    </Tab>
                    <Tab eventKey="subscription" title="Subscription">
                        Not Available
                    </Tab>
                    <Tab eventKey="activity" title="Activity">
                        Not Available
                    </Tab>
                </Tabs>
            </div>
        </>
    )
}

export default Setting;