import React from "react";
import { CustomInput } from "./Controls";
import { Card } from "react-bootstrap";

const AccountInputs = ({ AccountInfo, state, ...props }) => (
  <>
    <Card>
      <Card.Title className="mb-3">
        {state?.copy_report || state.dataSourceId > 0 ? "Edit" : "Add"}{" "}
        Connection
      </Card.Title>
      <CustomInput
        name="accountName"
        label="Account Name"
        placeholder="Enter Account Name"
        value={AccountInfo?.accountName}
        maxLength={50}
        required
        {...props}
      />
      <CustomInput
        name="sfUserName"
        label="Username"
        placeholder="Enter Username"
        value={AccountInfo?.sfUserName}
        maxLength={50}
        {...props}
        required
      />
      <CustomInput
        name="sfPassword"
        label="Password"
        type="password"
        placeholder="Enter Password"
        value={AccountInfo?.sfPassword}
        isPassword={true}
        required
        {...props}
      />
      <CustomInput
        name="sourceName"
        label="Save Connection As"
        placeholder="Enter Connection Name"
        value={AccountInfo?.sourceName}
        maxLength={50}
        required
        {...props}
      />
    </Card>
  </>
);

export default AccountInputs;
