import PropTypes from 'prop-types';
import React, { createContext, useContext, useCallback, useState } from "react";
import { executeAssessmentAPI } from "./base-context";
import { userAPI } from "../api/apis";
import { useNotification } from './notification-context-hook';
import { notificationType } from '../constants/constants';

export const UserManagementContext = createContext();

export const UserManagementProvider = ({ children }) => {
  const [userLists, setUserLists] = useState([]);
  const { addMessage } = useNotification();
  const [isLoading, setIsLoading] = useState({ isList: false, isLoading: false });

  const getUser = useCallback(async (request) => {
    setIsLoading({ isList: true, isLoading: false });
    try {
      const result = await executeAssessmentAPI(userAPI.getUser, request);
      if (result?.success) {
        setIsLoading({ isList: false, isLoading: false });
        setUserLists(result?.data);
      } else {
        setIsLoading({ isList: false, isLoading: false });
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      setIsLoading({ isList: false, isLoading: false });
      console.error('Error fetching user list:', error);
    }
  }, []);

  const createUser = useCallback(async (request) => {
    setIsLoading({ isList: false, isLoading: true });
    try {
      const result = await executeAssessmentAPI(userAPI.createUser, request);
      if (result?.success) {
        setIsLoading({ isList: false, isLoading: false });
        getUser();
        return result;
      } else {
        setIsLoading({ isList: false, isLoading: false });
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      setIsLoading({ isList: false, isLoading: false });
      console.error('Error fetching user create:', error);
    }
  }, [getUser]);

  const activeInactiveUser = useCallback(async (request) => {
    setIsLoading({ isList: false, isLoading: true });
    try {
      const result = await executeAssessmentAPI(userAPI.activeInactiveUser, request);
      if (result?.success) {
        setIsLoading({ isList: false, isLoading: false });
        getUser();
        return result;
      } else {
        setIsLoading({ isList: false, isLoading: false });
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      setIsLoading({ isList: false, isLoading: false });
      console.error('Error fetching user create:', error);
    }
  }, [getUser]);

  const deleteUser = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(userAPI.deleteUser, request);
      if (result?.success) {
        getUser();
        return result;
      } else {
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.error('Error fetching user delete:', error);
    }
  }, [getUser]);

  const contextValue = {
    isLoading,
    userLists,
    getUser,
    createUser,
    activeInactiveUser,
    deleteUser,
  };

  return (
    <UserManagementContext.Provider value={contextValue}>
      {children}
    </UserManagementContext.Provider>
  )
};

UserManagementProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useUserManagement = () => useContext(UserManagementContext);
