import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useState } from "react";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [message, setMessage] = useState(null);

    const addMessage = useCallback((messageObj) => setMessage(messageObj), []);
    const removeMessage = useCallback(() => setMessage(null), []);

    const contextValue = {
        message,
        addMessage,
        removeMessage
    };

    return (
        <NotificationContext.Provider value={contextValue}>
            {children}
        </NotificationContext.Provider>
    );
};

NotificationProvider.propTypes = {
    children: PropTypes.any.isRequired,
};

export const useNotification = () => useContext(NotificationContext);
