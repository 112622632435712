import { ApiUrls } from ".";
import { deleteApi, getApi, patchApi, postApi, postMultiPartFormApi } from "./base-api";

export const authenticateAPI = {
    userLogin(request) {
        return postApi(`${ApiUrls.authenticate.userLogin}`, request)
    },
    userForgotPassword(request) {
        return getApi(`${ApiUrls.authenticate.userForgotPassword}?Email=${request?.email}`, request)
    },
    userChangePassword(request) {
        return postApi(`${ApiUrls.authenticate.userChangePassword}`, request)
    },
    userRefreshToken(request) {
        return postApi(`${ApiUrls.user.userRefreshToken}`, request)
    }
}

export const connectionAPI = {
    updateConnection(request) {
        return patchApi(`${ApiUrls.connection.connection}`, request)
    },
}

export const dashboardAPI = {
    getGlobalSearch(request) {
        return getApi(`${ApiUrls.dashboard.globalSearch}?search=${request?.searchQuery}`, request);
    },
    getCockpitWidget(request) {
        return getApi(`${ApiUrls.dashboard.cockpitWidget}`, request);
    },
    getCockpitReportGenerationTrend(request) {
        return getApi(`${ApiUrls.dashboard.cockpitReportGenerationTrend}`, request);
    },
    getCockpitRecentReportActivity(request) {
        return getApi(`${ApiUrls.dashboard.cockpitRecentReportActivity}`, request);
    },
    getCockpitUpcomingReportSchedules(request) {
        return getApi(`${ApiUrls.dashboard.cockpitUpcomingReportSchedules}`, request);
    },
}

export const reportDesignerAPI = {
    getReportDesigner(request) {
        return getApi(`${ApiUrls.reportDesigner.report}`, request);
    },
    getUserReportsDesigner(request) {
        return getApi(`${ApiUrls.reportDesigner.userReportsDesigner}`, request);
    },
    getCheckConnection(request) {
        return getApi(`${ApiUrls.reportDesigner.checkConnection}?reportId=${request?.reportId}`, request);
    },
    getTimezones(request) {
        return getApi(`${ApiUrls.reportDesigner.timezones}`, request);
    },
    copyReportDesigner(request) {
        return postMultiPartFormApi(`${ApiUrls.reportDesigner.copyReportDesigner}`, request);
    },
    sendMailReportDesigner(request) {
        return postApi(`${ApiUrls.reportDesigner.sendMailReportDesigner}`, request);
    },
    deleteReportDesigner(request) {
        return deleteApi(`${ApiUrls.reportDesigner.report}`, request);
    }
}

export const distributionAPI = {
    getDistributionSFTP(request) {
        if (request?.id) {
            return getApi(`${ApiUrls.distribution.distributionSFTP}/${request?.id}`, request);
        } else {
            return getApi(`${ApiUrls.distribution.distributionSFTP}`, request);
        }
    },
    createDistributionSFTP(request) {
        return postApi(`${ApiUrls.distribution.distributionSFTP}`, request);
    },
    updateDistributionSFTP(request) {
        return patchApi(`${ApiUrls.distribution.distributionSFTP}`, request);
    },
    deleteDistributionSFTP(request) {
        return deleteApi(`${ApiUrls.distribution.distributionSFTP}/${request}`, request);
    },
    getDistributionMail(request) {
        if (request?.id) {
            return getApi(`${ApiUrls.distribution.distributionMail}/${request?.id}`, request);
        } else {
            return getApi(`${ApiUrls.distribution.distributionMail}`, request);
        }
    },
    createDistributionMail(request) {
        return postApi(`${ApiUrls.distribution.distributionMail}`, request);
    },
    updateDistributionMail(request) {
        return patchApi(`${ApiUrls.distribution.distributionMail}`, request);
    },
    deleteDistributionMail(request) {
        return deleteApi(`${ApiUrls.distribution.distributionMail}/${request}`, request);
    },
    defaultDistributionMail(request) {
        return patchApi(`${ApiUrls.distribution.distributionMail}/${request?.id}`, request);
    },
}

export const lookupDatabaseAPI = {
    getLookupDatabase(request) {
        return getApi(`${ApiUrls.lookupDatabase.lookupDatabase}`, request);
    },
    getSearchMailLookupDatabase(request) {
        return getApi(`${ApiUrls.lookupDatabase.SearchMailLookupDatabase}`, request);
    },
    addLookupDatabase(request) {
        return postMultiPartFormApi(`${ApiUrls.lookupDatabase.lookupDatabase}`, request);
    },
    deleteLookupDatabase(request) {
        return deleteApi(`${ApiUrls.lookupDatabase.lookupDatabase}`, request);
    },
}

export const schedulerAPI = {
    scheduler(request) {
        return postApi(`${ApiUrls.scheduler.scheduler}`, request);
    },
    getJobScheduler(request) {
        if (request?.id) {
            return getApi(`${ApiUrls.scheduler.jobScheduler}/${request?.id}`, request);
        } else {
            return getApi(`${ApiUrls.scheduler.jobScheduler}`, request);
        }
    },
    getJobHistoryScheduler(request) {
        return getApi(`${ApiUrls.scheduler.jobScheduler}/${request?.id}`, request);
    },
    updateJobScheduler(request) {
        return patchApi(`${ApiUrls.scheduler.scheduler}`, request);
    },
    pauseJobScheduler(request) {
        return patchApi(`${ApiUrls.scheduler.pauseJobScheduler}`, request);
    },
    resumeJobScheduler(request) {
        return patchApi(`${ApiUrls.scheduler.resumeJobScheduler}`, request);
    },
    runJobScheduler(request) {
        return getApi(`${ApiUrls.scheduler.runJobScheduler}/${request?.id}`, request);
    },
    deleteJobScheduler(request) {
        return deleteApi(`${ApiUrls.scheduler.jobScheduler}`, request);
    },
    ChangeOwnerScheduler(request) {
        return patchApi(`${ApiUrls.scheduler.ChangeOwnerScheduler}`, request);
    },
}

export const userAPI = {
    getUser(request) {
        return getApi(`${ApiUrls.user.userManagement}`, request);
    },
    getUserList(request) {
        return getApi(`${ApiUrls.user.userList}`, request);
    },
    createUser(request) {
        return postApi(`${ApiUrls.user.userManagement}`, request);
    },
    updateUser(request) {
        return patchApi(`${ApiUrls.user.userManagement}`, request);
    },
    activeInactiveUser(request) {
        return patchApi(`${ApiUrls.user.userManagement}/${request?.id}`, request);
    },
    deleteUser(request) {
        return deleteApi(`${ApiUrls.user.userManagement}/${request?.id}`, request);
    },
}

export const settingsAPI = {
    getUserProfile(request) {
        return getApi(`${ApiUrls.user.userProfile}`, request);
    },
    updateUserProfile(request) {
        return patchApi(`${ApiUrls.user.userProfileUpdate}`, request);
    },
}