import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FormText from "../../shared/components/form/FormText";
import Editor from "../../shared/components/form/FormEditor";
import { useDistribution } from "../../shared/hooks-context/distribution-context-hook";
import { useNotification } from "../../shared/hooks-context/notification-context-hook";
import { notificationType } from "../../shared/constants/constants";
import FormEmailChip from "../../shared/components/form/FormEmailChip";


const ComposeEmailModal = (props) => {
    const { setEmailFormData, setIsSendEmailModal, isNotSendMail } = props;
    const {
        setValue,
        watch,
        reset,
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        mode: "onChange"
    });
    const { addMessage } = useNotification();
    const { mailLists, getDistributionMail } = useDistribution();
    const [isClear, setIsClear] = useState(false);
    const [isCcInput, setIsCcInput] = useState(false);
    const [isBccInput, setIsBccInput] = useState(false);
    const [toEmailError, setToEmailError] = useState("");
    const [ccEmailError, setCcEmailError] = useState("");
    const [bccEmailError, setBccEmailError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [editorValue, setEditorValue] = useState("");

    useEffect(() => {
        if (isNotSendMail === "No") {
            resetFormData();
        }
    }, [isNotSendMail])

    useEffect(() => {
        let getFetchData = async () => {
            await getDistributionMail();
        };
        getFetchData();
    }, []);

    const resetFormData = () => {
        setIsClear(true);
        setEditorValue('')
        reset({
            to: "",
            cc: [],
            bcc: [],
            message: "",
            subject: ""
        })
        setIsCcInput(false);
        setIsBccInput(false);
        setToEmailError("");
    }

    const onCloseModal = (data) => {
        if (mailLists.find((mail) => mail?.isdefault === true)?.isdefault) {
            if (data?.to?.length > 0) {
                if (data) {
                    setEmailFormData(data)
                    setIsSendEmailModal(false);
                    setValue("message", data?.message);
                }
            } else {
                setToEmailError("To is required")
            }
        } else {
            addMessage({ message: "Please configure SMTP", type: notificationType.ERROR })
        }
    }

    const onClearCloseModal = () => {
        props.onHide();
        resetFormData();
    }

    return (
        <Modal
            {...props} onHide={onClearCloseModal}
            dialogClassName="modal-830w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="compose-email-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Enter Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form autoComplete="chrome-off">
                    <Form.Group className="mb-4">
                        <div className="d-flex align-items-center justify-content-between">
                            <Form.Label htmlFor="basic-url">To</Form.Label>
                            <div className="d-flex align-items-center">
                                {!isCcInput && <Button className="send-email-btn me-3" onClick={() => setIsCcInput(true)}>+ Cc</Button>}
                                {!isBccInput && <Button className="send-email-btn" onClick={() => setIsBccInput(true)}>+ Bcc</Button>}
                            </div>
                        </div>
                        <FormEmailChip name="to" register={register} setValue={setValue} isClear={isClear} setToEmailError={setToEmailError} errors={toEmailError} setIsClear={setIsClear} setEmailError={setEmailError} emailError={emailError} />
                    </Form.Group>
                    {isCcInput && <Form.Group className="mb-4">
                        <div className="d-flex align-items-center justify-content-between">
                            <Form.Label htmlFor="basic-url">Cc</Form.Label>
                            <div className="close-icon" onClick={() => setIsCcInput(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="48px" height="48px"><path d="M 16 14 C 15.488 14 14.976938 14.194937 14.585938 14.585938 C 13.804937 15.366937 13.804937 16.633063 14.585938 17.414062 L 29.171875 32 L 14.585938 46.585938 C 13.804938 47.366938 13.804937 48.633063 14.585938 49.414062 C 14.976937 49.805062 15.488 50 16 50 C 16.512 50 17.023062 49.805062 17.414062 49.414062 L 32 34.828125 L 46.585938 49.414062 C 47.366938 50.195063 48.633063 50.195062 49.414062 49.414062 C 50.195063 48.633062 50.195062 47.366937 49.414062 46.585938 L 34.828125 32 L 49.414062 17.414062 C 50.195063 16.633063 50.195062 15.366938 49.414062 14.585938 C 48.633062 13.804938 47.366937 13.804938 46.585938 14.585938 L 32 29.171875 L 17.414062 14.585938 C 17.023062 14.194938 16.512 14 16 14 z" /></svg>
                            </div>
                        </div>
                        <FormEmailChip name="cc" register={register} setValue={setValue} isClear={isClear} setIsClear={setIsClear} setEmailError={setEmailError} emailError={emailError} setCcEmailError={setCcEmailError} ccEmailError={ccEmailError} />
                    </Form.Group>}
                    {isBccInput &&
                        <Form.Group className="mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <Form.Label htmlFor="basic-url">Bcc</Form.Label>
                                <div className="close-icon" onClick={() => setIsBccInput(false)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="48px" height="48px"><path d="M 16 14 C 15.488 14 14.976938 14.194937 14.585938 14.585938 C 13.804937 15.366937 13.804937 16.633063 14.585938 17.414062 L 29.171875 32 L 14.585938 46.585938 C 13.804938 47.366938 13.804937 48.633063 14.585938 49.414062 C 14.976937 49.805062 15.488 50 16 50 C 16.512 50 17.023062 49.805062 17.414062 49.414062 L 32 34.828125 L 46.585938 49.414062 C 47.366938 50.195063 48.633063 50.195062 49.414062 49.414062 C 50.195063 48.633062 50.195062 47.366937 49.414062 46.585938 L 34.828125 32 L 49.414062 17.414062 C 50.195063 16.633063 50.195062 15.366938 49.414062 14.585938 C 48.633062 13.804938 47.366937 13.804938 46.585938 14.585938 L 32 29.171875 L 17.414062 14.585938 C 17.023062 14.194938 16.512 14 16 14 z" /></svg>
                                </div>
                            </div>
                            <FormEmailChip name="bcc" register={register} setValue={setValue} isClear={isClear} setIsClear={setIsClear} setEmailError={setEmailError} emailError={emailError} setBccEmailError={setBccEmailError} bccEmailError={bccEmailError} />
                        </Form.Group>}
                    <FormText
                        type="text"
                        name="subject"
                        label="Subject"
                        register={register}
                        maxLength={50}
                        validationSchema={{
                            required: "Subject is required",
                        }}
                        errors={errors} />
                    <Form.Group className="mb-4">
                        <div className="d-flex align-items-center justify-content-between">
                            <Form.Label>Message</Form.Label>
                            {/* <Form.Check // prettier-ignore
                                type="checkbox"
                                id="embedBody"
                                label="Embed in the body"
                            /> */}
                        </div>
                        <Editor setValue={setValue} name="message" value={watch("message")} editorValue={editorValue} setEditorValue={setEditorValue} />
                    </Form.Group>
                    <div className="d-flex align-items-center">
                        <Button variant="secondary me-3 px-3" disabled={!isValid || emailError || ccEmailError || bccEmailError} onClick={handleSubmit(onCloseModal)}>Save</Button>
                        <Button variant="secondary px-3" onClick={() => resetFormData()}>Reset</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal >
    )
}

export default ComposeEmailModal