import { Button, Form } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import { distributionData, distributionType } from "../../shared/config/array-config";
import Sftp from "./distribution-type-form/SFTP";
import { useForm } from "react-hook-form";
import FormSelect from "../../shared/components/form/FormSelect";
import FormText from "../../shared/components/form/FormText";
import { useEffect, useState } from "react";
import AWS from "./distribution-type-form/AWS";
import ReactSweetAlert from "../../shared/components/ReactSweetAlert";
import { useDistribution } from "../../shared/hooks-context/distribution-context-hook";
import { distributionsDataColumns } from "../../shared/constants/data-columns";
import ReactDataTable from "../../shared/components/table/ReactDataTable";
import { Spinner } from "react-bootstrap";

const Distribution = () => {
    const {
        register,
        watch,
        reset,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = useForm({ mode: "onChange", defaultValues: { distributionType: "SFTP" } });
    const { isLoading, sftpLists, sftpData, getDistributionSFTP, createDistributionSFTP, updateDistributionSFTP, deleteDistributionSFTP, getSingleDistributionSFTP } = useDistribution();
    const [distributionDeleteModal, setDistributionDeleteModal] = useState({
        isModal: false,
        id: null,
        name: ""
    });
    const [distributionId, setDistributeId] = useState(null);
    const [isHidePwd, setIsHidePwd] = useState(false);

    useEffect(() => {
        let getFetchData = async () => {
            await getDistributionSFTP();
        };
        getFetchData();
    }, [])

    useEffect(() => {
        if (distributionId) {
            let getFetchData = async () => {
                await getSingleDistributionSFTP({ id: distributionId });
            };
            getFetchData();
        }
    }, [distributionId])

    useEffect(() => {
        if (sftpData) {
            reset({ ...sftpData, distributionType: "SFTP" })
        }
    }, [sftpData])

    const onResetFormData = () => {
        reset({
            host: "",
            path: "",
            username: "",
            password: "",
            name: "",
            distributionType: "SFTP",
        })
        setDistributeId(null);
        setIsHidePwd(true);
    }

    const onCreateDistribution = async (formData) => {
        if (watch("distributionType") === "SFTP") {
            const response = await createDistributionSFTP(formData)
            if (response?.success) {
                reset();
            }
        } else {
            console.log('else', formData)
        }
    }

    const onEditDistribute = (id) => {
        setDistributeId(id);
        setIsHidePwd(true)
    }
    const onUpdateDistribute = async (formData) => {
        const response = await updateDistributionSFTP(formData)
        if (response?.success) {
            onResetFormData();
        }
    }

    const onDeleteDistribute = (item) => {
        setDistributionDeleteModal({
            isModal: true,
            id: item?.id,
            name: item?.name
        })
    }

    const onCancelDistributeDelete = async () => {
        setDistributionDeleteModal({ isModal: false, id: null, name: "" })
    }

    const onConfirmDistributeDelete = async () => {
        const response = await deleteDistributionSFTP(distributionDeleteModal?.id);
        if (response?.success) {
            onCancelDistributeDelete();
        }
    }

    const distributionProps = {
        register, errors, isHidePwd, setIsHidePwd
    }

    const distributionsColumns = distributionsDataColumns({ onEditDistribute, onDeleteDistribute })
    const distributionTypes = distributionData({ sftpLists });
    return (
        <>
            <div className="row">
                <div className="col-lg-5 col-md-4 col-12">
                    <h2 className="sub-title mb-3">Add New Distribution</h2>
                    <Form className="add-distribution-form inline-form" autoComplete="off">
                        <FormSelect
                            name="distributionType"
                            label="Distribution Type"
                            options={distributionType}
                            register={register}
                            validationSchema={{
                                required: "Distribution type is required.",
                            }}
                            errors={errors}
                            inlineForm
                        />
                        {watch("distributionType") === "SFTP" ? <Sftp {...distributionProps} /> : watch("distributionType") === "AWS" ? <AWS register={register} errors={errors} /> : null}
                        <FormText
                            type="text"
                            name="name"
                            label="Save Distribution As"
                            placeholder="Enter Distribution Name"
                            register={register}
                            maxLength={50}
                            validationSchema={{
                                required: "Distribution name is required.",
                            }}
                            errors={errors}
                            inlineForm />
                        <div className="d-flex align-items-center mt-4">
                            <Button
                                variant="secondary me-3 px-3"
                                type="submit"
                                disabled={!isDirty || !isValid || isLoading}
                                onClick={handleSubmit(distributionId ? onUpdateDistribute : onCreateDistribution)}
                            >
                                {distributionId ? (
                                    <>
                                        {isLoading && (
                                            <Spinner
                                                className="me-2"
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        )}
                                        Update
                                    </>
                                ) : (
                                    <>
                                        {isLoading && (
                                            <Spinner
                                                className="me-2"
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        )}
                                        Save
                                    </>
                                )}
                            </Button>
                            <Button variant="secondary px-3" onClick={onResetFormData}>Reset</Button>
                        </div>
                    </Form>
                </div >
                <div className="col-lg-7 col-md-8 col-12">
                    <h2 className="sub-title mb-3">Saved Distributions</h2>
                    <Accordion defaultActiveKey={0} className="save-distributions-table">
                        {distributionTypes?.map((type, index) => {
                            return (
                                <Accordion.Item eventKey={index} key={index}>
                                    <Accordion.Header>{type?.label} {`(${type?.data?.length})`}</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="custom-table">
                                            <ReactDataTable columns={distributionsColumns}
                                                data={type?.data} />
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })}
                    </Accordion>
                </div>
            </div >
            {distributionDeleteModal.isModal && <ReactSweetAlert onDeleteModal={onConfirmDistributeDelete} onCancelModal={onCancelDistributeDelete} name={distributionDeleteModal?.name} />}
        </>
    )
}

export default Distribution;