import { useEffect } from 'react';
import { useDashboard } from '../../shared/hooks-context/dashboard-context-hook';
import { upcomingSchedulesColumns } from '../../shared/constants/data-columns';
import ReactDataTable from '../../shared/components/table/ReactDataTable';

const UpcomingReportSchedules = () => {
    const { isScheduleLoading, upcomingSchedulesLists, getCockpitUpcomingReportSchedules } = useDashboard();

    useEffect(() => {
        let getFetchData = async () => {
            await getCockpitUpcomingReportSchedules();
        };
        getFetchData();
    }, [])

    const columns = upcomingSchedulesColumns()

    return (
        <div className="card mt-4">
            <h2 className="card-heading mb-3">Upcoming Report Schedules</h2>
            <div className="custom-table">
                <ReactDataTable
                    columns={columns}
                    data={upcomingSchedulesLists}
                    progressPending={isScheduleLoading}
                />
            </div>
        </div>
    )
}

export default UpcomingReportSchedules;