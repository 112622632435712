import { Button, Modal } from "react-bootstrap"

const ChangeConnectionModal = (props) => {

    return (
        <Modal {...props} centered backdrop="static"
            keyboard={false}>
            <Modal.Body className="p-5 px-3 text-center">
                <p className="mb-4">There are some problems with the existing database connection. Do you want to change the connection?</p>
                <div className="d-flex align-items-center justify-content-center">
                    <Button variant="secondary px-4 d-block me-3" onClick={props.openConnection}>Yes</Button>
                    <Button variant="secondary px-4 d-block" onClick={props.onHide}>No</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ChangeConnectionModal