import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import Switch from "./Switch";
import Select from "react-select";
import {
  LOADING,
  NEXT_STEP,
  PREV_STEP,
  SET_DATASOURCE,
  SET_DATASOURCE_ID,
  UPDATE_ACCOUNT_INFO,
  UPDATE_ACCOUNT_INPUTS,
  UPDATE_CONNECTIONS_LIST,
  UPDATE_FIELD,
  UPDATE_META_INPUTS,
  UPDATE_REPORT_BODY,
  initialState,
} from ".";
import AccountInputs from "./AccountInputs";
import MetaDataInputs from "./MetaDataInputs";
import notify from "devextreme/ui/notify";
import { BASE_URL } from "../..";
import useState from "react-usestateref";
import { userInfo, validateConnection } from "../../shared/utils/validation";

const ConnectionForm = ({ state, dispatch }) => {
  const {
    step,
    formData,
    reportRequestBody,
    AccountInfo,
    MetaInfo,
    ConnectionsList,
    dataSourceId,
    dataSource,
    skip_finish,
    copy_report,
    change_connection,
  } = state;

  const [metadata, setMetaData, metaDataRef] = useState({
    roles: [],
    databases: [],
    schemas: [],
    tables: [],
  });
  const [connection, setConnectionStatus] = useState({
    loading: false,
    connectionId: 0,
  });
  const [validation, setValidation] = useState({
    accountName: "",
    sfUserName: "",
    sfPassword: "",
    sourceName: "",
  });
  const handleNextStep = useCallback(() => {
    dispatch({ type: NEXT_STEP });
  }, [dispatch]);
  const handlePrevStep = useCallback(() => {
    dispatch({ type: PREV_STEP });
  }, [dispatch]);

  const handleAccountInputChange = (e) => {
    const { name, value } = e.target;
    setValidation(prevValidation => ({
      ...prevValidation,
      [name]: ""
    }));

    dispatch({ type: UPDATE_ACCOUNT_INPUTS, field: name, value });
    setConnectionStatus({
      ...connection,
      connection: false,
      message: "",
    });
  };
  const handleMetaInputChange = (e) => {
    const { name, value } = e;
    dispatch({ type: UPDATE_META_INPUTS, field: name, value });
    if (name == "Database" && value) {
      fetchSchemasInDatabase(value);
    } else if (name == "Schema" && value) {
      fetchTablesInSchema(value);
    }
    dispatch({
      type: SET_DATASOURCE_ID,
      value: 0,
    });
  };
  const updateDataSource = useCallback(() => {
    const obj = { ...AccountInfo, ...MetaInfo };
    obj["Tables"] =
      Array.isArray(MetaInfo.Tables) && MetaInfo.Tables.length > 0
        ? MetaInfo.Tables.map((x) => x.value)
        : [];
    if (validateConnection(obj) == true) {
      dispatch({ type: UPDATE_REPORT_BODY, value: obj });
    }
  }, [dispatch, MetaInfo, AccountInfo]);

  const updateValidation = (field, errorMessage) => {
    setValidation(prevValidation => ({
      ...prevValidation,
      [field]: errorMessage
    }));
  };

  const connectionStatus = () => {
    if (!AccountInfo.accountName || String(AccountInfo.accountName).trim() === "") {
      updateValidation("accountName", "Account name is required.");
    } else {
      updateValidation("accountName", "");
    }
    if (!AccountInfo.sfUserName || String(AccountInfo.sfUserName).trim() === "") {
      updateValidation("sfUserName", "Username is required.");
    } else {
      updateValidation("sfUserName", "");
    }
    if (!AccountInfo.sfPassword || String(AccountInfo.sfPassword).trim() === "") {
      updateValidation("sfPassword", "Password is required.");
    } else {
      updateValidation("sfPassword", "");
    }
    if (!AccountInfo.sourceName || String(AccountInfo.sourceName).trim() === "") {
      updateValidation("sourceName", "Connection name is required.");
    } else {
      updateValidation("sourceName", "");
    }

    if (
      AccountInfo.accountName &&
      AccountInfo.sfUserName &&
      AccountInfo.sfPassword &&
      AccountInfo.sourceName
    ) {
      setConnectionStatus({
        loading: true,
      });
      fetch(`${BASE_URL}/Snowflake/CheckConnection`, {
        method: "POST",
        headers: {
          accountName: AccountInfo?.accountName,
          sfUserName: AccountInfo?.sfUserName,
          sfPassword: AccountInfo?.sfPassword,
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
        .then(async (x) => {
          return {
            status: x.status,
            result: await x.json(),
          };
        })
        .then((response) => {
          if (response.status == 200) {
            const { data, success, message } = response.result;
            setConnectionStatus({
              loading: false,
              connection: data,
              ...response.result,
            });
          } else {
            setConnectionStatus({
              loading: false,
              connection: false,
              message: "Failed to fetch connection",
            });
          }
        })
        .catch((e) => {
          setConnectionStatus({
            loading: false,
            ...e,
          });
        });
    }
  };

  const fetchMetaData = () => {
    if (
      AccountInfo.accountName &&
      AccountInfo.sfUserName &&
      AccountInfo.sfPassword &&
      AccountInfo.sourceName
    ) {
      setMetaData({});

      fetch(`${BASE_URL}/Snowflake/Metadata`, {
        method: "POST",
        headers: {
          accountName: AccountInfo?.accountName,
          sfUserName: AccountInfo?.sfUserName,
          sfPassword: AccountInfo?.sfPassword,
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
        .then(async (x) => {
          return {
            status: x.status,
            result: await x.json(),
          };
        })
        .then((response) => {
          if (response.status == 200) {
            const { data, success, message } = response.result;
            if (success == true) {
              setMetaData(data);
              handleNextStep();
              if (dataSource) {
                fetchSchemasInDatabase(dataSource.database);
                fetchTablesInSchema(dataSource.schema);
                dispatch({
                  type: UPDATE_META_INPUTS,
                  field: "Schema",
                  value: dataSource.schema,
                });
                dispatch({
                  type: UPDATE_META_INPUTS,
                  field: "Tables",
                  value: dataSource.tableList.map((x) => {
                    return {
                      value: x,
                      label: x,
                      name: "Tables",
                    };
                  }),
                });
              }
            }
          }
        })
        .catch((e) => {
          setMetaData({});
        });
    } else {
      notify("Missing Parameters", "warning");
    }
  };

  const fetchSchemasInDatabase = (databaseName) => {
    if (
      AccountInfo.accountName &&
      AccountInfo.sfUserName &&
      AccountInfo.sfPassword &&
      AccountInfo.sourceName
    ) {
      setMetaData({
        ...metaDataRef.current,
        schemas: [],
        tables: [],
      });
      ["Schema", "Tables"].map((x) => {
        handleMetaInputChange({
          name: x,
          value: null,
        });
      });

      fetch(`${BASE_URL}/Snowflake/Schemas/${databaseName}`, {
        method: "GET",
        headers: {
          accountName: AccountInfo?.accountName,
          sfUserName: AccountInfo?.sfUserName,
          sfPassword: AccountInfo?.sfPassword,
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
        .then(async (x) => {
          return {
            status: x.status,
            result: await x.json(),
          };
        })
        .then((response) => {
          if (response.status == 200) {
            const { data, message, success } = response.result;
            if (success == true) {
              setMetaData({
                ...metaDataRef.current,
                schemas: data,
                tables: [],
              });
              // if (dataSourceId > 0 && dataSource != null) {
              //   handleMetaInputChange({
              //     name: "Schema",
              //     value: dataSource.schema,
              //   });
              // }
            }
          }
        })
        .catch((e) => {
          setMetaData({ ...metaDataRef.current, schemas: [], tables: [] });
        });
    } else {
      notify("Missing Parameters", "warning");
    }
  };
  const fetchTablesInSchema = (schemaName) => {
    if (
      AccountInfo.accountName &&
      AccountInfo.sfUserName &&
      AccountInfo.sfPassword &&
      AccountInfo.sourceName
    ) {
      setMetaData({
        ...metaDataRef.current,
        tables: [],
      });
      ["Tables"].map((x) => {
        handleMetaInputChange({
          name: x,
          value: null,
        });
      });
      if (schemaName) {
        fetch(
          `${BASE_URL}/Snowflake/Tables/${MetaInfo.Database}/${schemaName}`,
          {
            method: "GET",
            headers: {
              accountName: AccountInfo?.accountName,
              sfUserName: AccountInfo?.sfUserName,
              sfPassword: AccountInfo?.sfPassword,
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
          .then(async (x) => {
            return {
              status: x.status,
              result: await x.json(),
            };
          })
          .then((response) => {
            if (response.status == 200) {
              const { success, message, data } = response.result;
              if (success == true) {
                setMetaData({ ...metaDataRef.current, tables: data });
                // if (dataSourceId > 0 && dataSource != null) {
                //   var sourceTables = JSON.parse(dataSource.tables);
                //   handleMetaInputChange({
                //     name: "Tables",
                //     value: sourceTables.map((x) => {
                //       return {
                //         value: x,
                //         label: x,
                //         name: "Tables",
                //       };
                //     }),
                //   });
                // }
              } else {
                setMetaData({ ...metaDataRef.current, tables: [] });
                notify(message, "error");
              }
            }
          })
          .catch((e) => {
            setMetaData({ ...metaDataRef.current, tables: [] });
          });
      } else notify("Schema missing", "warning");
    } else {
      notify("Missing Parameters", "warning");
    }
  };

  const handleConnectionSave = () => {
    if (
      AccountInfo.accountName &&
      AccountInfo.sfUserName &&
      AccountInfo.sfPassword &&
      AccountInfo.sourceName
    ) {
      // AccountInfo["CreatedBy"] = "srikanth";
      setMetaData({});
      fetch(`${BASE_URL}/Connection`, {
        method: AccountInfo?.id > 0 ? "PATCH" : "POST",
        body: JSON.stringify(AccountInfo),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
        .then(async (x) => {
          return {
            status: x.status,
            result: await x.json(),
          };
        })
        .then((response) => {
          if (response.status == 200) {
            const { success, message, data } = response.result;
            if (success == true) {
              dispatch({
                type: UPDATE_ACCOUNT_INFO,
                payload: {
                  ...data,
                  connectionId: data.id,
                },
              });
              notify("Connection Saved", "success");
              fetchConnectionsList();
            } else {
              notify(JSON.stringify(message), "error");
            }
          }
        })
        .catch((e) => {
          console.log(e);
          notify(e, "error");
        });
    } else {
      notify("Missing Parameters 4", "warning");
    }
  };

  useEffect(() => {
    if (Object.keys(AccountInfo).length !== 0) {
      setValidation({
        accountName: !AccountInfo?.accountName ? "Account name is required." : "",
        sfUserName: !AccountInfo?.sfUserName ? "Username is required." : "",
        sfPassword: !AccountInfo?.sfPassword ? "Password is required." : "",
        sourceName: !AccountInfo?.sourceName ? "Connection name is required." : "",
      });
    }
  }, [AccountInfo]);

  useEffect(() => {
    fetchConnectionsList();

    return () => { };
  }, []);
  const fetchConnectionsList = () => {
    fetch(`${BASE_URL}/Connection`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then(async (x) => {
        return {
          status: x.status,
          result: await x.json(),
        };
      })
      .then((response) => {
        if (response.status == 200) {
          const { success, message, data } = response.result;
          if (success == true) {
            dispatch({ type: UPDATE_CONNECTIONS_LIST, payload: data });
            if (dataSourceId > 0 || dataSource?.id) {
              fetchDataSource(dataSourceId || dataSource?.id);
            }
          }
        }
      })
      .catch((e) => { });
  };

  const fetchDataSource = (id) => {
    fetch(`${BASE_URL}/Connection/DataSource/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then(async (x) => {
        return {
          status: x.status,
          result: await x.json(),
        };
      })
      .then((response) => {
        if (response.status == 200) {
          const { success, message, data } = response.result;
          if (success == true) {
            //#region  User Connection Restriction
            // if (data?.createdBy == userInfo()?.username) {
            //   setConnectionStatus({
            //     ...connection,
            //     connectionId: data.connectionAccount.id,
            //   });
            //   dispatch({
            //     type: UPDATE_ACCOUNT_INFO,
            //     payload: {
            //       ...data.connectionAccount,
            //       connectionId: data.connectionAccount.id,
            //     },
            //   });
            //   dispatch({
            //     type: SET_DATASOURCE,
            //     payload: { ...data },
            //   });
            // } else {
            //   setConnectionStatus({
            //     ...connection,
            //     connectionId: 0,
            //   });
            //   dispatch({
            //     type: UPDATE_ACCOUNT_INFO,
            //     payload: {
            //       ...initialState.AccountInfo,
            //     },
            //   });
            //   dispatch({
            //     type: SET_DATASOURCE,
            //     payload: { ...data },
            //   });
            // }
            //#endregion

            //#region  User Connection With out Restriction
            setConnectionStatus({
              ...connection,
              connectionId: data.connectionAccount.id,
            });
            dispatch({
              type: UPDATE_ACCOUNT_INFO,
              payload: {
                ...data.connectionAccount,
                connectionId: data.connectionAccount.id,
              },
            });
            dispatch({
              type: SET_DATASOURCE,
              payload: { ...data },
            });
            //#endregion

            dispatch({
              type: UPDATE_META_INPUTS,
              field: "Role",
              value: data.role,
            });
            dispatch({
              type: UPDATE_META_INPUTS,
              field: "Database",
              value: data.database,
            });
          }
        }
      })
      .catch((e) => { });
  };
  const AccountFormMemo = useMemo(
    () => (
      <AccountInputs
        state={state}
        AccountInfo={AccountInfo}
        onChange={handleAccountInputChange}
        error={validation}
      />
    ),
    [step, handleAccountInputChange]
  );
  const CollectionsMemo = useMemo(
    () => (
      <MetaDataInputs
        dispatch={dispatch}
        roles={metaDataRef.current.roles}
        databases={metaDataRef.current.databases}
        tables={metaDataRef.current.tables}
        schemas={metaDataRef.current.schemas}
        MetaInfo={MetaInfo}
        onChange={handleMetaInputChange}
      />
    ),
    [step, handleMetaInputChange]
  );
  return (
    <Form autoComplete="off">
      {/* {JSON.stringify(MetaInfo)} */}
      <Row>
        {step === 1 && (
          <>
            <Col className="col-lg-5">
              <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
                <ListGroup>
                  {ConnectionsList.map((x, i) => (
                    <ListGroup.Item key={x.id}>
                      <div className="d-flex  w-100 justify-content-between align-items-center">
                        {x.sourceName}

                        <Switch
                          key={x.id}
                          checked={x.id == AccountInfo?.id}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setConnectionStatus({
                                ...connection,
                                connectionId: x.id,
                              });
                              dispatch({
                                type: UPDATE_ACCOUNT_INFO,
                                payload: { ...x, connectionId: x.id },
                              });
                            } else {
                              setConnectionStatus({
                                ...connection,
                                connectionId: 0,
                              });
                              Object.keys(AccountInfo).map((key) => {
                                if (key == "id") delete AccountInfo[key];
                                else {
                                  dispatch({
                                    type: UPDATE_ACCOUNT_INPUTS,
                                    field: key,
                                    value: "",
                                  });
                                }
                              });
                            }
                          }}
                        />
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            </Col>
            <Col className="col-lg-7 ">
              {AccountFormMemo}
              <span
                className={`${connection.connection == true ? "text-success" : "text-danger"
                  } mt-2 d-block`}
              >
                {connection?.message}
              </span>
            </Col>
          </>
        )}

        {step >= 2 && <Col className="col-lg-12 ">{CollectionsMemo}</Col>}

        <div className="text-center mt-3">
          {step > 1 && (
            <>
              <Button
                disabled={state?.loading}
                variant="secondary"
                onClick={handlePrevStep}
                className="mx-2"
              >
                Previous
              </Button>
            </>
          )}

          {step == 1 ? (
            <>
              <Button
                disabled={connection.loading}
                variant="secondary"
                className="mx-2"
                onClick={connectionStatus}
              >
                {connection?.loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Testing ...
                  </>
                ) : (
                  "Test Connection"
                )}
              </Button>
              {connection?.connection == true && (
                <>
                  <Button
                    variant="secondary"
                    onClick={handleConnectionSave}
                    className="mx-2"
                  >
                    {AccountInfo?.id > 0 ? "Update" : "Save"} Connection
                  </Button>
                  {AccountInfo?.id > 0 && (
                    <Button
                      variant="secondary"
                      onClick={fetchMetaData}
                      className="mx-2"
                    >
                      Next
                    </Button>
                  )}
                </>
              )}
            </>
          ) : step < 2 ? (
            <>
              {AccountInfo?.id > 0 && (
                <Button
                  variant="secondary"
                  onClick={fetchMetaData}
                  className="mx-2"
                >
                  Next
                </Button>
              )}
            </>
          ) : (
            <>
              {copy_report == true ? (
                <Button
                  id="clonesubmitBtn"
                  variant="secondary"
                  className="mx-2"
                  disabled={state.loading}
                >
                  {state?.loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading ...
                    </>
                  ) : (
                    "Save"
                  )}
                </Button>
              ) : change_connection == true ? (
                <Button
                  id="changeConnectionSubmitBtn"
                  variant="secondary"
                  className="mx-2"
                  disabled={state.loading}
                >
                  {state?.loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading ...
                    </>
                  ) : (
                    "Finish"
                  )}
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  onClick={updateDataSource}
                  className="mx-2"
                  disabled={state.loading}
                >
                  {state?.loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading ...
                    </>
                  ) : (
                    "Finish"
                  )}
                </Button>
              )}
            </>
          )}
        </div>
      </Row>
    </Form>
  );
};

export default ConnectionForm;
