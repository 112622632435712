import React, { useEffect } from 'react';
import "../../assets/scss/notification.scss";
import Alert from 'react-bootstrap/Alert';
import { useNotification } from '../hooks-context/notification-context-hook';

const AlertNotification = () => {
    const { message, removeMessage } = useNotification();

    useEffect(() => {
        if (message) {
            const timeout = setTimeout(() => {
                removeMessage();
            }, 3000);

            return () => clearTimeout(timeout);
        }
    }, [message, removeMessage]);

    return (
        message && (
            <div className="alert-notification">
                <Alert
                    variant={message.type === "error" ? "danger" : message.type}
                    onClose={removeMessage}
                    dismissible
                >
                    <p className="mb-0 text-start">
                        {message.message}
                    </p>
                </Alert>
            </div>
        )
    );
}

export default AlertNotification;
