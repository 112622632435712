import { useEffect, useState, useRef, useReducer, useCallback } from "react";
import { DxReportDesigner } from "devexpress-reporting/dx-reportdesigner";
import notify from "devextreme/ui/notify";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import "sweetalert2/src/sweetalert2.scss"; // Import SweetAlert2 styles
import "../../assets/scss/sweet-alert.scss"; // Import your custom styles

import {
  CLOSE_POPUP,
  LOADING,
  OPEN_POPUP,
  SET_DATASOURCE_ID,
  UPDATE_FIELD,
  UPDATE_META_INPUTS,
  formReducer,
  initialState,
} from ".";
import { useParams } from "react-router-dom";
import { REPORT_BASE_URL } from "../..";
import { pageRoutes, tokens } from "../../shared/constants/constants";
import { ajaxSetup } from "@devexpress/analytics-core/analytics-utils";
import { OnWebDocumentViewerInit } from "./ReportViewer";
import AppLoading from "../../shared/components/loader/AppLoader";

const ReportEngine = ({ state, dispatch, openPopup, dataSource }) => {
  const [isLoading, setLoading] = useState(true);
  const [connectionString, setConnectionString] = useState({
    dataSourceId: 0,
    reportId: 0,
  });
  const params = useParams();
  const navigate = useNavigate();
  const { step, formData, reportRequestBody, AccountInfo, MetaInfo } = state;
  const designerRef = useRef();
  const requestOptions =
    window.location.hostname === "localhost"
      ? {
          host: `https://pixelperfect.reportworks.co/`,
          getDesignerModelAction: `api/DXXRD/GetDesignerModel`,
        }
      : {
          host: `${process.env.REACT_APP_API_URL}`.replace("/api", "/"),
          getDesignerModelAction: `api/DXXRD/GetDesignerModel`,
        };
  const setUpControls = (dataSourceRefs) => {
    document.querySelectorAll(".dxrd-menu-item-text").forEach((ele, idx) => {
      if (!reportRequestBody?.connectionId) {
        document.querySelectorAll(".dxrd-menu-button-image").forEach((e) => {
          e.style.display = "none";
        });
      }
      if (dataSourceRefs && dataSourceRefs.length > 0) {
        document.querySelectorAll(".dxrd-menu-button-image").forEach((e) => {
          e.style.display = "block";
        });
      }

      if (params && params.reportId > 0) {
        if (ele.innerHTML == "Save As") {
          ele.parentElement.remove();
        }
        document.querySelectorAll(".dxrd-menu-button-image").forEach((e) => {
          e.style.display = "block";
        });
      } else {
        if (ele.innerHTML == "Save") {
          ele.parentElement.remove();
        }
      }
      if (
        ele.innerHTML === "New" ||
        ele.innerHTML === "New via Wizard..." ||
        ele.innerHTML === "Open" ||
        ele.innerHTML === "Exit" ||
        ele.innerHTML === "Localization..."
      ) {
        ele.parentElement.remove();
      }
    });
    var parent = document.createElement("div");
    parent.id = "btn-add-dataSource";
    parent.role = "button";
    parent.ariaLabel = "Add Data Source";
    parent.title = "Add Data Source";
    parent.className =
      "dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-text dx-button-has-icon";

    var button = document.createElement("button");
    button.textContent =
      dataSourceRefs && dataSourceRefs.length > 0
        ? "Edit Connection"
        : "Add Connection";
    button.onclick = () => {
      dispatch({ type: OPEN_POPUP });
    };
    button.className =
      "dx-button-text dx-button pull-right custom-data-source-btn";
    parent.appendChild(button);
    document.querySelector(".dxrd-right-panel-header").appendChild(parent);
    dispatch({ type: CLOSE_POPUP });
  };

  useEffect(() => {
    let designer;
    setLoading(true);
    dispatch({ type: LOADING, value: true });

    ajaxSetup.ajaxSettings = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(tokens.AUTH_TOKEN)}`,
      },
      beforeSend: (requestParameters) => {
        requestParameters.credentials = "include";
      },
    };
    if (params && params.reportId > 0) {
      designer = new DxReportDesigner(designerRef.current, {
        // reportUrl: JSON.stringify({
        //   reportId: params.reportId,
        // }),
        reportUrl: JSON.stringify({
          ...reportRequestBody,
          reportId: params.reportId,
        }),
        requestOptions: requestOptions,
        callbacks: {
          beforeRender: (designerModel) => {
            //console.log(designerModel);
            dispatch({ type: LOADING, value: false });

            setLoading(false);
          },
          onServerError: (error) => {
            setLoading(false);
            dispatch({ type: LOADING, value: false });
            console.log(error);
            if (error) {
              if (error.jqXHR) {
                const { responseJSON } = error.jqXHR;
                notify(
                  responseJSON?.message || responseJSON?.error,
                  "error",
                  5000
                );
              }
            }
            // ... your error handling code ...
          },
          ReportOpened: (sender, args) => {
            setUpControls(args.Report.dataSourceRefs);
            const dataSource = args.Report.dataSource();
            if (dataSource) {
              dispatch({
                type: SET_DATASOURCE_ID,
                value: args?.Report?.parameters()
                  ?.filter((parameter) => parameter.name === "dataSourceId")[0]
                  ?.value(),
              });
            } else {
              console.error("Error: Extracted data is undefined.");
            }
          },
          PreviewCustomizeExportOptions: (s, e) => {
            OnWebDocumentViewerInit(s, e);
          },
        },
      });
    } else {
      designer = new DxReportDesigner(designerRef.current, {
        reportUrl: JSON.stringify(reportRequestBody),
        requestOptions: requestOptions,
        callbacks: {
          beforeRender: (designerModel) => {
            // console.log(designerModel);
            setLoading(false);
            dispatch({ type: LOADING, value: false });
          },
          onServerError: (error) => {
            setLoading(false);
            dispatch({ type: LOADING, value: false });
            if (error) {
              if (error.jqXHR) {
                const { responseJSON } = error.jqXHR;
                notify(
                  responseJSON?.message || responseJSON?.error,
                  "error",
                  5000
                );
              }
            }
            // ... your error handling code ...
          },
          ReportOpened: (sender, args) => {
            setUpControls(args.Report.dataSourceRefs);
            const dataSource = args.Report.dataSource();
            if (dataSource) {
              dispatch({
                type: SET_DATASOURCE_ID,
                value: args?.Report?.parameters()
                  ?.filter((parameter) => parameter.name === "dataSourceId")[0]
                  ?.value(),
              });
            } else {
              console.error("Error: Extracted data is undefined.");
            }
          },
          CustomizeSaveAsDialog: (sender, args) => {
            args.Popup.title = "Save Template";
          },
          reportSaved: (sender, args) => {
            Swal.fire({
              icon: "success",
              title: "Report saved successfully!",
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                popup: "custom-swal-popup", // Custom popup class
                confirmButton: "custom-confirm-button mb-3 fs-5 rounded", // Custom confirm button class
              },
              buttonsStyling: false, // Disable SweetAlert2 default styles for buttons
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = `${pageRoutes.REPORT_DESIGNER}/edit/${sender.url}`;
              }
            });
          },
          PreviewCustomizeExportOptions: (s, e) => {
            OnWebDocumentViewerInit(s, e);
          },
        },
      });
    }
    designer?.render();
    return () => {
      console.log(designerRef.current);

      designer?.dispose();
    };
  }, [reportRequestBody, params]);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "88vh",
          display: isLoading ? "none" : "block",
        }}
      >
        <div ref={designerRef}></div>
      </div>
      <div
        style={{
          width: "100%",
          height: "700px",
          display: isLoading ? "block" : "none",
        }}
      >
        <AppLoading />
      </div>
    </>
  );
};

const parseXmlToJson = (xmlString) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, "text/xml");

  const traverse = (node) => {
    const obj = {};

    // Convert attributes to properties
    if (node.attributes.length > 0) {
      for (let i = 0; i < node.attributes.length; i++) {
        const attribute = node.attributes[i];
        obj[`@${attribute.name}`] = attribute.value;
      }
    }

    // Convert child nodes to properties
    for (let i = 0; i < node.childNodes.length; i++) {
      const child = node.childNodes[i];
      if (child.nodeType === 1) {
        if (obj[child.nodeName]) {
          if (!Array.isArray(obj[child.nodeName])) {
            obj[child.nodeName] = [obj[child.nodeName]];
          }
          obj[child.nodeName].push(traverse(child));
        } else {
          obj[child.nodeName] = traverse(child);
        }
      } else if (child.nodeType === 3 && child.nodeValue.trim() !== "") {
        obj["#text"] = child.nodeValue.trim();
      }
    }

    return obj;
  };

  const result = traverse(xmlDoc.documentElement);
  return result;
};

export default ReportEngine;
