import { reportsJobsColumns, reportsJobsDeletedColumns } from "../../shared/constants/data-columns";
import ReactDataTable from "../../shared/components/table/ReactDataTable";
import Form from "react-bootstrap/Form";
// import runNowOutlineIcon from "../../assets/images/run-now-outline-icon.svg";
import refreshOutlineIcon from "../../assets/images/refresh-outline-icon.svg";
import resumeOutlineIcon from "../../assets/images/resume-outline-icon.svg";
import pauseOutlineIcon from "../../assets/images/pause-outline-icon.svg";
import userOutlineIcon from "../../assets/images/change-job-owner-outline-icon.svg";
import deleteOutlineIcon from "../../assets/images/delete-multiple-outline-icon.svg";
import Button from "react-bootstrap/Button";
import ViewReport from "./ViewReport";
import { useCallback, useState } from "react";
import EditReportModal from "./EditReportModal";
import { useScheduler } from "../../shared/hooks-context/scheduler-context-hook";
import ReactSweetAlert from "../../shared/components/ReactSweetAlert";
import SearchFilter from "../../shared/components/table/SearchFilter";
import ChangeJobOwner from "./ChangeJobOwner";
import { isDisable } from "../../shared/shared-method";

const ReportsTableFilter = ({ data, tabName, onRefresh }) => {
    const { isSchedulerLoading, jobHistoryScheduler, deleteJobScheduler, pauseJobScheduler, resumeJobScheduler, runJobScheduler, getJobHistoryScheduler } = useScheduler();
    const [jobSchedulerDeleteModal, setJobSchedulerDeleteModal] = useState({ isEditJobScheduler: false, id: null, name: "" });
    const [toggleCleared, setToggleCleared] = useState(false);
    const [jobSchedulerId, setJobSchedulerId] = useState([]);
    const [isViewReport, setIsViewReport] = useState(false);
    const [isEditJobScheduler, setIsEditJobScheduler] = useState({
        isModal: false,
        id: null,
    });
    const [isChangeJobOwner, setIsChangeJobOwner] = useState({
        isModal: false,
        id: null,
    });
    const [filterText, setFilterText] = useState("");

    const onHandleViewReport = (id) => {
        setIsViewReport(true);
        getJobHistoryScheduler({ id });
    }
    const onBack = () => {
        setIsViewReport(false)
    }

    const onEditJobSchedulerModal = (id) => {
        setIsEditJobScheduler({ isModal: true, id: id });
    }

    const onOpenJobSchedulerDeleteModal = (item) => {
        setJobSchedulerDeleteModal({
            isEditJobScheduler: true,
            id: item?.id || null,
            name: item?.custom_report_name
        })
    }

    const onCancelJobSchedulerDelete = async () => {
        setJobSchedulerDeleteModal({ isEditJobScheduler: false, id: null, name: "" })
        setJobSchedulerId([]);
        setToggleCleared(!toggleCleared);
    }

    const onConfirmJobSchedulerDelete = async () => {
        if (jobSchedulerId?.length > 0) {
            const response = await deleteJobScheduler(jobSchedulerId)
            if (response?.success) {
                onCancelJobSchedulerDelete();
            }
        } else {
            const response = await deleteJobScheduler([jobSchedulerDeleteModal?.id]);
            if (response?.success) {
                onCancelJobSchedulerDelete();
                setIsViewReport(false);
            }
        }
    }

    const onPauseJobScheduler = async ({ id, isSingle }) => {
        if (isSingle) {
            const response = await pauseJobScheduler([id]);
            if (response?.success) {
                getJobHistoryScheduler({ id });
            }
        } else {
            await pauseJobScheduler([id]);
        }
    }

    const onRunJobScheduler = async (id) => {
        await runJobScheduler({ id });
    }

    const onResumeJobScheduler = async ({ id, isSingle }) => {
        if (isSingle) {
            const response = await resumeJobScheduler([id]);
            if (response?.success) {
                getJobHistoryScheduler({ id });
            }
        } else {
            await resumeJobScheduler([id]);
        }
    }

    const handleRowSelected = useCallback(state => {
        setJobSchedulerId(state.selectedRows.map(selectedRow => selectedRow.id));
    }, [jobSchedulerId]);

    const onMultiplePauseJobScheduler = async () => {
        const response = await pauseJobScheduler(jobSchedulerId);
        if (response?.success) {
            setJobSchedulerId([]);
        }
    }

    const onMultipleResumeJobScheduler = async () => {
        const response = await resumeJobScheduler(jobSchedulerId);
        if (response?.success) {
            setJobSchedulerId([]);
        }
    }

    const onChangeJobOwnerModal = (id) => {
        setIsChangeJobOwner({ isModal: true, id: id });
    }

    const onFilter = (value) => {
        setFilterText(value);
    };

    const filteredItems = data?.filter((item) => {
        return item?.custom_report_name?.toLowerCase().includes(filterText.toLowerCase()) ||
            item?.id === Number(filterText) ||
            item?.createdby?.toLowerCase().includes(filterText.toLowerCase()) ||
            (!item?.deleted && item?.nextSchedule?.toLowerCase().includes(filterText.toLowerCase())) ||
            ((item?.jobStatus === 4 || item?.deleted) && item?.lastupdatedon?.toLowerCase().includes(filterText.toLowerCase())) ||
            item?.format?.toLowerCase().includes(filterText.toLowerCase());
    });

    const rowDisabledChockRow = (row) => ((tabName === "Deleted" && isDisable(row?.createdby)) || (tabName !== "Deleted" && (isDisable(row?.createdby) || row?.jobStatus === 1)) ? true : false);
    const columns = tabName === "Deleted" ? reportsJobsDeletedColumns() : reportsJobsColumns({ tabName, onEditJobSchedulerModal, onPauseJobScheduler, onRunJobScheduler, onResumeJobScheduler, onOpenJobSchedulerDeleteModal, onHandleViewReport, onChangeJobOwnerModal });

    return (
        <>
            <div className="card-table">
                <div className="row align-items-center px-4">
                    <div className="col-lg-4 col-12">
                        {isViewReport ? <Button className="d-flex align-items-center back-btn" onClick={onBack}>
                            <span className="back-icon me-1">
                                <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 1024 1024" version="1.1">
                                    <path d="M671.968 912c-12.288 0-24.576-4.672-33.952-14.048L286.048 545.984c-18.752-18.72-18.752-49.12 0-67.872l351.968-352c18.752-18.752 49.12-18.752 67.872 0 18.752 18.72 18.752 49.12 0 67.872l-318.016 318.048 318.016 318.016c18.752 18.752 18.752 49.12 0 67.872C696.544 907.328 684.256 912 671.968 912z" />
                                </svg>
                            </span>
                            Back
                        </Button> :
                            <Form className="d-flex align-items center">
                                <SearchFilter onFilter={onFilter} filterText={filterText} placeholder="Search Job" className="ms-0" />
                            </Form>
                        }
                    </div>
                    <div className="col-lg-8 col-12">
                        <div className="d-flex align-items-center justify-content-end mb-3">
                            {isViewReport ? <>
                                <Button variant="secondary" className="mb-0 tushar@parkingrhino.com" disabled={isDisable(jobHistoryScheduler?.createdby) || jobHistoryScheduler?.jobStatus === 1} onClick={() => onEditJobSchedulerModal(jobHistoryScheduler?.id)}>
                                    Edit
                                </Button>
                                {/* <Button className="icon-outline-btn ms-3" variant="outline-primary" title="Run Now" onClick={() => onRunJobScheduler(jobHistoryScheduler?.id)}>
                                    <img src={runNowOutlineIcon} alt="Run Now Icon" />
                                </Button> */}
                                {jobHistoryScheduler?.jobStatus === 2 ? <Button className="icon-outline-btn ms-3" variant="outline-primary" title="Resume" onClick={() => onResumeJobScheduler({ id: jobHistoryScheduler?.id, isSingle: true })}>
                                    <img src={resumeOutlineIcon} alt="Resume Icon" />
                                </Button> :
                                    jobHistoryScheduler?.jobStatus === 1 ?
                                        <Button className="icon-outline-btn ms-3" variant="outline-primary" title="Pause" onClick={() => onPauseJobScheduler({ id: jobHistoryScheduler?.id, isSingle: true })}>
                                            <img src={pauseOutlineIcon} alt="Pause Icon" />
                                        </Button> : null
                                }
                                <Button className="icon-outline-btn ms-3" variant="outline-primary" title="Change Job Owner" disabled={isDisable(jobHistoryScheduler?.createdby)} onClick={() => onChangeJobOwnerModal(jobHistoryScheduler?.id)}>
                                    <img src={userOutlineIcon} alt="User Icon" />
                                </Button>
                                <Button className="icon-outline-btn ms-3" variant="outline-primary" title="Delete" disabled={isDisable(jobHistoryScheduler?.createdby) || jobHistoryScheduler?.jobStatus === 1} onClick={() => onOpenJobSchedulerDeleteModal(jobHistoryScheduler)}>
                                    <img src={deleteOutlineIcon} alt="Delete Icon" />
                                </Button>
                            </> :
                                <>
                                    {/* <Button className="icon-outline-btn ms-3" variant="outline-primary" title="Run Now" disabled={jobSchedulerId?.length === 0} >
                                        <img src={runNowOutlineIcon} alt="Run Now Icon" />
                                    </Button> */}
                                    <Button className="icon-outline-btn ms-3" variant="outline-primary" title="Refresh" onClick={onRefresh}>
                                        <img src={refreshOutlineIcon} alt="Refresh Icon" style={{ width: "22px", height: "22px" }} />
                                    </Button>
                                    <Button className="icon-outline-btn ms-3" variant="outline-primary" title="Resume" disabled={jobSchedulerId?.length === 0} onClick={onMultipleResumeJobScheduler}>
                                        <img src={resumeOutlineIcon} alt="Resume Icon" />
                                    </Button>
                                    <Button className="icon-outline-btn ms-3" variant="outline-primary" title="Pause" disabled={jobSchedulerId?.length === 0} onClick={onMultiplePauseJobScheduler}>
                                        <img src={pauseOutlineIcon} alt="Pause Icon" />
                                    </Button>
                                    <Button className="icon-outline-btn ms-3" variant="outline-primary" title="Delete" disabled={jobSchedulerId?.length === 0} onClick={onOpenJobSchedulerDeleteModal}>
                                        <img src={deleteOutlineIcon} alt="Delete Icon" />
                                    </Button>
                                </>
                            }
                        </div>
                    </div>
                </div>
                {isViewReport ? <ViewReport jobHistoryScheduler={jobHistoryScheduler} /> : <ReactDataTable columns={columns}
                    data={filteredItems}
                    onSelectedRowsChange={handleRowSelected}
                    selectableRowDisabled={rowDisabledChockRow}
                    selectableRows
                    pagination
                    clearSelectedRows={toggleCleared}
                    progressPending={isSchedulerLoading?.isList}
                />}
            </div>
            <EditReportModal show={isEditJobScheduler.isModal} onHide={() => setIsEditJobScheduler(false)} id={isEditJobScheduler?.id} />
            {jobSchedulerDeleteModal.isEditJobScheduler && <ReactSweetAlert onDeleteModal={onConfirmJobSchedulerDelete} onCancelModal={onCancelJobSchedulerDelete} name={jobSchedulerDeleteModal?.name ? jobSchedulerDeleteModal?.name : "Reports"} />}
            <ChangeJobOwner show={isChangeJobOwner.isModal} onHide={() => setIsChangeJobOwner(false)} id={isChangeJobOwner?.id} />
        </>
    )
}

export default ReportsTableFilter;