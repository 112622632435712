import { useEffect, useState } from "react";
import { reportDayLists, reportStatusLists } from "../../shared/config/array-config";
import ToggleButton from "react-bootstrap/ToggleButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/esm/Button";
import { useDashboard } from "../../shared/hooks-context/dashboard-context-hook";
import { FirstLetterCapitalized, convertFileSize, getDataTime } from "../../shared/shared-method";
import { useNavigate } from "react-router-dom";
import { pageRoutes } from "../../shared/constants/constants";
import AppLoading from "../../shared/components/loader/AppLoader";

const RecentReportActivity = () => {
    const navigate = useNavigate();
    const { isActivityLoading, recentReportActivityLists, getCockpitRecentReportActivity } = useDashboard();
    const [radioValue, setRadioValue] = useState(30);
    const [reportStatus, setReportStatus] = useState("ALL");
    const [filterReports, setFilterReports] = useState([]);

    useEffect(() => {
        let getFetchData = async () => {
            await getCockpitRecentReportActivity();
        };
        getFetchData();
    }, [])

    useEffect(() => {
        let filteredReports = recentReportActivityLists;

        if (reportStatus !== "ALL") {
            filteredReports = filteredReports.filter(report => report.status === reportStatus);
        }

        if (radioValue) {
            const cutoffDate = new Date();
            cutoffDate.setDate(cutoffDate.getDate() - Number(radioValue));

            filteredReports = filteredReports.filter(report => {
                const executionDate = new Date(report.executionDate);
                return executionDate > cutoffDate;
            });
        }

        setFilterReports(filteredReports);

    }, [reportStatus, radioValue, recentReportActivityLists]);

    return (
        <div className="card report-activity">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
                <h2 className="card-heading mb-3">Recent Report Activity</h2>
                <div className="d-flex align-items-center justify-content-between recent-filter-grp flex-wrap">
                    <ButtonGroup className="chart-tab mb-3">
                        {reportDayLists.map((radio, idx) => (
                            <ToggleButton
                                key={idx}
                                id={`report-activity-${idx}`}
                                type="radio"
                                variant={radio.value === Number(radioValue) ? 'secondary' : 'light'}
                                name="radio"
                                value={radio.value}
                                checked={radio.value === Number(radioValue)}
                                onChange={(e) => setRadioValue(e.currentTarget.value)}
                            >
                                {radio.name}
                            </ToggleButton>
                        ))}
                    </ButtonGroup>
                    <ButtonGroup className="chart-tab mb-3">
                        {reportStatusLists.map((radio, idx) => (
                            <ToggleButton
                                key={idx}
                                id={`report-status-${idx}`}
                                type="radio"
                                variant={radio?.value === reportStatus ? 'secondary' : 'light'}
                                name="radio"
                                value={radio.value}
                                checked={radio?.value === reportStatus}
                                onChange={(e) => setReportStatus(e.currentTarget.value)}
                            >
                                {radio.name}
                            </ToggleButton>
                        ))}
                    </ButtonGroup>
                    <Button variant="secondary" className="view-report-btn mb-3" onClick={() => navigate(pageRoutes.REPORTS)}>View Reports</Button>
                </div>
            </div>
            <div>
                {isActivityLoading ? <AppLoading /> :
                    filterReports?.length > 0 ?
                        (<div className="recent-report-activity">
                            {filterReports?.map((report, index) => {
                                return (
                                    <div className="recent-report-activity__status-item" key={index}>
                                        <div className="d-flex align-items-center">
                                            <div class="recent-report-activity__badge"><span className={`recent-report-activity__circle-${FirstLetterCapitalized(report?.status)}`} /></div>
                                            <Button variant="secondary" className="recent-report-activity__pdf-btn">{report?.reportFormat?.toUpperCase()}</Button>
                                            <div className="recent-report-activity__content">
                                                <h4 className="recent-report-activity__data text-truncate mb-1">{report?.reportName}</h4>
                                                <h5>{getDataTime(report?.executionDate)}</h5>
                                            </div>
                                        </div>
                                        <div className="recent-report-activity__id">
                                            <h4 className="recent-report-activity__data">{report?.jobId}</h4>
                                        </div>
                                        <div className="recent-report-activity__type text-start" style={{ minWidth: "100px", whiteSpace: "pre-wrap" }}>
                                            <h4 className="recent-report-activity__data">{report?.type}</h4>
                                        </div>
                                        <div className="recent-report-activity__size text-start" style={{ width: "80px" }}>
                                            <h4 className="recent-report-activity__data">{convertFileSize(report?.fileSize)}</h4>
                                        </div>
                                        <div className="recent-report-activity__status-btn text-end">
                                            <Button variant={report?.status === "SUCCESS" ? "success" : "danger"} className="recent-report-activity__success-btn">
                                                {report?.status === "SUCCESS" ? FirstLetterCapitalized(report?.status) : "Failed"}
                                            </Button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>)
                        :
                        <p className="text-center py-5">There are no records to display</p>
                }
            </div>
        </div >
    )
}

export default RecentReportActivity;