import { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import eyeIcon from "../../assets/images/password-view.svg";
import eyeHiddenIcon from "../../assets/images/password-hidden.svg";

export const CustomDropDown = ({ label, options = [], ...props }) => (
  <Form.Group className="mb-2">
    <Form.Label>{label}</Form.Label>
    <Form.Select aria-label={label} {...props}>
      {!props.value && <option>{props.placeholder}</option>}
      {options.map((x) => {
        return (
          <option key={x.value} value={x.value}>
            {x.label}
          </option>
        );
      })}
    </Form.Select>
  </Form.Group>
);

export const SelectCustomDropDown = ({
  isMulti,
  label,
  options,
  value,
  onCustomChange,
  onChange,
  ...props
}) => {
  return (
    <Form.Group className="mb-2">
      <Form.Label>{label}</Form.Label>
      <Select
        options={options}
        value={value}
        isMulti={isMulti}
        isSearchable
        placeholder="Select options"
        onChange={isMulti ? onCustomChange : onChange}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            text: '#3599B8',
            font: '#3599B8',
            primary25: '#FFC0C0',
            primary: '#E16C20',
            neutral80: 'black',
            color: '#000000',
          },
        })}
        {...props}
      />
    </Form.Group>
  );
};

export const CustomInput = ({ label, type, isPassword, maxLength, error, ...props }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleClickShowPassword = () => {
    setPasswordVisible((prev) => !prev);
  };

  return (
    <Form.Group controlId={props.name} className="mb-3">
      <Form.Label>{label}</Form.Label>
      {isPassword ?
        <>
          <InputGroup>
            <Form.Control size="sm" type={passwordVisible ? "text" : "password"} {...props} autoComplete="new-password" />
            <Button variant="outline-secondary" className="inline-form__password-view small-pwd-input d-flex align-items-center" onClick={handleClickShowPassword}>
              <img src={passwordVisible ? eyeIcon : eyeHiddenIcon} alt="Password View" />
            </Button>
          </InputGroup>
          {error && error[props.name] && (
            <p className="text-danger text-sm pt-1">{error[props.name]}</p>
          )}
        </>
        :
        <>
          <Form.Control size="sm" type={type ? type : "text"} {...props} maxLength={maxLength} />
          {error && error[props.name] && (
            <p className="text-danger text-sm pt-1 mb-0">{error[props.name]}</p>
          )}
        </>
      }
    </Form.Group>
  )
};
