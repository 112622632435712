export const pageRoutes = {
    LOGIN: "/auth/login",
    FORGOT_PASSWORD: "/auth/forgot-password",
    CHANGED_PASSWORD_SUCCESSFULLY: "/auth/change-password-successfully",
    CHANGED_PASSWORD: "/auth/change-password",
    COCKPIT: "/cockpit",
    SEARCH: "/search",
    REPORTS: "/reports",
    SCHEDULER: "/scheduler",
    REPORT_DESIGNER: "/report-designer",
    CONFIGURE: "/configure",
    USER_MANAGEMENT: "/user-management",
    SETTINGS: "/settings",
    MODELS: "/models"
}

export const tokens = {
    AUTH_TOKEN: "auth_token",
    EMAIL: "email",
    NAME: "name",
    RANDOM_PASSWORD: "random_password",
    IS_FORGOT: "is_forgot",
}

export const notificationType = {
    SUCCESS: "success",
    ERROR: "error",
    WARNING: "warning"
}