import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap"
import FormText from "../../shared/components/form/FormText";
import { useSettings } from "../../shared/hooks-context/settings-context-hook";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

const UpdateProfileModal = (props) => {
    const { userProfile } = useSettings();
    const {
        register,
        reset,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = useForm({ mode: "onChange", defaultValues: { id: 0 } });
    const { isLoading, updateUserProfile } = useSettings();

    useEffect(() => {
        if (userProfile) {
            reset(userProfile);
        }
    }, [userProfile])

    const onClearModal = () => {
        props.onHide();
        reset();
    }

    const onUpdateProfile = async (formData) => {
        const response = await updateUserProfile(formData);
        if (response?.success) {
            onClearModal();
        }
    }

    return (
        <Modal
            {...props} onHide={onClearModal}
            dialogClassName="modal-830w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Update Profile
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col xs={6}>
                            <FormText
                                type="text"
                                name="name"
                                label="Full Name"
                                placeholder="Enter User Name"
                                maxLength={50}
                                register={register}
                                validationSchema={{
                                    required: "Username is required",
                                }}
                                errors={errors} />
                        </Col>
                        <Col xs={6}>
                            <FormText
                                type="email"
                                name="email"
                                label="Email"
                                placeholder="Enter From Email"
                                register={register}
                                disable={true}
                            />
                        </Col>
                        <Col xs={6}>
                            <FormText
                                type="text"
                                name="designation"
                                label="Designation"
                                placeholder="Enter Designation"
                                register={register}
                            />
                        </Col>
                        <Col xs={6}>
                            <FormText
                                type="text"
                                name="department"
                                label="Department"
                                placeholder="Enter Department"
                                register={register} />
                        </Col>
                        <Col xs={6}>
                            <FormText
                                type="text"
                                name="location"
                                label="Location"
                                placeholder="Enter Location"
                                register={register} />
                        </Col>
                        <Col xs={6}>
                            <FormText
                                type="number"
                                name="contact"
                                label="Contact"
                                placeholder="Enter Contact"
                                register={register}
                            />
                        </Col>
                        <Col xs={12}>
                            <Button variant="secondary px-4 d-block me-4" disabled={!isDirty || !isValid || isLoading?.isUpdate} onClick={handleSubmit(onUpdateProfile)}>
                                {isLoading?.isUpdate && <Spinner
                                    className="me-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />}
                                Update
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default UpdateProfileModal;