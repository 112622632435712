import { useEffect } from "react";
import "../../assets/scss/cockpit.scss";
import Title from "../../shared/components/Title";
import reportIcon from "../../assets/images/no-of-reports.png";
import dataExportedIcon from "../../assets/images/data-exported.png";
import trendIcon from "../../assets/images/trend.png";
import downArrowIcon from "../../assets/images/down-arrow.png";
import ReportGenerationTrendChart from "./ReportGenerationTrendChart";
import RecentReportActivity from "./RecentReportActivity";
import UpcomingReportSchedules from "./UpcomingReportSchedules";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { useDashboard } from "../../shared/hooks-context/dashboard-context-hook";
import {
  convertFileSize,
  convertFileSizeNoType,
} from "../../shared/shared-method";
Chart.register(...registerables);

const Cockpit = () => {
  const { widgets, getCockpitWidget } = useDashboard();

  useEffect(() => {
    let getFetchData = async () => {
      await getCockpitWidget();
    };
    getFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const labels = ["01", "02", "03", "04", "05", "06", "07"];
  const reportsData = {
    labels: labels,
    datasets: [
      {
        type: "bar",
        label: "Reports",
        data: widgets?.currentWeekNoofReports?.dateWiseData,
        borderColor: "#3293CC",
        backgroundColor: "#3293CC",
      },
    ],
  };

  const convertDataToFileSizes = (data) => {
    return data?.map((bytes) => convertFileSizeNoType(bytes));
  };

  const exportedData = {
    labels: labels,
    datasets: [
      {
        type: "bar",
        label: "Reports",
        data: convertDataToFileSizes(
          widgets?.currentWeekDataExported?.dateWiseData
        ),
        borderColor: "#3293CC",
        backgroundColor: "#3293CC",
      },
    ],
  };

  const chartOptions = {
    plugins: {
      datalabels: {
        display: true,
        color: "black",
        formatter: Math.round,
        anchor: "end",
        offset: -11,
        align: "start",
        font: { size: "8" },
      },
      responsive: true,
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        display: false, // Hide Y axis labels
      },
      x: {
        display: false, // Hide X axis labels
      },
    },
    layout: {
      padding: {
        top: 10,
      },
    },
  };

  return (
    <div className="cockpit-page">
      <Title title="Cockpit" />
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-12 widget-card-block">
            <div className="card widget-card-block__card">
              <h3 className="widget-card-block__title">
                Report Generated Count
              </h3>
              <div className="d-flex align-items-center justify-content-between mt-auto">
                <h4 className="widget-card-block__count mb-0">
                  {widgets?.totalNoofReports ? widgets?.totalNoofReports : 0}
                </h4>
                <img
                  src={reportIcon}
                  alt="Reports Icon"
                  className="img-fluid widget-card-block__img"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 widget-card-block">
            <div className="card widget-card-block__card">
              <h3 className="widget-card-block__title">Data Exported - YTD</h3>
              <div className="d-flex align-items-center justify-content-between mt-auto">
                <h4 className="widget-card-block__count mb-0">
                  {widgets?.totalDataExported
                    ? convertFileSize(widgets?.totalDataExported)
                    : 0}
                </h4>
                <img
                  src={dataExportedIcon}
                  alt="Data Exported Icon"
                  className="img-fluid widget-card-block__img"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 widget-card-block">
            <div className="card widget-card-block__card">
              <h3 className="widget-card-block__title">
                Report Generated Count - Last 7 days
              </h3>
              <div className="d-flex align-items-center justify-content-between mt-auto">
                <div className="d-flex align-items-center">
                  <h4 className="widget-card-block__count mb-0 me-3">
                    {widgets?.currentWeekNoofReports?.noofReport
                      ? widgets?.currentWeekNoofReports?.noofReport
                      : 0}
                  </h4>
                  <span
                    className="d-flex align-items-end"
                    style={{
                      color:
                        widgets?.currentWeekNoofReports?.percentage > 0
                          ? "#1AC95A"
                          : "#EE5252",
                    }}
                  >
                    <img
                      src={
                        widgets?.currentWeekNoofReports?.percentage > 0
                          ? trendIcon
                          : downArrowIcon
                      }
                      alt="Trend Icon"
                      className="me-2"
                    />
                    <span className="widget-card__stock-upDown-arrow fw-bold">
                      {widgets?.currentWeekNoofReports?.percentage
                        ? widgets?.currentWeekNoofReports?.percentage
                        : 0}
                      %
                    </span>
                  </span>
                </div>
                <div className="widget-card-block__bar-chart">
                  <Bar
                    data={reportsData}
                    plugins={[ChartDataLabels]}
                    options={chartOptions}
                    width={90}
                    height={60}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 widget-card-block">
            <div className="card widget-card-block__card">
              <h3 className="widget-card-block__title">
                Data Exported - Last 7 days
              </h3>
              <div className="d-flex align-items-center justify-content-between mt-auto">
                <div className="d-flex align-items-end mr-3">
                  <h4 className="widget-card-block__count mb-0 me-3">
                    {widgets?.currentWeekDataExported?.dataExported
                      ? convertFileSize(
                          widgets?.currentWeekDataExported?.dataExported
                        )
                      : 0}
                  </h4>
                  <span
                    className="d-flex align-items-center"
                    style={{
                      color:
                        widgets?.currentWeekDataExported?.percentage > 0
                          ? "#1AC95A"
                          : "#EE5252",
                    }}
                  >
                    <img
                      src={
                        widgets?.currentWeekDataExported?.percentage > 0
                          ? trendIcon
                          : downArrowIcon
                      }
                      alt="Down Arrow Icon"
                      className="me-2"
                    />
                    <span className="widget-card__stock-upDown-arrow fw-bold">
                      {widgets?.currentWeekDataExported?.percentage
                        ? widgets?.currentWeekDataExported?.percentage
                        : 0}
                      %
                    </span>
                  </span>
                </div>
                <div className="widget-card-block__bar-chart">
                  <Bar
                    data={exportedData}
                    plugins={[ChartDataLabels]}
                    options={chartOptions}
                    width={90}
                    height={60}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-12">
            <div className="row">
              <div className="col-12">
                <ReportGenerationTrendChart />
              </div>
              <div className="col-12">
                <UpcomingReportSchedules />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <RecentReportActivity />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cockpit;
