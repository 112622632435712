import "../../../assets/scss/table.scss";
import DataTable from "react-data-table-component";
import AppLoading from "../loader/AppLoader";

const ReactDataTable = (props) => {
    const { columns, data, subHeader, subHeaderComponent, selectableRows, selectableRowDisabled, onSelectedRowsChange, pagination, progressPending, title, clearSelectedRows } = props;

    return (
        <div className="d-flex justify-content-center flex-column">
            <DataTable className={`${progressPending ? "isReact-loader" : "isReact-table"}`}
                title={title}
                columns={columns}
                data={data}
                pagination={pagination}
                subHeader={subHeader}
                selectableRows={selectableRows}
                selectableRowDisabled={selectableRowDisabled}
                onSelectedRowsChange={onSelectedRowsChange}
                clearSelectedRows={clearSelectedRows}
                subHeaderComponent={subHeaderComponent}
                progressPending={progressPending}
                progressComponent={<AppLoading />}
            />
        </div>
    )
}

export default ReactDataTable