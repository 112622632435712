import { Bar } from "react-chartjs-2";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { useEffect, useState } from "react";
import { Chart, registerables } from 'chart.js';
import { reportDayLists } from "../../shared/config/array-config";
import { useDashboard } from "../../shared/hooks-context/dashboard-context-hook";
import { convertFileSizeNoType } from "../../shared/shared-method";
import moment from "moment";
import AppLoading from "../../shared/components/loader/AppLoader";

Chart.register(...registerables);

const ReportGenerationTrendChart = () => {
    const { isChatLoading, reportChartData, getCockpitReportGenerationTrend } = useDashboard();
    const [radioValue, setRadioValue] = useState(30);
    const [filterReports, setFilterReports] = useState([]);

    useEffect(() => {
        let getFetchData = async () => {
            await getCockpitReportGenerationTrend();
        };
        getFetchData();
    }, [])

    useEffect(() => {
        let filteredReports = reportChartData;

        if (radioValue) {
            const cutoffDate = new Date();
            cutoffDate.setDate(cutoffDate.getDate() - Number(radioValue));

            filteredReports = filteredReports.filter(report => {
                const executionDate = new Date(report.date);
                return executionDate > cutoffDate;
            });
        }

        setFilterReports(filteredReports);

    }, [radioValue, reportChartData]);

    const labels = filterReports?.map((report) => moment(report?.date).format("DD-MMM"));
    const data = {
        labels: labels,
        datasets: [
            {
                type: 'line',
                label: 'Reports',
                data: filterReports?.map((report) => report?.totalReports),
                borderColor: '#DCC153'
            },
            {
                type: 'bar',
                label: 'Data',
                data: filterReports?.map((report) => convertFileSizeNoType(report?.totalDataSize)),
                fill: false,
                borderColor: '#3293CC',
                backgroundColor: '#3293CC'
            },
        ],
    };

    const chartOptions = {
        plugins: {
            responsive: true,
            legend: {
                position: "bottom",
                align: "end",
                labels: {
                    boxWidth: 13,
                }
            }
        }
    };

    return (
        <div className="card">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
                <h2 className="card-heading mb-3">Report Generation Trend</h2>
                <ButtonGroup className="chart-tab  mb-3">
                    {reportDayLists.map((radio, idx) => (
                        <ToggleButton
                            key={idx}
                            id={`Report-trend-${idx}`}
                            type="radio"
                            variant={radio.value === Number(radioValue) ? 'secondary' : 'light'}
                            name="radio"
                            value={radio.value}
                            checked={radio.value === Number(radioValue)}
                            onChange={(e) => setRadioValue(e.currentTarget.value)}
                        >
                            {radio.name}
                        </ToggleButton>
                    ))}
                </ButtonGroup>
            </div>
            {isChatLoading ? <div style={{ height: "404px" }}><AppLoading /></div> : <Bar data={data} options={chartOptions} />}
        </div>
    );
}

export default ReportGenerationTrendChart