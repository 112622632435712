import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Title from '../../shared/components/Title';
import Distribution from './Distribution';
import "../../assets/scss/configure.scss";
import SMTP from './SMTP';

const Configure = () => {
    return (
        <>
            <Title title="Configure" />
            <div className="card configure">
                <Tabs
                    defaultActiveKey="distribution"
                    id="uncontrolled-tab-example"
                    className="mb-5 setting-tabs"
                >
                    <Tab eventKey="distribution" title="Distribution">
                        <Distribution />
                    </Tab>
                    <Tab eventKey="SMTP" title="SMTP">
                        <SMTP />
                    </Tab>
                </Tabs>
            </div>
        </>
    )
}

export default Configure;