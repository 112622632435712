import { Link, useLocation } from "react-router-dom";
import { SidebarConfig } from "../../shared/config/sidebar-config";
import "../../assets/scss/sidebar.scss"

const Sidebar = () => {
    const location = useLocation();

    const isActive = (itemPath, currentPath) => {
        return currentPath.startsWith(itemPath);
    };
    return (
        <div className="sidebar">
            <ul className="nav flex-column">
                {SidebarConfig?.map((item) => {
                    return (
                        <li className="nav-item" key={item?.name}>
                            <Link to={item?.path} className={`nav-link ${isActive(item.path, location.pathname) ? "active" : ""}`}>
                                <img src={item?.icon} alt={item?.name} className="nav-img" />
                                <span className="d-block pt-2">{item?.name}</span>
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Sidebar