import PropTypes from 'prop-types';
import React, { createContext, useContext, useCallback, useState } from "react";
import { executeAssessmentAPI } from "./base-context";
import { dashboardAPI } from "../api/apis";
import { notificationType } from '../constants/constants';
import { useNotification } from './notification-context-hook';

export const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const { addMessage } = useNotification();
  const [isActivityLoading, setIsActivityLoading] = useState(false);
  const [isChatLoading, setIsChartLoading] = useState(false);
  const [isScheduleLoading, setIsScheduleLoading] = useState(false);
  const [searchLists, setSearchLists] = useState([]);
  const [recentReportActivityLists, setRecentReportActivityLists] = useState([]);
  const [upcomingSchedulesLists, setUpcomingSchedulesLists] = useState([]);
  const [reportChartData, setReportChartData] = useState([]);
  const [widgets, setWidgets] = useState(null);

  const getGlobalSearch = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(dashboardAPI.getGlobalSearch, request);
      if (result?.success) {
        setSearchLists(result?.data);
      } else {
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.error('Error fetching search:', error);
    }
  }, []);

  const getCockpitWidget = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(dashboardAPI.getCockpitWidget, request);
      if (result?.success) {
        setWidgets(result?.data);
      } else {
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.error('Error fetching widget:', error);
    }
  }, []);

  const getCockpitReportGenerationTrend = useCallback(async (request) => {
    setIsChartLoading(true);
    try {
      const result = await executeAssessmentAPI(dashboardAPI.getCockpitReportGenerationTrend, request);
      if (result?.success) {
        setIsChartLoading(false);
        setReportChartData(result?.data);
      } else {
        setIsChartLoading(false);
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.error('Error fetching chart:', error);
    }
  }, []);

  const getCockpitRecentReportActivity = useCallback(async (request) => {
    setIsActivityLoading(true);
    try {
      const result = await executeAssessmentAPI(dashboardAPI.getCockpitRecentReportActivity, request);
      if (result?.success) {
        setIsActivityLoading(false);
        setRecentReportActivityLists(result?.data);
      } else {
        setIsActivityLoading(false);
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.log('error', error)
      console.error('Error fetching Report activity:', error);
    }
  }, []);

  const getCockpitUpcomingReportSchedules = useCallback(async (request) => {
    setIsScheduleLoading(true);
    try {
      const result = await executeAssessmentAPI(dashboardAPI.getCockpitUpcomingReportSchedules, request);
      if (result?.success) {
        setIsScheduleLoading(false);
        setUpcomingSchedulesLists(result?.data);
      } else {
        setIsScheduleLoading(false);
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.error('Error fetching Report schedules:', error);
    }
  }, []);

  const contextValue = {
    isChatLoading,
    isActivityLoading,
    isScheduleLoading,
    setSearchLists,
    searchLists,
    widgets,
    recentReportActivityLists,
    upcomingSchedulesLists,
    reportChartData,
    getGlobalSearch,
    getCockpitWidget,
    getCockpitRecentReportActivity,
    getCockpitUpcomingReportSchedules,
    getCockpitReportGenerationTrend
  };

  return (
    <DashboardContext.Provider value={contextValue}>
      {children}
    </DashboardContext.Provider>
  )
};

DashboardProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useDashboard = () => useContext(DashboardContext);
