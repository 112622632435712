// Importing helper modules
import { useCallback, useEffect, useMemo, useRef, useState, } from "react";

// Importing core components
import QuillEditor from "react-quill";

// Importing styles
import "react-quill/dist/quill.snow.css";

const Editor = ({ setValue, name, value, editorValue, setEditorValue }) => {
    const quill = useRef();

    useEffect(() => {
        if (value) {
            setEditorValue(value)
        }
    }, [value])

    const imageHandler = useCallback(() => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = () => {
            const file = input.files[0];
            const reader = new FileReader();

            reader.onload = () => {
                const imageUrl = reader.result;
                const quillEditor = quill.current.getEditor();

                const range = quillEditor.getSelection(true);
                quillEditor.insertEmbed(range.index, "image", imageUrl);
            };

            reader.readAsDataURL(file);
        };
    }, []);

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [2, 3, 4, false] }],
                    ["bold", "italic", "underline", "blockquote"],
                    [{ color: [] }],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                    ],
                    ["link", "image"],
                    ["clean"],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
            clipboard: {
                matchVisual: true,
            },
        }),
        [imageHandler]
    );

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "clean",
    ];

    return (
        <div className="editor-wrapper">
            <QuillEditor
                ref={(el) => (quill.current = el)}
                className="editor-wrapper__editor"
                theme="snow"
                value={editorValue}
                formats={formats}
                modules={modules}
                onChange={(value) => {
                    setEditorValue(value);
                    setValue(name, value, { shouldDirty: true });
                }}
            />
        </div>
    );
};

export default Editor;