import { reportDesignerColumns } from "../../shared/constants/data-columns";
import Title from "../../shared/components/Title";
import ReactDataTable from "../../shared/components/table/ReactDataTable";
import deleteIcon from "../../assets/images/delete-icon.svg";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { pageRoutes } from "../../shared/constants/constants";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import SearchFilter from "../../shared/components/table/SearchFilter";
import CopyReportDesignerModal from "./CopyReportDesignerModal";
import { useReportDesigner } from "../../shared/hooks-context/report-designer-context-hook";
import ReactSweetAlert from "../../shared/components/ReactSweetAlert";
import ShareReportDesignerModal from "./ShareReportDesignerModal";
import {
  IS_COPY_REPORT,
  NEXT_STEP,
  PREV_STEP,
  SET_DATASOURCE_ID,
  SKIP_FINISH,
  UPDATE_ACCOUNT_INFO,
  formReducer,
  initialState,
} from "../DxReport";
import { useReducer } from "react";
import CheckConnectionModal from "./CheckConnectionModal";
const ReportDesigner = () => {
  const [state, dispatch] = useReducer(formReducer, initialState);
  const {
    isReportDesignerLoading,
    getReportDesignerList,
    getCheckConnection,
    reportDesignerData,
    deleteReportDesigner,
  } = useReportDesigner();
  const [isDeleteModal, setIsDeleteModal] = useState({
    isModal: false,
    name: "",
  });
  const [toggleCleared, setToggleCleared] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [singleReportDesignerId, setSingleReportDesignerId] = useState("");
  const [reportDesignerId, setReportDesignerId] = useState([]);
  const [isCopyModal, setIsCopyModal] = useState(false);
  const [isShareModal, setIsShareModal] = useState(false);
  const [isCheckConnectionModal, setIsCheckConnectionModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let getFetchData = async () => {
      await getReportDesignerList();
    };
    getFetchData();
  }, []);

  const handleRowSelected = useCallback((state) => {
    setReportDesignerId(
      state.selectedRows.map((selectedRow) => selectedRow.id)
    );
  }, []);

  const filteredItems = reportDesignerData?.filter(
    (item) =>
      item.reportName?.toLowerCase().includes(filterText.toLowerCase()) ||
      item.schema?.toLowerCase().includes(filterText.toLowerCase()) ||
      item.createdby?.toLowerCase().includes(filterText.toLowerCase()) ||
      item.lastupdated?.toLowerCase().includes(filterText.toLowerCase())
  );

  const onOpenReportDeleteModal = (item) => {
    if (item?.id) {
      setReportDesignerId([item?.id]);
    }
    setIsDeleteModal({ isModal: true, name: item?.reportName });
  };

  const onOpenDeleteModal = () => {
    setIsDeleteModal({ isModal: true, name: "Report Designer" });
  };

  const onCancelReportDesignerDelete = async () => {
    setToggleCleared(!toggleCleared);
    setIsDeleteModal({ isModal: false, name: "" });
    setReportDesignerId([]);
  };

  const onConfirmDeleteReportDesigner = async () => {
    const response = await deleteReportDesigner(reportDesignerId);
    if (response?.success) {
      onCancelReportDesignerDelete();
    }
  };

  const onClickCopyModal = (id) => {
    setSingleReportDesignerId(id);
    setIsCopyModal(true);
    var reportObj = filteredItems.find((x) => x.id == id);
    if (reportObj) {
      dispatch({ type: SET_DATASOURCE_ID, value: reportObj.datasource_id });
      dispatch({ type: SKIP_FINISH, value: true });
      dispatch({ type: IS_COPY_REPORT, value: true });

      if (state.step > 1) {
        dispatch({ type: PREV_STEP });
      }
    } else {
      dispatch({ type: SET_DATASOURCE_ID, value: 0 });
    }
  };

  const onClickPreviewReport = async (id) => {
    if (id) {
      const response = await getCheckConnection({ reportId: id });
      if (response?.success) {
        navigate(`${pageRoutes.REPORT_DESIGNER}/view/${id}`);
      } else {
        setIsCheckConnectionModal(true);
      }
    }
  };

  const onClickShareModal = async (id) => {
    if (id) {
      const response = await getCheckConnection({ reportId: id });
      if (response?.success) {
        setSingleReportDesignerId(id);
        setIsShareModal(true);
      } else {
        setIsCheckConnectionModal(true);
      }
    }
  };

  const onFilter = (value) => {
    setFilterText(value);
  };

  const columns = reportDesignerColumns({
    navigate,
    onClickPreviewReport,
    onOpenReportDeleteModal,
    onClickCopyModal,
    onClickShareModal,
  });

  const subHeaderComponent = useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="text-start pe-3">
          <Button
            variant="secondary"
            onClick={() => navigate(`${pageRoutes.REPORT_DESIGNER}/create`)}
          >
            + Create New
          </Button>
        </div>
        <div className="d-flex align-items center justify-content-end">
          <Button
            className="delete-btn"
            title="Delete"
            onClick={onOpenDeleteModal}
            disabled={reportDesignerId?.length === 0 ? true : false}
          >
            <img src={deleteIcon} alt="Delete Icon" />
          </Button>
          <Form className="d-flex align-items center">
            <SearchFilter
              onFilter={onFilter}
              filterText={filterText}
              placeholder={`Search Report`}
            />
          </Form>
        </div>
      </div>
    );
  }, [filterText, reportDesignerId]);

  const rowDisabledChockRow = (row) => (!row?.deletable ? true : false);
  return (
    <>
      <Title title="Report Designer" />
      <div className="card-table">
        <ReactDataTable
          columns={columns}
          data={filteredItems}
          selectableRows
          subHeader={true}
          onSelectedRowsChange={handleRowSelected}
          selectableRowDisabled={rowDisabledChockRow}
          clearSelectedRows={toggleCleared}
          pagination
          subHeaderComponent={subHeaderComponent}
          progressPending={isReportDesignerLoading.isList}
        />
      </div>
      {isDeleteModal?.isModal && (
        <ReactSweetAlert
          onDeleteModal={onConfirmDeleteReportDesigner}
          onCancelModal={() => {
            onCancelReportDesignerDelete();
          }}
          name={isDeleteModal?.name}
        />
      )}
      <CopyReportDesignerModal
        state={state}
        dispatch={dispatch}
        show={isCopyModal}
        copyId={singleReportDesignerId}
        onHide={() => setIsCopyModal(false)}
      />
      <ShareReportDesignerModal
        show={isShareModal}
        onHide={() => setIsShareModal(false)}
        shareId={singleReportDesignerId}
      />
      <CheckConnectionModal
        show={isCheckConnectionModal}
        onHide={() => setIsCheckConnectionModal(false)}
      />
    </>
  );
};

export default ReportDesigner;
