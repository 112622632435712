import PropTypes from "prop-types";
import React, { createContext, useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { executeAssessmentAPI } from "./base-context";
import { notificationType, pageRoutes, tokens } from "../constants/constants";
import { authenticateAPI } from "../api/apis";
import { jwtDecode } from "jwt-decode";
import { useNotification } from "./notification-context-hook";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const { addMessage } = useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const login = useCallback(
    async (request) => {
      setIsLoading(true);
      try {
        const result = await executeAssessmentAPI(
          authenticateAPI.userLogin,
          request
        );
        if (result.success) {
          setIsLoading(false);
          const tokenPayload = jwtDecode(result?.data?.token);
          localStorage.setItem(tokens.AUTH_TOKEN, result?.data?.token);
          localStorage.setItem(tokens.EMAIL, tokenPayload?.username);
          localStorage.setItem(tokens.NAME, tokenPayload?.name);
          if (result?.data?.isForgot) {
            navigate(pageRoutes.CHANGED_PASSWORD);
          } else {
            navigate(pageRoutes.COCKPIT);
          }
        } else {
          setIsLoading(false);
          addMessage({ message: result?.message, type: notificationType.ERROR });
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Login error:", error);
      }
    },
    [navigate]
  );

  const forgotPassword = useCallback(
    async (request) => {
      setIsLoading(true);
      try {
        const result = await executeAssessmentAPI(
          authenticateAPI.userForgotPassword,
          request
        );
        if (result.success) {
          setIsLoading(false);
          localStorage.setItem(tokens.RANDOM_PASSWORD, result?.data?.password);
          localStorage.setItem(tokens.IS_FORGOT, result?.data?.isForgot);
          navigate(pageRoutes.CHANGED_PASSWORD_SUCCESSFULLY);
        } else {
          setIsLoading(false);
          addMessage({ message: result?.message, type: notificationType.ERROR });
        }
      } catch (error) {
        setIsLoading(false);
        console.error("ForgotPassword error:", error);
      }
    },
    [navigate]
  );

  const changePassword = useCallback(
    async (request) => {
      setIsLoading(true);
      try {
        const result = await executeAssessmentAPI(
          authenticateAPI.userChangePassword,
          request
        );
        if (result.success) {
          setIsLoading(false);
          localStorage.removeItem(tokens.RANDOM_PASSWORD);
          localStorage.removeItem(tokens.IS_FORGOT);
          navigate(pageRoutes.COCKPIT);
        } else {
          setIsLoading(false);
          addMessage({ message: result?.message, type: notificationType.ERROR });
        }
      } catch (error) {
        setIsLoading(false);
        console.error("ChangePassword error:", error);
      }
    },
    [navigate]
  );

  const settingChangePassword = useCallback(
    async (request) => {
      setIsLoading(true);
      try {
        const result = await executeAssessmentAPI(
          authenticateAPI.userChangePassword,
          request
        );
        if (result.success) {
          setIsLoading(false);
          addMessage({ message: result?.message, type: notificationType.SUCCESS });
          return result;
        } else {
          setIsLoading(false);
          addMessage({ message: result?.message, type: notificationType.ERROR });
        }
      } catch (error) {
        setIsLoading(false);
        console.error("ChangePassword error:", error);
      }
    },
    []
  );
  const userRefreshToken = useCallback(
    async (request) => {
      try {
        const result = await executeAssessmentAPI(
          authenticateAPI.userRefreshToken,
          request
        );
        if (result.success) {
          return result;
        } else {
          addMessage({ message: result?.message, type: notificationType.ERROR });
        }
      } catch (error) {
        console.error("ChangePassword error:", error);
      }
    },
    [navigate]
  );

  const contextValue = {
    isLoading,
    login,
    forgotPassword,
    changePassword,
    settingChangePassword,
    userRefreshToken
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useAuth = () => useContext(AuthContext);
