import { Button } from "react-bootstrap";
import { useSettings } from "../../shared/hooks-context/settings-context-hook";
import { useEffect, useState } from "react";
import UpdateProfileModal from "./UpdateProfileModal";

const Overview = () => {
    const { userProfile, getUserProfile } = useSettings();
    const [isProfileModal, setIsProfileModal] = useState(false);

    useEffect(() => {
        let getFetchData = async () => {
            await getUserProfile();
        };
        getFetchData();
    }, [])

    return (
        <>
            <h3 className="mb-5 fs-5">Basic Information</h3>
            <div className="basic-information">
                <div className="d-flex align-items-center">
                    <span className="basic-information__user-label"><span>Full Name</span><span>:</span></span>
                    <span className="basic-information__user-input">{userProfile?.name}</span>
                </div>
                <div className="d-flex align-items-center">
                    <span className="basic-information__user-label"><span>Designation</span><span>:</span></span>
                    <span className="basic-information__user-input">{userProfile?.designation}</span>
                </div>
                <div className="d-flex align-items-center">
                    <span className="basic-information__user-label"><span>Department</span><span>:</span></span>
                    <span className="basic-information__user-input">{userProfile?.department}</span>
                </div>
                <div className="d-flex align-items-center">
                    <span className="basic-information__user-label"><span>Email Address</span><span>:</span></span>
                    <span className="basic-information__user-input">{userProfile?.email}</span>
                </div>
                <div className="d-flex align-items-center">
                    <span className="basic-information__user-label"><span>Location</span><span>:</span></span>
                    <span className="basic-information__user-input">{userProfile?.location}</span>
                </div>
                <div className="d-flex align-items-center">
                    <span className="basic-information__user-label"><span>Contact</span><span>:</span></span>
                    <span className="basic-information__user-input">{userProfile?.contact}</span>
                </div>
                <Button variant="secondary me-3 px-3" onClick={() => setIsProfileModal(true)}>Edit Profile</Button>
            </div>
            <UpdateProfileModal show={isProfileModal} onHide={() => setIsProfileModal(false)} />
        </>
    )
}

export default Overview;