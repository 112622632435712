import Modal from 'react-bootstrap/Modal';
import "../../../assets/scss/modal.scss";
import successIcon from "../../../assets/images/success-icon.svg";

const ActionModal = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="action-modal"
        >
            <Modal.Header closeButton className="border-0 pb-0" />
            <Modal.Body className="pt-0 mb-4">
                <img src={successIcon} alt="success Icon" className="image-fluid d-block mx-auto mb-3" />
                <h4 className="action-modal__title text-center mb-0">
                    Job ID - 124 <br />{props.type} Successfully</h4>
            </Modal.Body>
        </Modal>
    )
}

export default ActionModal;