import React from "react";
import Form from "react-bootstrap/Form";

const SearchFilter = (props) => {
  const { filterText, onFilter, placeholder, className } = props;

  const onSearchFilter = (e) => {
    e.preventDefault();
    onFilter(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default action if Enter key is pressed
      onFilter(e.target.value);
    }
  };

  return (
    <Form.Control
      type="search"
      placeholder={`${placeholder ? placeholder : "Search User"}`}
      className={`table-search ${className}`}
      value={filterText}
      onChange={(e) => onSearchFilter(e)}
      onKeyPress={handleKeyPress}
    />
  );
};

export default SearchFilter;
