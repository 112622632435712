import Button from "react-bootstrap/Button";
import editIcon from "../../assets/images/edit-icon.svg";
import previewIcon from "../../assets/images/preview-icon.svg";
import shareIcon from "../../assets/images/share-icon.svg";
import deleteIcon from "../../assets/images/delete-icon.svg";
import userOutlineIcon from "../../assets/images/change-job-owner-icon.svg";
import copyIcon from "../../assets/images/copy-icon.svg";
import fileHistoryIcon from "../../assets/images/file-history-icon.svg";
import resumeIcon from "../../assets/images/resume-icon.svg";
import pauseIcon from "../../assets/images/pause-icon.svg";
// import runIcon from "../../assets/images/run-icon.svg";
import revokeIcon from "../../assets/images/revoke-icon.svg";
import suspendIcon from "../../assets/images/suspend-icon.svg";
import disableUserIcon from "../../assets/images/disable-user-icon.svg";
import { pageRoutes } from "../constants/constants";
import moment from "moment";
import { getDataTime, isDisable } from "../shared-method";
import { Form } from "react-bootstrap";

export const searchReportsColumns = () => {
  return [
    {
      name: "Job ID",
      selector: (row) => row?.id,
      cell: row => <div>{row?.id}</div>,
      sortable: true,
    },
    {
      name: "Template Name",
      selector: (row) => row?.custom_report_name,
      cell: row => <div title={row?.custom_report_name} className="text-truncate">{row?.custom_report_name}</div>,
      sortable: true,
    },
    {
      name: "Report Format",
      selector: (row) => row?.format.toUpperCase(),
      cell: row => <div>{row?.format.toUpperCase()}</div>,
      sortable: true,
    },
    {
      name: "Created by",
      selector: (row) => row?.createdby,
      cell: row => <div>{row?.createdby}</div>,
      sortable: true,
    },
  ]
}

export const searchReportDesignerColumns = () => {
  return [
    {
      name: "Template Name",
      selector: (row) => row?.report_name,
      cell: row => <div title={row?.report_name} className="text-truncate">{row?.report_name}</div>,
      sortable: true,
    },
    {
      name: "No of Jobs associated",
      selector: (row) => row?.jobs,
      cell: row => <div>{row?.jobs}</div>,
      sortable: true,
    },
    {
      name: "Schema",
      selector: (row) => row?.schema,
      cell: row => <div title={row?.schema} className="text-truncate">{row?.schema}</div>,
      sortable: true,
    },
    {
      name: "Created by",
      selector: (row) => row?.createdby,
      cell: row => <div>{row?.createdby}</div>,
      sortable: true,
    },
    {
      name: "Created on",
      selector: (row) => row?.createdon,
      cell: row => <div>{row?.createdon ? moment(row?.createdon).format("YYYY-MM-DD HH:mm:ss") : "__"}</div>,
      sortable: true,
    },
    {
      name: "Last Modified on",
      selector: (row) => row?.lastupdatedon,
      cell: row => <div>{row?.lastupdatedon ? moment(row?.lastupdatedon).format("YYYY-MM-DD HH:mm:ss") : "__"}</div>,
      sortable: true,
    },
  ]
}

export const reportDesignerColumns = ({
  navigate,
  onClickPreviewReport,
  onOpenReportDeleteModal,
  onClickCopyModal,
  onClickShareModal,
}) => {
  return [
    {
      name: "Template Name",
      selector: (row) => row?.reportName,
      cell: row => <div title={row?.reportName} className="text-truncate">{row?.reportName}</div>,
      sortable: true,
    },
    {
      name: "No of Jobs Associated",
      selector: (row) => row?.jobCount,
      cell: row => <div>{row?.jobCount}</div>,
      sortable: true,
    },
    {
      name: "Schema",
      selector: (row) => row?.schema,
      cell: row => <div title={row?.schema} className="text-truncate">{row?.schema}</div>,
      sortable: true,
    },
    {
      name: "Created By",
      selector: (row) => row?.createdby,
      cell: row => <div>{row?.createdby}</div>,
      sortable: true,
    },
    {
      name: "Created On",
      selector: (row) => row?.createdon,
      cell: row => <div className="text-nowrap">{moment(row?.createdon).format("YYYY-MM-DD HH:mm:ss")}</div>,
      sortable: true,
    },
    {
      name: "Last Modified On",
      selector: (row) => row?.lastupdated,
      cell: row => <div className="text-nowrap">{moment(row?.lastupdated).format("YYYY-MM-DD HH:mm:ss")}</div>,
      sortable: true,
    },
    {
      name: "Actions ",
      selector: (row) => (
        <>
          <Button
            className="icon-btn"
            title="Edit"
            disabled={!row?.editable ? true : false}
            onClick={() =>
              navigate(`${pageRoutes.REPORT_DESIGNER}/edit/${row?.id}`)
            }
          >
            <img src={editIcon} alt="Edit Icon" />
          </Button>
          <Button
            className="icon-btn"
            title="Preview"
            onClick={() => onClickPreviewReport(row?.id)}
          >
            <img src={previewIcon} alt="Preview Icon" />
          </Button>
          <Button
            className="icon-btn"
            title="Send Email"
            disabled={!row?.share ? true : false}
            onClick={() => onClickShareModal(row?.id)}
          >
            <img src={shareIcon} alt="Share Icon" />
          </Button>
          <Button
            className="icon-btn"
            title="Delete"
            disabled={!row?.deletable ? true : false}
            onClick={() => onOpenReportDeleteModal(row)}
          >
            <img src={deleteIcon} alt="Delete Icon" />
          </Button>
          <Button
            className="icon-btn"
            title="Copy"
            onClick={() => onClickCopyModal(row?.id)}
          >
            <img src={copyIcon} alt="Copy Icon" />
          </Button>
        </>
      ),
    },
  ];
};

export const reportsJobsColumns = ({ tabName, onEditJobSchedulerModal, onPauseJobScheduler, onRunJobScheduler, onResumeJobScheduler, onOpenJobSchedulerDeleteModal, onHandleViewReport, onChangeJobOwnerModal }) => {
  return [
    {
      name: "Job Id",
      width: "100px",
      selector: (row) => row?.id,
      cell: row => <div>{row?.id}</div>,
      sortable: true,
    },
    {
      name: "Report Name",
      selector: (row) => row?.custom_report_name,
      cell: row => <div title={row?.custom_report_name} className="text-truncate">{row?.custom_report_name}</div>,
      sortable: true,
    },
    {
      name: "Report Format",
      selector: (row) => row?.format.toUpperCase(),
      cell: row => <div>{row?.format.toUpperCase()}</div>,
      sortable: true,
    },
    {
      name: "Created By",
      selector: (row) => row?.createdby,
      cell: row => <div>{row?.createdby}</div>,
      sortable: true,
    },
    {
      name: `${tabName === "Completed" ? "Last Schedule On" : "Next Schedule On"}`,
      sortable: true,
      selector: (row) => row?.jobStatus === 4 ? row.lastupdatedon : row.nextSchedule,
      cell: row => {
        if (row?.jobStatus === 4) {
          return <div className="text-nowrap">{row.lastupdatedon ? moment(row.lastupdatedon).format("YYYY-MM-DD HH:mm:ss") : "__"}</div>;
        } else {
          return <div className="text-nowrap">{row.nextSchedule ? moment(row.nextSchedule).format("YYYY-MM-DD HH:mm:ss") : "__"}</div>;
        }
      },
    },
    {
      name: "History",
      width: "90px",
      selector: (row) => (
        <Button className="icon-btn" onClick={() => onHandleViewReport(row?.id)} title="History">
          <img src={fileHistoryIcon} alt="File History Icon" />
        </Button>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <>
          {row?.jobStatus === 2 ?
            <Button className="icon-btn" title="Resume" onClick={() => onResumeJobScheduler({ id: row?.id })}>
              <img src={resumeIcon} alt="Resume Icon" />
            </Button>
            :
            row?.jobStatus === 1 ?
              <Button className="icon-btn" title="Pause" onClick={() => onPauseJobScheduler({ id: row?.id })}>
                <img src={pauseIcon} alt="Pause Icon" />
              </Button>
              : null
          }
          <Button className="icon-btn" title="Edit" disabled={isDisable(row?.createdby) || row?.jobStatus === 1} onClick={() => onEditJobSchedulerModal(row?.id)}>
            <img src={editIcon} alt="Edit Icon" />
          </Button>
          {/* <Button className="icon-btn" title="Run" onClick={() => onRunJobScheduler(row?.id)}>
            <img src={runIcon} alt="Run Icon" />
          </Button> */}
          <Button className="icon-btn" title="Change Job Owner" disabled={isDisable(row?.createdby)} onClick={() => onChangeJobOwnerModal(row?.id)}>
            <img src={userOutlineIcon} alt="User Icon" />
          </Button>
          <Button className="icon-btn" title="Delete" disabled={isDisable(row?.createdby) || row?.jobStatus === 1} onClick={() => onOpenJobSchedulerDeleteModal(row)}>
            <img src={deleteIcon} alt="Delete Icon" />
          </Button>
        </>
      ),
    },
  ];
};

export const reportsJobsDeletedColumns = () => {
  return [
    {
      name: "Job Id",
      width: "100px",
      selector: (row) => row?.id,
      cell: row => <div>{row?.id}</div>,
      sortable: true,
    },
    {
      name: "Template Name",
      selector: (row) => row?.custom_report_name,
      cell: row => <div title={row?.custom_report_name} className="text-truncate">{row?.custom_report_name}</div>,
      sortable: true,
    },
    {
      name: "Report Format",
      selector: (row) => row?.format.toUpperCase(),
      cell: row => <div>{row?.format.toUpperCase()}</div>,
      sortable: true,
    },
    {
      name: "Created By",
      selector: (row) => row?.createdby,
      cell: row => <div>{row?.createdby}</div>,
      sortable: true,
    },
    {
      name: "Last Schedule On",
      selector: (row) => row.lastupdatedon,
      cell: row => <div>{row.lastupdatedon ? moment(row.lastupdatedon).format("YYYY-MM-DD HH:mm:ss") : "__"}</div>,
      sortable: true,
    },
  ];
};

export const userManagementColumns = ({ onUserActiveInactive, onOpenUserDeleteModal }) => {
  return [
    {
      name: "User Name",
      selector: (row) => row.name,
      cell: row => <div>{row?.name}</div>,
      sortable: true,
    },
    {
      name: "Email Id",
      selector: (row) => row.username,
      cell: row => <div>{row?.username}</div>,
      sortable: true,
    },
    {
      name: "Added On",
      selector: (row) => row?.createdon,
      cell: row => <div className="text-nowrap">{row?.createdon ? moment(row?.createdon).format("YYYY-MM-DD HH:mm:ss") : "__"}</div>,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <>
          <Button className={`icon-btn`} onClick={() => onUserActiveInactive(row?.id)} title={(row?.isregister && !row?.active ? "Invited" : !row?.isregister && !row?.active ? "Activate" : "Deactivate")} >
            <img
              src={(row?.isregister && !row?.active ? disableUserIcon : !row?.isregister && !row?.active ? revokeIcon : suspendIcon)}
              alt="Suspend Icon"
            />
          </Button>
          <Button className="icon-btn" title="Delete" onClick={() => onOpenUserDeleteModal(row)}>
            <img src={deleteIcon} alt="Delete Icon" />
          </Button>
        </>
      ),
    },
  ];
};

export const lookupDatabaseColumns = ({ onOpenLookupDatabaseDeleteModal }) => {
  return [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Email Address",
      selector: (row) => row.email,
    },
    {
      name: "Actions",
      selector: (row) => (
        <>
          <Button
            className="icon-btn"
            title="Delete"
            onClick={() => onOpenLookupDatabaseDeleteModal(row.id)}
          >
            <img src={deleteIcon} alt="Delete Icon" />
          </Button>
        </>
      ),
    },
  ];
};

export const SMTPConfigurationsColumns = ({ onEditSMTP, onDeleteSMTP, onDefaultDistributionSMTP }) => {
  return [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Host",
      selector: (row) => row.host,
    },
    {
      name: "Username",
      selector: (row) => row.username,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="d-flex align-items-center">
          <label className="switch-label small-switch me-2">
            <input
              type="checkbox"
              className="switch-input"
              onChange={() => onDefaultDistributionSMTP(row?.id, row?.isdefault)}
              checked={row?.isdefault}
            />
            <span className="slider round"></span>
          </label>
          <Button
            className="icon-btn py-1 px-2"
            title="Edit"
            onClick={() => onEditSMTP(row?.id)}
          >
            <img src={editIcon} alt="Edit Icon" />
          </Button>
          <Button
            className="icon-btn py-1 px-2"
            title="Delete"
            disabled={row?.isdefault}
            onClick={() => onDeleteSMTP(row)}
          >
            <img src={deleteIcon} alt="Delete Icon" />
          </Button>
        </div>
      ),
    },
  ];
};

export const distributionsDataColumns = ({
  onEditDistribute,
  onDeleteDistribute,
}) => {
  return [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Host",
      selector: (row) => row.host,
    },
    {
      name: "Path",
      selector: (row) => row.path,
    },
    {
      name: "Username",
      selector: (row) => row.username,
    },
    {
      name: "Actions",
      selector: (row) => (
        <>
          <Button
            className="icon-btn py-1 px-2"
            title="Edit"
            onClick={() => onEditDistribute(row?.id)}
          >
            <img src={editIcon} alt="Edit Icon" />
          </Button>
          <Button
            className="icon-btn py-1 px-2"
            title="Delete"
            onClick={() => onDeleteDistribute(row)}
          >
            <img src={deleteIcon} alt="Delete Icon" />
          </Button>
        </>
      ),
    },
  ];
};

export const upcomingSchedulesColumns = () => {
  return [
    {
      name: "Report Template",
      selector: (row) => row?.reportName,
    },
    {
      name: "Report Format",
      selector: (row) => row?.reportFormat,
    },
    {
      name: "Job Id",
      selector: (row) => row?.jobId,
    },
    {
      name: "Type",
      selector: (row) => row?.type,
    },
    {
      name: "	Scheduled On",
      selector: (row) => getDataTime(row?.nextScheduleDate),
    },
  ];
};

export const changeJobOwnerUserColumns = ({ setValue, register, watch, errors }) => {
  return [
    {
      name: "Username",
      selector: (row) => (
        <>
          <Form.Check
            name="email"
            {...register("email", { required: "Please select a user" })}
            className="job-radio d-flex align-items-center"
            type="radio"
            id={row?.id}
            label={row?.name}
            value={row?.username}
            checked={watch("email") === row?.username}
            onChange={(event) => {
              setValue("email", event.target.value);
              if (Object.keys(errors).length > 0) {
                errors.email.message = undefined;
              }
            }}
          />
        </>
      ),
    },
    {
      name: "Email Address",
      selector: (row) => row?.username,
    },
  ];
} 