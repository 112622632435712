import PropTypes from 'prop-types';
import React from "react";
import { NotificationProvider, AuthProvider, DashboardProvider, ReportDesignerProvider, DistributionProvider, LookupDatabaseProvider, SchedulerProvider, UserManagementProvider, SettingsProvider } from "../hooks-context/context";

const ProvidersWrapper = ({ children }) => {
    const providers = {
        NotificationProvider,
        AuthProvider,
        DashboardProvider,
        ReportDesignerProvider,
        DistributionProvider,
        LookupDatabaseProvider,
        SchedulerProvider,
        UserManagementProvider,
        SettingsProvider
    };

    const ProviderComponents = Object.values(providers);

    return (
        <>
            {ProviderComponents.reduceRight((acc, Comp) => {
                return <Comp>{acc}</Comp>;
            }, children)}
        </>
    );
};

ProvidersWrapper.propTypes = {
    children: PropTypes.any.isRequired,
};

export default ProvidersWrapper;
