import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useReportDesigner } from "../../shared/hooks-context/report-designer-context-hook";
import FormText from "../../shared/components/form/FormText";
import ConnectionForm from "../DxReport/ConnetionForm";
import { LOADING } from "../DxReport";
import { validateConnection } from "../../shared/utils/validation";
import { useEffect } from "react";
import notify from "devextreme/ui/notify";

const CopyReportDesignerModal = (props) => {
  const { copyId, onHide, state, dispatch } = props;
  const { AccountInfo, MetaInfo, step } = state;
  const { copyReportDesigner } = useReportDesigner();
  const {
    register,
    reset,
    // handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  // const onCopyReportDesigner = async (formData) => {
  //   let reportRequestBody = updateDataSource();
  //   if (validateConnection(reportRequestBody) == true) {
  //     formData["connectionObj"] = JSON.stringify(reportRequestBody);
  //     const result = await copyReportDesigner({
  //       id: copyId,
  //       ...formData,
  //     });
  //     if (result?.success) {
  //       reset();
  //       onHide();
  //     }
  //   }
  // };

  const onClearModal = () => {
    reset();
    onHide();
  }

  const onCopyReportDesigner = async () => {
    let reportRequestBody = updateDataSource();
    if (reportRequestBody) {
      reportRequestBody["newReportName"] = getValues("newReportName");
    }
    if (!reportRequestBody.newReportName || String(reportRequestBody.newReportName).trim() == "") {
      notify("Template name is required.", "warning");
    } else {
      if (validateConnection(reportRequestBody) == true) {
        reportRequestBody["connectionObj"] = JSON.stringify(reportRequestBody);
        dispatch({ type: LOADING, value: true });
        const result = await copyReportDesigner({
          copyId: copyId,
          ...reportRequestBody,
        });
        if (result?.success) {
          onClearModal();
          dispatch({ type: LOADING, value: false });
        } else {
          dispatch({ type: LOADING, value: false });
        }
      }
    }
  };

  const updateDataSource = () => {
    const obj = { ...AccountInfo, ...MetaInfo };
    obj["Tables"] =
      Array.isArray(MetaInfo.Tables) && MetaInfo.Tables.length > 0
        ? MetaInfo.Tables.map((x) => x.value)
        : [];
    return obj;
  };

  useEffect(() => {
    const btn = document.getElementById("clonesubmitBtn");

    const clickHandler = (e) => {
      e.preventDefault();
      onCopyReportDesigner();
    };

    if (step === 2) {
      btn?.addEventListener("click", clickHandler);
    }

    return () => {
      if (step === 2) {
        btn?.removeEventListener("click", clickHandler);
      }
    };
  }, [step, onCopyReportDesigner]);

  return (
    <Modal
      {...props} onHide={onClearModal}
      dialogClassName="modal-830w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Copy Report Designer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <FormText
            type="text"
            name="newReportName"
            label="Template Name"
            placeholder="Enter Template Name"
            maxLength={50}
            register={register}
            validationSchema={{
              required: "Template name is required.",
            }}
            errors={errors}
          />
          <ConnectionForm state={state} dispatch={dispatch} />
          {/* <div className="d-flex justify-content-center align-items-center mt-4">
            <Button
              variant="secondary me-3 px-3"
              onClick={handleSubmit(onCopyReportDesigner)}
            >
              Submit
            </Button>
          </div> */}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CopyReportDesignerModal;
