import React, { useEffect, useState } from "react";
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { useLookupDatabase } from "../../hooks-context/lookup-database-context-hook";

const isValidEmail = (email) => {
    // Regular expression for validating email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const FormEmailChip = ({ name, setValue, errors, setToEmailError, isClear, setIsClear, setCcEmailError, ccEmailError, setBccEmailError, bccEmailError }) => {
    const { searchEmailLists, getSearchMailLookupDatabase } = useLookupDatabase();
    const [emailSuggestionLists, setEmailSuggestionLists] = useState([]);
    const [search, setSearch] = useState("");
    const [emails, setEmails] = useState([]);
    const [focused, setFocused] = useState(false);
    const [inputKey, setInputKey] = useState(Date.now());

    useEffect(() => {
        let getFetchData = async () => {
            await getSearchMailLookupDatabase();
        };
        getFetchData();
    }, []);

    useEffect(() => {
        if (search) {
            setEmailSuggestionLists(searchEmailLists?.filter((item) =>
                item.email?.toLowerCase().includes(search.toLowerCase())));
        } else {
            setEmailSuggestionLists([]);
            if (setToEmailError) {
                setToEmailError("")
            }
            if (setCcEmailError) {
                setCcEmailError("")
            }
            if (setBccEmailError) {
                setBccEmailError("")
            }
        }
    }, [search]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest(".auto__complete")) {
                setEmailSuggestionLists([]);
            }
        };

        document.body.addEventListener("click", handleClickOutside);

        return () => {
            document.body.removeEventListener("click", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isClear) {
            setEmails([]);
            setValue(name, "");
            setInputKey(Date.now());
        }
    }, [isClear]);

    const selectOption = (email) => {
        setEmails([...emails, email]);
        setSearch("");
        setValue(name, [...emails, email]);
        setEmailSuggestionLists([]);
        setInputKey(Date.now());
        if (setToEmailError) {
            setToEmailError("")
        }
        if (setCcEmailError) {
            setCcEmailError("")
        }
        if (setBccEmailError) {
            setBccEmailError("")
        }
    };

    return (
        <>
            <div className="position-relative">
                <div className="input-chip position-relative">
                    <span className="user-icon">
                        <svg fill="#ffffff" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m17 19v-2.5c0-.2761424.2238576-.5.5-.5s.5.2238576.5.5v2.5h2.5c.2761424 0 .5.2238576.5.5s-.2238576.5-.5.5h-2.5v2.5c0 .2761424-.2238576.5-.5.5s-.5-.2238576-.5-.5v-2.5h-2.5c-.2761424 0-.5-.2238576-.5-.5s.2238576-.5.5-.5zm-2.9574865-5.4348558c-.6236156.2794421-1.3149151.4348558-2.0425135.4348558-.7261289 0-1.4161054-.1547865-2.03873417-.4331642l-4.08197025 1.855441c-.53549142.2434052-.87929558.7773345-.87929558 1.3655497v.7121735c0 .8284271.67157288 1.5 1.5 1.5h5c.2761424 0 .5.2238576.5.5s-.2238576.5-.5.5h-5c-1.38071187 0-2.5-1.1192881-2.5-2.5v-.7121735c0-.9803587.57300693-1.8702408 1.46549264-2.2759162l3.45666559-1.5712116c-1.170067-.9151623-1.92215823-2.3401003-1.92215823-3.9406987 0-2.76142375 2.23857625-5 5-5 2.7614237 0 5 2.23857625 5 5 0 1.5929224-.7448949 3.0118652-1.9053532 3.927497l2.6019626 1.1127805c.2538977.1085844.3716974.4024342.2631131.6563319-.1085844.2538977-.4024342.3716974-.6563319.2631131zm-2.0425135-.5651442c2.209139 0 4-1.790861 4-4s-1.790861-4-4-4-4 1.790861-4 4 1.790861 4 4 4z" />
                        </svg>
                    </span>
                    <ReactMultiEmail
                        key={inputKey}
                        autoFocus={focused}
                        placeholder='Enter Email Address'
                        emails={emails}
                        onChange={(_emails) => {
                            setEmails(_emails);
                            setValue(name, _emails);
                            if (setToEmailError) {
                                setToEmailError("")
                            }
                            if (setCcEmailError) {
                                setCcEmailError("")
                            }
                            if (setBccEmailError) {
                                setBccEmailError("")
                            }
                            if (setIsClear) {
                                setIsClear(false);
                            }
                        }}
                        onChangeInput={(input) => {
                            setSearch(input)
                            switch (name) {
                                case "toEmail":
                                    if (!isValidEmail(search) && search.trim() !== '') {
                                        setToEmailError("Please enter a valid email address.");
                                    } else {
                                        setToEmailError(""); // Clear error if valid email
                                    }
                                    break;
                                case "CCEmail":
                                    if (!isValidEmail(search) && search.trim() !== '') {
                                        setCcEmailError("Please enter a valid email address.");
                                    } else {
                                        setCcEmailError(""); // Clear error if valid email
                                    }
                                    break;
                                case "BCCEmail":
                                    if (!isValidEmail(search) && search.trim() !== '') {
                                        setBccEmailError("Please enter a valid email address.");
                                    } else {
                                        setBccEmailError(""); // Clear error if valid email
                                    }
                                    break;
                                case "to":
                                    if (!isValidEmail(search) && search.trim() !== '') {
                                        setToEmailError("Please enter a valid email address.");
                                    } else {
                                        setToEmailError(""); // Clear error if valid email
                                    }
                                    break;
                                case "cc":
                                    if (!isValidEmail(search) && search.trim() !== '') {
                                        setCcEmailError("Please enter a valid email address.");
                                    } else {
                                        setCcEmailError(""); // Clear error if valid email
                                    }
                                    break;
                                case "bcc":
                                    if (!isValidEmail(search) && search.trim() !== '') {
                                        setBccEmailError("Please enter a valid email address.");
                                    } else {
                                        setBccEmailError(""); // Clear error if valid email
                                    }
                                    break;
                                default:
                                    break;
                            }
                        }}
                        getLabel={(email, index, removeEmail) => {
                            return (
                                <div data-tag key={index}>
                                    <div data-tag-item>{email}</div>
                                    <span data-tag-handle onClick={() => {
                                        removeEmail(index);
                                        setEmails(emails.filter((_, i) => i !== index)); // Remove email from the list
                                        setEmailSuggestionLists([]);
                                    }}>
                                        ×
                                    </span>
                                </div>
                            );
                        }}
                        autoComplete="chrome-off"
                        optionArea={
                            ({ emails, getTagProps }) => {
                                return (
                                    <div>
                                        {
                                            emails.map((item, index) => (
                                                <div {...getTagProps({ index })} key={index}>
                                                    <div>{item.name}</div>
                                                    <div>{item.email}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                );
                            }
                        }
                    />
                </div>
                {emailSuggestionLists?.length !== 0 && (
                    <div className="auto__complete">
                        <ul>
                            {emailSuggestionLists?.map((user, index) => (
                                <li
                                    key={user.id}
                                    onClick={() => {
                                        selectOption(user.email)
                                        setFocused(true)
                                    }}
                                    tabIndex={0}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            // Enter key pressed, select this suggestion
                                            selectOption(user.email);
                                            setFocused(true)
                                        } else if (e.keyCode === 40) {
                                            // Down arrow key pressed, highlight next suggestion
                                            const nextSuggestion = document.querySelector(`.auto__complete li:nth-child(${index + 2})`);
                                            if (nextSuggestion) {
                                                nextSuggestion.focus();
                                            }
                                        } else if (e.keyCode === 38) {
                                            // Up arrow key pressed, highlight previous suggestion
                                            const prevSuggestion = document.querySelector(`.auto__complete li:nth-child(${index})`);
                                            if (prevSuggestion) {
                                                prevSuggestion.focus();
                                            }
                                        }
                                    }}
                                >
                                    <div className="email-name">{user.name}</div>
                                    <div className="email">{user.email}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            {bccEmailError && <p className="text-danger text-sm pt-1">{bccEmailError}</p>}
            {ccEmailError && <p className="text-danger text-sm pt-1">{ccEmailError}</p>}
            {errors && <p className="text-danger text-sm pt-1">{errors}</p>}
        </>
    );
};

export default FormEmailChip;
