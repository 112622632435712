import { Button, Row, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import SearchFilter from "../../shared/components/table/SearchFilter";
import { useEffect, useState } from "react";
import ReactDataTable from "../../shared/components/table/ReactDataTable";
import { changeJobOwnerUserColumns } from "../../shared/constants/data-columns";
import { useScheduler } from "../../shared/hooks-context/scheduler-context-hook";
import { useForm } from "react-hook-form";

const ChangeJobOwner = (props) => {
    const { id } = props;
    const { register, setValue, handleSubmit, watch, reset, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [filterText, setFilterText] = useState("");
    const { userLists, getUserList, ChangeOwnerScheduler } = useScheduler();

    useEffect(() => {
        let getFetchData = async () => {
            await getUserList();
        };
        getFetchData();
    }, [])

    const onFilter = (value) => {
        setFilterText(value);
    };

    const filteredItems = userLists?.filter((item) => {
        return (item?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
            item?.username?.toLowerCase().includes(filterText.toLowerCase()))
    });

    const columns = changeJobOwnerUserColumns({ setValue, register, watch, errors });

    const onClearModal = () => {
        setFilterText("");
        props.onHide();
        reset();
    }

    const onChangeJobOwner = async (formData) => {
        const data = { ...formData, jobid: id }
        if (formData?.email) {
            const response = await ChangeOwnerScheduler(data);
            if (response?.success) {
                onClearModal();
            }
        }
    }

    return (
        <Modal
            {...props} onHide={onClearModal}
            dialogClassName="modal-830w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Change Ownership of Job(s)
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form autoComplete="off">
                    <div className="d-flex align-items-center">
                        <Form.Label className="block" >
                            Job Ids selected :
                        </Form.Label>
                        <strong className="mb-2 text-primary ms-5">{id}</strong>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <Form.Label className="block mb-0">
                            Change ownership to :
                        </Form.Label>
                        <Form className="d-flex align-items center">
                            <SearchFilter onFilter={onFilter} filterText={filterText} placeholder="Search User" className="ms-0" />
                        </Form>
                    </div>
                    <div className="custom-table job-owner-table mb-4">
                        <ReactDataTable columns={columns}
                            data={filteredItems}
                        />
                        {errors && errors["email"]?.type === "required" && (
                            <p className="text-danger text-sm pt-1">{errors["email"]?.message}</p>
                        )}
                    </div>
                    <div className="d-flex align-items-center">
                        <Button type="submit" variant="secondary me-3 px-3" onClick={handleSubmit(onChangeJobOwner)}>Change</Button>
                        <Button variant="secondary px-3" onClick={onClearModal} >Cancel</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal >
    )
}

export default ChangeJobOwner