import React, { useState } from "react";
import "../../assets/scss/switch.scss";

const Switch = ({ checked, onChange, ...props }) => {
  const [isChecked, setIsChecked] = useState(false);
  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  return (
    <label className="switch-label">
      <input
        type="checkbox"
        className="switch-input"
        onChange={onChange}
        checked={checked}
        {...props}
      />
      <span className="slider round"></span>
    </label>
  );
};

export default Switch;
