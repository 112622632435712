export const radioOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

export const reportFormat = [
  {
    label: "PDF",
    value: "PDF",
  },
  {
    label: "Excel",
    value: "xlsx",
  },
];

export const reportTemplate = [
  {
    label: "Template 1",
    value: "Template_1",
  },
  {
    label: "Template 2",
    value: "Template_2",
  },
  {
    label: "Template 3",
    value: "Template_3",
  },
  {
    label: "Template 4",
    value: "Template_4",
  },
];

export const reportDayLists = [
  { name: "Last 7 Days", value: 7 },
  { name: "Last 15 Days", value: 15 },
  { name: "Last 30 Days", value: 30 },
];

export const reportStatusLists = [
  { name: "Failed", value: "ERROR" },
  { name: "All", value: "ALL" },
];

export const reportData = [
  {
    status: "Success",
    id: 125,
    type: "Email, SFTP",
    size: "32MB",
  },
  {
    status: "Failed",
    id: 124,
    type: "Email, SFTP",
    size: "16MB",
  },
  {
    status: "Success",
    id: 115,
    type: "SFTP",
    size: "12MB",
  },
  {
    status: "Success",
    id: 118,
    type: "Email, SFTP",
    size: "18MB",
  },
  {
    status: "Success",
    id: 120,
    type: "Email",
    size: "6MB",
  },
  {
    status: "Failed",
    id: 116,
    type: "Email, SFTP",
    size: "18MB",
  },
  {
    status: "Success",
    id: 119,
    type: "Email, SFTP",
    size: "32MB",
  },
  {
    status: "Failed",
    id: 114,
    type: "Email, SFTP",
    size: "16MB",
  },
  {
    status: "Success",
    id: 111,
    type: "SFTP",
    size: "12MB",
  },
  {
    status: "Success",
    id: 115,
    type: "Email, SFTP",
    size: "18MB",
  },
  {
    status: "Success",
    id: 123,
    type: "Email",
    size: "6MB",
  },
  {
    status: "Failed",
    id: 122,
    type: "Email, SFTP",
    size: "18MB",
  },
  {
    status: "Failed",
    id: 116,
    type: "Email, SFTP",
    size: "16MB",
  },
  {
    status: "Success",
    id: 110,
    type: "SFTP",
    size: "12MB",
  },
];

export const reportsTabs = ({ jobSchedulerLists }) => {
  const sortByScheduleNextAsc = (list) => {
    return list.sort(
      (a, b) => new Date(a.nextSchedule) - new Date(b.nextSchedule)
    );
  };

  const now = new Date();

  const sortScheduledOnce = (list) => {
    const futureSchedules = list.filter(
      (job) => new Date(job.nextSchedule) >= now
    );
    const pastSchedules = list.filter(
      (job) => new Date(job.nextSchedule) < now
    );
    return [
      ...sortByScheduleNextAsc(futureSchedules),
      ...sortByScheduleNextAsc(pastSchedules),
    ];
  };

  return [
    {
      tabName: "All",
      records:
        jobSchedulerLists?.filter(
          (jobSchedulerList) => !jobSchedulerList?.deleted
        )?.length || 0,
      data:
        jobSchedulerLists?.filter(
          (jobSchedulerList) => !jobSchedulerList?.deleted
        ) || [],
    },
    // {
    //   tabName: "Scheduled Once",
    //   records:
    //     jobSchedulerLists?.filter(
    //       (jobSchedulerList) =>
    //         jobSchedulerList?.frequency === "ONCE" &&
    //         !jobSchedulerList?.deleted &&
    //         jobSchedulerList?.jobStatus !== 4
    //     )?.length || 0,
    //   data:
    //     jobSchedulerLists?.filter(
    //       (jobSchedulerList) =>
    //         jobSchedulerList?.frequency === "ONCE" &&
    //         !jobSchedulerList?.deleted &&
    //         jobSchedulerList?.jobStatus !== 4
    //     ) || [],
    // },
    {
      tabName: "Scheduled Once",
      records:
        jobSchedulerLists?.filter(
          (jobSchedulerList) =>
            jobSchedulerList?.frequency === "ONCE" &&
            !jobSchedulerList?.deleted &&
            jobSchedulerList?.jobStatus !== 4
        )?.length || 0,
      data: sortScheduledOnce(
        jobSchedulerLists?.filter(
          (jobSchedulerList) =>
            jobSchedulerList?.frequency === "ONCE" &&
            !jobSchedulerList?.deleted &&
            jobSchedulerList?.jobStatus !== 4
        ) || []
      ),
    },
    {
      tabName: "Recurring",
      records:
        jobSchedulerLists?.filter(
          (jobSchedulerList) =>
            jobSchedulerList?.frequency === "RECURRING" &&
            !jobSchedulerList?.deleted &&
            jobSchedulerList?.jobStatus !== 4
        )?.length || 0,
      data:
        jobSchedulerLists?.filter(
          (jobSchedulerList) =>
            jobSchedulerList?.frequency === "RECURRING" &&
            !jobSchedulerList?.deleted &&
            jobSchedulerList?.jobStatus !== 4
        ) || [],
    },
    {
      tabName: "Completed",
      records:
        jobSchedulerLists?.filter(
          (jobSchedulerList) =>
            jobSchedulerList?.schedulerStatus === "COMPLETED" &&
            jobSchedulerList?.jobStatus === 4 &&
            !jobSchedulerList?.deleted
        )?.length || 0,
      data:
        jobSchedulerLists?.filter(
          (jobSchedulerList) =>
            jobSchedulerList?.schedulerStatus === "COMPLETED" &&
            jobSchedulerList?.jobStatus === 4 &&
            !jobSchedulerList?.deleted
        ) || [],
    },
    {
      tabName: "Deleted",
      records:
        jobSchedulerLists?.filter(
          (jobSchedulerList) => jobSchedulerList?.deleted
        )?.length || 0,
      data:
        jobSchedulerLists?.filter(
          (jobSchedulerList) => jobSchedulerList?.deleted
        ) || [],
    },
  ];
};

export const distributionData = ({ sftpLists }) => {
  return [
    {
      label: "SFTP",
      value: "SFTP",
      data: sftpLists,
    },
    // {
    //     label: "AWS",
    //     value: "AWS",
    //     data: []
    // },
    // {
    //     label: "Google Cloud Storage",
    //     value: "Google Cloud Storage",
    //     total: "01"
    // },
    // {
    //     label: "Azure Blob",
    //     value: "Azure Blob",
    //     total: "01"
    // }
  ];
};

export const distributionType = [
  {
    label: "SFTP",
    value: "SFTP",
  },
  // {
  //     label: "AWS",
  //     value: "AWS",
  // },
  // {
  //     label: "Google Cloud Storage",
  //     value: "Google Cloud Storage",
  // },
  // {
  //     label: "Azure Blob",
  //     value: "Azure Blob",
  // }
];

export const frequencyOptions = [
  {
    label: "Once",
    value: "ONCE",
  },
  {
    label: "Recurring",
    value: "RECURRING",
  },
];

export const repeatModeLists = [
  {
    label: "Hourly",
    value: "Hourly",
  },
  {
    label: "Weekly",
    value: "Weekly",
  },
  {
    label: "Daily",
    value: "Daily",
  },
];

export const timeZoneLists = [
  {
    label: "(GMT -05:00) Eastern Time",
    value: "(GMT -05:00) Eastern Time",
  },
  {
    label: "(GMT -05:00) Eastern Time",
    value: "(GMT -05:00) Eastern Time",
  },
  {
    label: "(GMT -05:00) Eastern Time",
    value: "(GMT -05:00) Eastern Time",
  },
  {
    label: "(GMT -05:00) Eastern Time",
    value: "(GMT -05:00) Eastern Time",
  },
];

export const weekly = [
  {
    label: "S",
    value: "Sunday",
  },
  {
    label: "M",
    value: "Monday",
  },
  {
    label: "T",
    value: "Tuesday",
  },
  {
    label: "W",
    value: "Wednesday",
  },
  {
    label: "T",
    value: "Thursday",
  },
  {
    label: "F",
    value: "Friday",
  },
  {
    label: "S",
    value: "Saturday",
  },
];

export const distributionLocations = [
  {
    label: "Cloud",
    options: [
      { value: "Cloud Distribution 01", label: "Cloud Distribution 01" },
      { value: "Cloud Distribution 02", label: "Cloud Distribution 02" },
    ],
  },
  {
    label: "SFTP",
    options: [{ value: "SFTP Distribution 01", label: "SFTP Distribution 01" }],
  },
];
