import { useEffect } from "react";
import ReactDataTable from "../../shared/components/table/ReactDataTable";
import { searchReportDesignerColumns, searchReportsColumns } from "../../shared/constants/data-columns";
import { useDashboard } from "../../shared/hooks-context/dashboard-context-hook";
import { useLocation } from "react-router-dom";
import Title from "../../shared/components/Title";
import { getSearchQuery } from "../../shared/shared-method";

const reportDesignerColumns = searchReportDesignerColumns();
const reportsColumns = searchReportsColumns();

const GlobalSearch = () => {
    const location = useLocation();
    const { searchLists, setSearchLists, getGlobalSearch } = useDashboard();
    const searchQuery = getSearchQuery(location.search);

    useEffect(() => {
        if (searchQuery) {
            getGlobalSearch({ searchQuery });
        } else {
            setSearchLists([]);
        }
    }, [searchQuery, setSearchLists, getGlobalSearch]);

    return (
        <div className="global-search">
            <Title title={`Search Results (${(searchLists?.scheduler?.length + searchLists?.reports?.length) || 0})`} />
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <ReactDataTable
                            title={`Jobs (${searchLists?.scheduler?.length || 0})`}
                            columns={reportsColumns}
                            data={searchLists?.scheduler}
                        />
                    </div>
                    <div className="card">
                        <ReactDataTable
                            title={`Templates (${searchLists?.reports?.length || 0})`}
                            columns={reportDesignerColumns}
                            data={searchLists?.reports}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GlobalSearch;
