import PropTypes from 'prop-types';
import React, { createContext, useContext, useCallback, useState } from "react";
import { executeAssessmentAPI } from "./base-context";
import { lookupDatabaseAPI } from "../api/apis";
import { useNotification } from './notification-context-hook';
import { notificationType } from '../constants/constants';

export const LookupDatabaseContext = createContext();

export const LookupDatabaseProvider = ({ children }) => {
  const [isLookupDatabaseLoading, setIsLookupDatabaseLoading] = useState({ isList: false, isLoading: false });
  const [lookDatabaseLists, setLookDatabaseLists] = useState([]);
  const [searchEmailLists, setSearchEmailLists] = useState([]);
  const { addMessage } = useNotification();

  const getLookupDatabase = useCallback(async (request) => {
    setIsLookupDatabaseLoading({ isList: true, isLoading: false });
    try {
      const result = await executeAssessmentAPI(lookupDatabaseAPI.getLookupDatabase, request);
      if (result?.success) {
        setIsLookupDatabaseLoading({ isList: false, isLoading: false });
        setLookDatabaseLists(result?.data);
      } else {
        setIsLookupDatabaseLoading({ isList: false, isLoading: false });
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      setIsLookupDatabaseLoading({ isList: false, isLoading: false });
      console.error('Error fetching lookup database list:', error);
    }
  }, []);

  const getSearchMailLookupDatabase = useCallback(async (request) => {
    setIsLookupDatabaseLoading({ isList: true, isLoading: false });
    try {
      const result = await executeAssessmentAPI(lookupDatabaseAPI.getSearchMailLookupDatabase, request);
      if (result?.success) {
        setSearchEmailLists(result?.data);
      } else {
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.error('Error fetching lookup database list:', error);
    }
  }, []);

  const addLookupDatabase = useCallback(async (request) => {
    setIsLookupDatabaseLoading({ isList: false, isLoading: true });
    try {
      const result = await executeAssessmentAPI(lookupDatabaseAPI.addLookupDatabase, request);
      if (result?.success) {
        setIsLookupDatabaseLoading({ isList: false, isLoading: false });
        getLookupDatabase();
        return result;
      } else {
        setIsLookupDatabaseLoading({ isList: false, isLoading: false });
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      setIsLookupDatabaseLoading({ isList: false, isLoading: false });
      console.error('Error fetching lookup database create:', error);
    }
  }, [getLookupDatabase]);

  const deleteLookupDatabase = useCallback(async (request) => {
    try {
      const result = await executeAssessmentAPI(lookupDatabaseAPI.deleteLookupDatabase, request);
      if (result?.success) {
        getLookupDatabase();
        return result;
      } else {
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.error('Error fetching lookup database create:', error);
    }
  }, [getLookupDatabase]);

  const contextValue = {
    isLookupDatabaseLoading,
    searchEmailLists,
    lookDatabaseLists,
    getLookupDatabase,
    addLookupDatabase,
    deleteLookupDatabase,
    getSearchMailLookupDatabase
  };

  return (
    <LookupDatabaseContext.Provider value={contextValue}>
      {children}
    </LookupDatabaseContext.Provider>
  )
};

LookupDatabaseProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useLookupDatabase = () => useContext(LookupDatabaseContext);
