import moment from "moment";
import 'moment-timezone';
import { tokens } from "./constants/constants"

export const clearFormDta = (reset) => {
    return reset("", {
        keepValues: false,
    })
}

export const isDisable = (email) => {
    return email !== localStorage.getItem(tokens.EMAIL) ? true : false
}

export const convertBooleanValue = (booleanFields, watch) => {
    return booleanFields?.reduce((acc, field) => {
        acc[field] = watch(field) === "Yes";
        return acc;
    }, {});
};

export const getCurrentDateTime = (date) => {
    const currentDateTime = new Date(date);

    const year = currentDateTime.getFullYear();
    const month = (currentDateTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const day = currentDateTime.getDate().toString().padStart(2, '0');
    const hours = currentDateTime.getHours().toString().padStart(2, '0');
    const minutes = currentDateTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentDateTime.getSeconds().toString().padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;

    return formattedDateTime;
}

export const getSearchQuery = (search) => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get('search');
}

export const getDataTime = (data) => {
    let date2 = new Date(data).setUTCHours(new Date(data).getHours(), new Date(data).getMinutes(), new Date(data).getSeconds(), 0)

    let hour = new Date(date2).getHours()
    let hourM = new Date(date2).getMinutes()
    let hourS = new Date(date2).getSeconds()


    let date = moment(data).format("YYYY-MM-DD");
    let finalDate = `${date} ${hour}:${hourM}:${hourS}`

    return finalDate
}

export const convertFileSize = (bytes) => {
    const MB = bytes / (1024 * 1024);
    if (MB >= 1024) {
        const GB = MB / 1024;
        return <>{GB.toFixed(2)} <span className="fs-6">GB</span></>;
    }
    return <>{MB.toFixed(2)} <span className="fs-6">MB</span></>;
}

export const convertFileSizeNoType = (bytes) => {
    const MB = bytes / (1024 * 1024);
    if (MB >= 1024) {
        const GB = MB / 1024;
        return `${GB.toFixed(2)}`;
    }
    return `${MB.toFixed(2)}`;
}


export const getCurrentTimezoneOffset = () => {
    const now = moment();
    const offsetInHours = now.utcOffset() / 60;
    return offsetInHours;
}

export const getCurrentTimezoneUTC = () => {
    const options = Intl.DateTimeFormat().resolvedOptions();
    const timezoneUTC = options.timeZone;
    return timezoneUTC;
}


export const setSelectedTimezoneByOffset = (offset, abbbr, timezones) => {
    return timezones?.find(zone => zone?.utc?.includes(abbbr));
}

export const FirstLetterCapitalized = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}