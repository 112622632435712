import axios from "axios";
import { tokens } from "../constants/constants";

export const getApi = async (url) => {
    return axios.get(url, await getRequestConfiguration());
};

export const postApi = async (url, data) => {
    return axios.post(url, data, await getRequestConfiguration());
};
export const patchApi = async (url, data) => {
    return axios.patch(url, data, await getRequestConfiguration());
};

export const postMultiPartFormApi = async (url, data) => {
    return axios.post(url, data, await getMultipartFormRequestConfiguration());
};

export const postFormApi = async (url, data) => {
    return axios.post(url, data, await getFormRequestConfiguration());
};

export const putApi = async (url, data) => {
    return axios.put(url, data, await getRequestConfiguration());
};

export const deleteApi = async (url, data = {}) => {
    return axios.delete(url, { data: data, ...await getRequestConfiguration() });
};


const getRequestConfiguration = async () => {
    const requestConfig = {};
    requestConfig.headers = await getHeaders();
    return requestConfig;
};

const getFormRequestConfiguration = async () => {
    const requestConfig = {};
    requestConfig.headers = await getFormDataHeaders();
    return requestConfig;
};

const getMultipartFormRequestConfiguration = async () => {
    const requestConfig = {};
    requestConfig.headers = await getMultipartFormDataHeaders();
    return requestConfig;
};

const getFormDataHeaders = async () => {
    const headers = {};
    headers["Content-Type"] = "application/form-data";
    const token = await getToken();
    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }
    return headers;
};

const getMultipartFormDataHeaders = async () => {
    const headers = {};
    headers["Content-Type"] = "multipart/form-data";
    const token = await getToken();
    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }
    return headers;
};

const getHeaders = async () => {
    const headers = {};
    headers["Content-Type"] = "application/json";
    const token = await getToken();
    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }
    return headers;
};

const getToken = async () => {
    const token = localStorage.getItem(tokens.AUTH_TOKEN);
    return token;
};
