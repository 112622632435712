import PropTypes from "prop-types";
import { Button, Form, InputGroup } from "react-bootstrap";
import eyeIcon from "../../../assets/images/password-view.svg";
import eyeHiddenIcon from "../../../assets/images/password-hidden.svg";
import { useEffect, useState } from "react";

const FormText = (props) => {
    const { name, label, register, errors, required, type, validationSchema, disable, isTextarea, inlineForm, placeholder, inputGroup, isHidePwd, setIsHidePwd, widthClass, maxLength } = props;
    const [passwordVisible, setPasswordVisible] = useState(false);

    const handleClickShowPassword = () => {
        if (setIsHidePwd) {
            setIsHidePwd(false);
        }
        setPasswordVisible((prev) => !prev);
    };

    useEffect(() => {
        if (isHidePwd) {
            setPasswordVisible(false);
        }
    }, [isHidePwd, passwordVisible])

    return (
        <div className={`${widthClass ? "w-auto" : " w-100"} mb-4`} >
            <Form.Group className={`${inlineForm ? "d-flex align-items-center" : ""}`}>
                {label &&
                    <Form.Label className={`${inlineForm ? "inline-form__label" : "block"}`} htmlFor={name} >
                        <span>{label}</span> {required && <span className="text-danger">*</span>} {inlineForm && <span>:</span>}
                    </Form.Label>
                }
                <div className={`${inlineForm ? "inline-form__input w-100" : ""} ${widthClass}`}>
                    {isTextarea ? (<textarea
                        className={`form-control custom-form-control2 ${isTextarea && "h-32"}`}
                        name={name}
                        {...register(name, validationSchema)}
                    />) : type === "password" ?
                        (<InputGroup>
                            <Form.Control
                                name={name}
                                type={passwordVisible ? "text" : "password"}
                                placeholder={placeholder}
                                {...register(name, validationSchema)}
                                disabled={disable}
                                autoComplete="new-password"
                            />
                            <Button variant="outline-secondary" className="inline-form__password-view" onClick={handleClickShowPassword}>
                                <img src={passwordVisible ? eyeIcon : eyeHiddenIcon} alt="Password View" />
                            </Button>
                        </InputGroup>)
                        : type === "number" ? (<Form.Control
                            name={name}
                            type={type}
                            placeholder={placeholder}
                            {...register(name, validationSchema)}
                            disabled={disable} min="0"
                        />) : inputGroup ?
                            (<InputGroup>
                                <InputGroup.Text className="border-0">
                                    <span>
                                        <svg fill="#ffffff" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m17 19v-2.5c0-.2761424.2238576-.5.5-.5s.5.2238576.5.5v2.5h2.5c.2761424 0 .5.2238576.5.5s-.2238576.5-.5.5h-2.5v2.5c0 .2761424-.2238576.5-.5.5s-.5-.2238576-.5-.5v-2.5h-2.5c-.2761424 0-.5-.2238576-.5-.5s.2238576-.5.5-.5zm-2.9574865-5.4348558c-.6236156.2794421-1.3149151.4348558-2.0425135.4348558-.7261289 0-1.4161054-.1547865-2.03873417-.4331642l-4.08197025 1.855441c-.53549142.2434052-.87929558.7773345-.87929558 1.3655497v.7121735c0 .8284271.67157288 1.5 1.5 1.5h5c.2761424 0 .5.2238576.5.5s-.2238576.5-.5.5h-5c-1.38071187 0-2.5-1.1192881-2.5-2.5v-.7121735c0-.9803587.57300693-1.8702408 1.46549264-2.2759162l3.45666559-1.5712116c-1.170067-.9151623-1.92215823-2.3401003-1.92215823-3.9406987 0-2.76142375 2.23857625-5 5-5 2.7614237 0 5 2.23857625 5 5 0 1.5929224-.7448949 3.0118652-1.9053532 3.927497l2.6019626 1.1127805c.2538977.1085844.3716974.4024342.2631131.6563319-.1085844.2538977-.4024342.3716974-.6563319.2631131zm-2.0425135-.5651442c2.209139 0 4-1.790861 4-4s-1.790861-4-4-4-4 1.790861-4 4 1.790861 4 4 4z" />
                                        </svg>
                                    </span>
                                </InputGroup.Text>
                                <Form.Control
                                    name={name}
                                    type={type}
                                    placeholder={placeholder}
                                    {...register(name, validationSchema)}
                                    disabled={disable}
                                    style={{ borderColor: "#dee2e6 !important" }}
                                />
                            </InputGroup>)
                            : (<Form.Control
                                name={name}
                                type={type}
                                maxLength={maxLength}
                                placeholder={placeholder}
                                {...register(name, validationSchema)}
                                disabled={disable}
                                autoComplete="off"
                            />)
                    }
                </div>
            </Form.Group >
            {
                errors && (errors[name]?.type === "required" || errors[name]?.type === "validate") && (
                    <p className={`${inlineForm && "inline-form__error"} text-danger text-sm pt-1`}>{errors[name]?.message}</p>
                )
            }
        </div >
    )
}

FormText.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    register: PropTypes.any.isRequired,
    errors: PropTypes.object,
    required: PropTypes.bool,
    type: PropTypes.string.isRequired,
    validationSchema: PropTypes.object,
    disable: PropTypes.bool,
    isTextarea: PropTypes.bool,
    inlineForm: PropTypes.bool,
    inputGroup: PropTypes.bool,
    isHidePwd: PropTypes.bool,
    setIsHidePwd: PropTypes.func,
    placeholder: PropTypes.string,
    styleWidth: PropTypes.string,
};

export default FormText;