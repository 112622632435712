import PropTypes from 'prop-types';
import React, { createContext, useContext, useCallback, useState } from "react";
import { executeAssessmentAPI } from "./base-context";
import { settingsAPI } from "../api/apis";
import { notificationType } from '../constants/constants';
import { useNotification } from './notification-context-hook';

export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const { addMessage } = useNotification();
  const [isLoading, setIsLoading] = useState({ isProfile: false, isUpdate: false });
  const [userProfile, setUserProfile] = useState(null);

  const getUserProfile = useCallback(async (request) => {
    setIsLoading({ isProfile: true, isUpdate: false });
    try {
      const result = await executeAssessmentAPI(settingsAPI.getUserProfile, request);
      if (result?.success) {
        setIsLoading({ isProfile: false, isUpdate: false });
        setUserProfile(result?.data);
      } else {
        setIsLoading({ isProfile: false, isUpdate: false });
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.log('error', error)
      console.error('Error fetching profile:', error);
    }
  }, []);

  const updateUserProfile = useCallback(async (request) => {
    setIsLoading({ isProfile: false, isUpdate: true });
    try {
      const result = await executeAssessmentAPI(settingsAPI.updateUserProfile, request);
      if (result?.success) {
        setIsLoading({ isProfile: false, isUpdate: false });
        getUserProfile();
        return result;
      } else {
        setIsLoading({ isProfile: false, isUpdate: false });
        addMessage({ message: result?.message, type: notificationType.ERROR })
      }
    } catch (error) {
      console.error('Error fetching profile update:', error);
    }
  }, []);

  const contextValue = {
    isLoading,
    userProfile,
    getUserProfile,
    updateUserProfile
  };

  return (
    <SettingsContext.Provider value={contextValue}>
      {children}
    </SettingsContext.Provider>
  )
};

SettingsProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useSettings = () => useContext(SettingsContext);
