import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FormText from "../../shared/components/form/FormText";
import { useForm } from "react-hook-form";
import { validEmail } from "../../shared/utils/validation";
import { useLookupDatabase } from "../../shared/hooks-context/lookup-database-context-hook";
import { Spinner } from "react-bootstrap";

const AddLookupDatabaseModal = (props) => {
    const {
        register,
        reset,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = useForm({ mode: "onChange" });
    const { isLookupDatabaseLoading, addLookupDatabase } = useLookupDatabase();

    const onClearModal = () => {
        props.onHide();
        reset();
    }

    const onAddLookupDatabase = async (formData) => {
        const response = await addLookupDatabase(formData);
        if (response?.success) {
            onClearModal();
        }
    }

    const onUploadCSVFile = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.files) {
            const file = e.target.files[0];
            if (file) {
                const formData = {
                    File: file,
                    IsBulk: true
                }
                await onAddLookupDatabase(formData)
            }
        }
    }

    return (
        <Modal
            show={props.show} onHide={onClearModal}
            dialogClassName="modal-830w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add User
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <FormText
                        type="text"
                        name="name"
                        label="Username"
                        placeholder="Enter Username"
                        maxLength={50}
                        register={register}
                        validationSchema={{
                            required: "Username is required",
                        }}
                        errors={errors} />
                    <FormText
                        type="email"
                        name="email"
                        label="Email Address"
                        placeholder="Enter Email Address"
                        maxLength={255}
                        register={register}
                        validationSchema={{
                            required: "Email address is required",
                            validate: (value) => validEmail(value),
                        }}
                        errors={errors} />
                    <div className="d-flex align-items-enter mt-4">
                        <Button variant="secondary px-4 d-block me-4" disabled={(!isDirty || !isValid || isLookupDatabaseLoading?.isLoading)} onClick={handleSubmit(onAddLookupDatabase)}>{isLookupDatabaseLoading?.isLoading && <Spinner
                            className="me-2"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />}Add User</Button>
                        <div className="btn btn-secondary csv-upload-btn">
                            <div class="upload-file-btn"><input type="file" name="File" onChange={(e) => onUploadCSVFile(e)} accept=".csv" /></div>
                            Import Users
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AddLookupDatabaseModal;