import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../assets/scss/login.scss";
import Form from "react-bootstrap/Form";
import pixelPerfectLogo from "../../assets/images/PPRCloud.svg";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { useAuth } from "../../shared/hooks-context/auth-context-hook";
import { pageRoutes } from "../../shared/constants/constants";
import FormText from "../../shared/components/form/FormText";
import { Spinner } from "react-bootstrap";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });
  const { isLoading, login } = useAuth();

  const onHandleLogin = async (formData) => {
    await login(formData);
  };

  const checkKeyDown = (e) => {
    if (e.key === "Enter" && e.keyCode === 13) {
      e.preventDefault();
      handleSubmit(onHandleLogin)();
    }
  };

  return (
    <Container fluid className="h-100">
      <Row className="h-100">
        <Col xs={5} className="d-none d-lg-block">
          <div className="bg-image" />
        </Col>
        <Col xs={12} lg={7} className="shape-image">
          <div className="d-flex w-100 h-100 justify-content-center align-items-center position-relative">
            <div className="w-100 login-form py-5">
              <div className="compony-logo">
                <img src={pixelPerfectLogo} alt="Pixel Perfect" />
              </div>
              <h2 className="title ps-3">Login</h2>
              <Form onKeyDown={(e) => checkKeyDown(e)} autoComplete="off">
                <FormText
                  type="email"
                  name="username"
                  label="Email"
                  placeholder="Enter Email"
                  register={register}
                  validationSchema={{
                    required: "Email is required.",
                  }}
                  errors={errors}
                />
                <FormText
                  type="password"
                  name="password"
                  label="Password"
                  placeholder="Enter Password"
                  register={register}
                  validationSchema={{
                    required: "Password is required.",
                  }}
                  errors={errors}
                />
                <Link
                  to={pageRoutes.FORGOT_PASSWORD}
                  className="text-decoration-none fst-italic fs-5 forgot-link"
                >
                  Forgot Password?
                </Link>
                <div className="mt-5 pt-3">
                  <Button
                    variant="secondary"
                    className="rounded-0 py-2 px-4"
                    disabled={!isDirty || !isValid || isLoading}
                    onClick={handleSubmit(onHandleLogin)}
                  >
                    {isLoading && (
                      <Spinner
                        className="me-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}{" "}
                    LOG IN
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
