import { Button, Col, Container, Row } from "react-bootstrap";
import passwordChangedLogo from "../../assets/images/password-changed.svg";
import { pageRoutes, tokens } from "../../shared/constants/constants";
import { useNavigate } from "react-router-dom";
import "../../assets/scss/auth.scss";

const PasswordSuccessfully = () => {
    const navigate = useNavigate();
    return (
        <Container fluid className="h-100">
            <Row className="h-100">
                <Col xs={5} className="d-none d-lg-block">
                    <div className="bg-image" />
                </Col>
                <Col xs={12} lg={7} className="shape-image">
                    <div className="d-flex w-100 h-100 justify-content-center align-items-center position-relative">
                        <div className="w-100 password-successfully py-5">
                            <div className="password-successfully__compony-logo">
                                <img src={passwordChangedLogo} alt="password Changed" />
                            </div>
                            <h2 className="password-successfully__title mb-4">Password Change Request Received!</h2>
                            <p className="password-successfully__message mb-5">Please check your email and complete the steps to change the password.</p>
                            <Button variant="secondary" className="rounded-0 py-2 px-4" onClick={() => navigate(pageRoutes.LOGIN)}>LOGIN</Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default PasswordSuccessfully;