import React, { useState } from "react";
import { CustomDropDown, SelectCustomDropDown } from "./Controls";
import { Card } from "react-bootstrap";
import { UPDATE_META_INPUTS } from ".";

const MetaDataInputs = ({
  dispatch,
  roles = [],
  databases = [],
  schemas = [],
  tables = [],
  MetaInfo,
  ...props
}) => {
  return (
    <>
      <Card>
        <SelectCustomDropDown
          name="Role"
          className="mb-2"
          placeholder="Select Role"
          label="Role"
          options={roles?.map((x) => {
            return {
              value: x,
              label: x,
              name: "Role",
            };
          })}
          value={{ label: MetaInfo?.Role, value: MetaInfo?.Role }}
          {...props}
        />
        <SelectCustomDropDown
          name="Database"
          className="mb-2"
          placeholder="Select Database"
          label="Database"
          options={databases?.map((x) => {
            return {
              value: x,
              label: x,
              name: "Database",
            };
          })}
          value={{ label: MetaInfo?.Database, value: MetaInfo?.Database }}
          {...props}
        />
        <SelectCustomDropDown
          name="Schema"
          className="mb-2"
          placeholder="Select Schema"
          label="Schema"
          options={schemas?.map((x) => {
            return {
              value: x,
              label: x,
              name: "Schema",
            };
          })}
          value={{ label: MetaInfo?.Schema, value: MetaInfo?.Schema }}
          {...props}
        />

        <SelectCustomDropDown
          isMulti={true}
          name="Tables"
          id="tables"
          className="mb-2"
          placeholder="Select Table"
          label="Tables"
          options={tables?.map((x) => {
            return {
              value: x,
              label: x,
              name: "Tables",
            };
          })}
          value={MetaInfo?.Tables}
          onCustomChange={(e) => {
            dispatch({ type: UPDATE_META_INPUTS, field: "Tables", value: e });
          }}
          {...props}
        />
      </Card>
    </>
  );
};

export default MetaDataInputs;
