import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { Form } from 'react-bootstrap';
import Select, { components } from "react-select";

const InputOption = ({ getStyles, Icon, isDisabled, isFocused, isSelected, children, innerProps, ...rest }) => {
    const [isActive, setIsActive] = useState(false);

    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex"
    };

    // prop assignment
    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style
    };

    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            <input type="checkbox" checked={isSelected} disabled={isDisabled} /> {/* Disable checkbox based on isDisabled prop */}
            <span className="ms-1">{children}</span>
        </components.Option>
    );
};

InputOption.propTypes = {
    getStyles: PropTypes.any,
    Icon: PropTypes.any,
    isFocused: PropTypes.any,
    isDisabled: PropTypes.any,
    children: PropTypes.any,
    innerProps: PropTypes.any,
};

const formatGroupLabel = (data) => {
    return (
        <div>
            <span>{data.label}</span>
        </div>
    );
};

const FormReactSelect = ({ options, label, name, placeholder, inlineForm, required, setValue, register, setError, defaultValues, isClear, setIsClear }) => {
    const [selectValue, setSelectValue] = useState([]);

    useEffect(() => {
        if (defaultValues) {
            if (Object.keys(defaultValues)?.length > 0) {
                setSelectValue(options[0]?.options?.filter(item => defaultValues.sftp?.includes(item.value)) || []);
            }
        }
    }, [defaultValues]);

    useEffect(() => {
        if (isClear) {
            setSelectValue([]);
            setValue(name, {
                Distribution: {
                    sftp: [],
                    aws: []
                }
            });
        }
    }, [isClear])

    const handleDistributionChange = (selectedOptions) => {
        setIsClear(false);
        setError("");
        const distribution = {
            sftp: [],
            aws: []
        };

        selectedOptions.forEach(option => {
            if (option.subValue && distribution[option.subValue]) {
                distribution[option.subValue].push(option.value);
            }
        });
        setSelectValue(selectedOptions);
        setValue(name, distribution);
    };

    return (
        <div className='mb-4'>
            <Form.Group className={`${inlineForm && "d-flex align-items-center"}`}>
                {label &&
                    <Form.Label className={`${inlineForm ? "inline-form__label" : "block"}`} htmlFor={name} >
                        <span>{label}</span> {required && <span className="text-danger">*</span>} {inlineForm && <span>:</span>}
                    </Form.Label>
                }
                <div className={`${inlineForm ? "inline-form__input w-100" : ""}`}>
                    <Select
                        name={name}
                        {...register(name)}
                        placeholder={placeholder}
                        value={selectValue}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        onChange={handleDistributionChange}
                        options={options}
                        components={{ Option: InputOption }}
                        formatOptionLabel={formatGroupLabel}
                        styles={{
                            indicatorContainer: base => ({
                                ...base,
                                background: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEySURBVEiJtZI9SsRAGIaffBELr7B32Mo6KdSrCBYKFlZBmSKtupJjaKmgYJEDeAAhh5iwyEKEZGySJYH8mUzean6fl+EZWDiOUuoQOAaOLLN3wNdBURSvwJlleJUPAdYLwQHWYozZLFjwJK7rPgDfC8ATrfVGlFK/InJpmy4iV1EUZQKglPo0xrxY5D8rpd4BpFpxXfca+LEA3+V5frPnVoM4jree5xnHcU5nFtyGYfhWTaS+Y0F4orVu/MpGwVzhldjOgrJkqvC92N4CmCS8IbbBaluM43jr+z7AyRi6MeauLrae1hcAiMg944QnaZo+dnK6NsYKbxM7qqAsGRLeKnZ0AfQK7xTbuD90oEt4n9h6Bl8ArcJ7xf67oBR+AWRAJiLnfWInJwiCVRAEK+vgOfkDdJeIUbiW2w4AAAAASUVORK5CYII=) center no-repeat 8px 50%;",
                                backgroundSize: "13px",
                                borderRadius: "4px",
                                height: "40px",
                                display: "block",
                                width: "100%",
                            })
                        }}
                    />
                </div>
            </Form.Group>
        </div>
    );
};

FormReactSelect.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.any.isRequired
        })
    ),
    placeholder: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    isClear: PropTypes.bool,
    inlineForm: PropTypes.bool,
    setValue: PropTypes.func,
};

export default FormReactSelect;
