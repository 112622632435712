import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

const FormDateTimePicker = (props) => {
    const { label, inlineForm, required, register, errors, setValue, name, isClear, setIsClear, defaultValues, setError } = props;
    const [value, onChange] = useState();

    useEffect(() => {
        if (isClear) {
            onChange(new Date());
            setValue(name, new Date());
        }
    }, [isClear])

    useEffect(() => {
        if (defaultValues) {
            onChange(defaultValues)
            setValue(name, defaultValues, { shouldDirty: true });
        } else {
            onChange(new Date())
            setValue(name, new Date(), { shouldDirty: true });
        }
    }, [defaultValues])

    useEffect(() => {
        if (value) {
            onChange(value)
            setValue(name, value, { shouldDirty: true });
        }
    }, [value])

    return (
        <div className="mb-4">
            <div className="d-flex align-items-center">
                <label className={`${inlineForm ? "inline-form__label" : "block"} form-label`} >
                    <span>{label}</span> {required && <span className="text-danger">*</span>} {inlineForm && <span>:</span>}
                </label>
                <DateTimePicker
                    name={name}
                    {...register(name)}
                    onChange={(value) => {
                        setValue(name, value, { shouldDirty: true });
                        onChange(value);
                        if (setError) {
                            setError("");
                        }
                        if (setIsClear) {
                            setIsClear(false);
                        }
                    }}
                    value={value}
                />
            </div>
            {
                errors && (
                    <p className={`${inlineForm && "inline-form__error"} text-danger text-sm pt-1`}>{errors}</p>
                )
            }
        </div>
    )
}

FormDateTimePicker.propTypes = {
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    inlineForm: PropTypes.bool,
    setValue: PropTypes.func,
    defaultValues: PropTypes.string,
};

export default FormDateTimePicker