import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import pixelPerfectLogo from "../../assets/images/pixel-perfect-logo.svg";
import FormText from "../../shared/components/form/FormText";
import { useAuth } from "../../shared/hooks-context/auth-context-hook";

const ChangePassword = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onChange" });
    const { isLoading, changePassword } = useAuth();

    const onHandleResetPassword = async (formData) => {
        await changePassword(formData);
    }

    const checkKeyDown = (e) => {
        if (e.key === 'Enter' && e.keyCode === 13) {
            e.preventDefault();
            handleSubmit(onHandleResetPassword)();
        }
    };

    return (
        <Container fluid className="h-100">
            <Row className="h-100">
                <Col xs={5} className="d-none d-lg-block">
                    <div className="bg-image" />
                </Col>
                <Col xs={12} lg={7} className="shape-image">
                    <div className="d-flex w-100 h-100 justify-content-center align-items-center position-relative">
                        <div className="w-100 login-form py-5">
                            <div className="compony-logo">
                                <img src={pixelPerfectLogo} alt="Pixel Perfect" />
                            </div>
                            <h2 className="title ps-3">Change Password</h2>
                            <Form onKeyDown={(e) => checkKeyDown(e)}>
                                <FormText
                                    type="password"
                                    name="currentpassword"
                                    label="Current Password"
                                    placeholder="Enter Current Password"
                                    register={register}
                                    validationSchema={{
                                        required: "Current password is required.",
                                    }}
                                    errors={errors}
                                />
                                <FormText
                                    type="password"
                                    name="changepassword"
                                    label="Change Password"
                                    placeholder="Enter Change Password"
                                    register={register}
                                    validationSchema={{
                                        required: "Change password is required.",
                                    }}
                                    errors={errors}
                                />
                                <div className="mt-5 pt-3">
                                    <Button variant="secondary" className="rounded-0 py-2 px-4 me-3" disabled={isLoading} onClick={handleSubmit(onHandleResetPassword)}>
                                        {isLoading && <Spinner
                                            className="me-2"
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />}
                                        Reset Password</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default ChangePassword;