import Cockpit from "./components/cockpit/Cockpit";
import DxReport from "./components/DxReport";
import ReportViewer from "./components/DxReport/ReportViewer";
import GlobalSearch from "./components/cockpit/GlobalSearch";
import Configure from "./components/configure/Configure";
import ReportDesigner from "./components/report-designer/ReportDesigner";
import Reports from "./components/reports/Reports";
import Scheduler from "./components/scheduler/Scheduler";
import Setting from "./components/settings/Settings";
import UserManagement from "./components/user-management/UserManagement";
import { pageRoutes } from "./shared/constants/constants";

export const routes = [
  {
    path: pageRoutes.COCKPIT,
    component: Cockpit,
    id: "cockpit",
  },
  {
    path: pageRoutes.SEARCH,
    component: GlobalSearch,
    id: "search",
  },
  {
    path: pageRoutes.REPORTS,
    component: Reports,
    id: "reports",
  },
  {
    path: pageRoutes.SCHEDULER,
    component: Scheduler,
    id: "scheduler",
  },
  {
    path: pageRoutes.REPORT_DESIGNER,
    component: ReportDesigner,
    id: "report-designer",
  },
  {
    path: `${pageRoutes.REPORT_DESIGNER}/create`,
    component: DxReport,
    id: "report-designer-create",
  },
  {
    path: `${pageRoutes.REPORT_DESIGNER}/edit/:reportId`,
    component: DxReport,
    id: "report-designer-edit",
  },
  {
    path: `${pageRoutes.REPORT_DESIGNER}/view/:reportId`,
    component: ReportViewer,
    id: "report-designer-view",
  },
  {
    path: pageRoutes.SETTINGS,
    component: Setting,
    id: "settings",
  },
  {
    path: pageRoutes.CONFIGURE,
    component: Configure,
    id: "configure",
  },
  {
    path: pageRoutes.USER_MANAGEMENT,
    component: UserManagement,
    id: "user-management",
  },
];
