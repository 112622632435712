import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import pixelPerfectLogo from "../../assets/images/pixel-perfect-logo.svg";
import FormText from "../../shared/components/form/FormText";
import { useAuth } from "../../shared/hooks-context/auth-context-hook";
import { useNavigate } from "react-router-dom";
import { pageRoutes } from "../../shared/constants/constants";
import { validEmail } from "../../shared/utils/validation";

const ForgotPassword = () => {
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = useForm({ mode: "onChange" });
    const { isLoading, forgotPassword } = useAuth();
    const navigate = useNavigate();

    const onHandleForgotPassword = async (formData) => {
        await forgotPassword(formData)
    }

    const checkKeyDown = (e) => {
        if (e.key === 'Enter' && e.keyCode === 13) {
            e.preventDefault();
            handleSubmit(onHandleForgotPassword)();
        }
    };

    return (
        <Container fluid className="h-100">
            <Row className="h-100">
                <Col xs={5} className="d-none d-lg-block">
                    <div className="bg-image" />
                </Col>
                <Col xs={12} lg={7} className="shape-image">
                    <div className="d-flex w-100 h-100 justify-content-center align-items-center position-relative">
                        <div className="w-100 login-form py-5">
                            <div className="compony-logo">
                                <img src={pixelPerfectLogo} alt="Pixel Perfect" />
                            </div>
                            <h2 className="title ps-3 mb-4">Forgot Password</h2>
                            <p className="mb-5">Password Reset link will be shared with your registered Email id</p>
                            <Form onKeyDown={(e) => checkKeyDown(e)}>
                                <FormText
                                    type="email"
                                    name="email"
                                    label="Enter Registered Email"
                                    placeholder="Enter Email here"
                                    register={register}
                                    validationSchema={{
                                        required: "Registered email is required.",
                                        validate: (value) => validEmail(value),
                                    }}
                                    errors={errors}
                                />
                                <div className="mt-5 pt-3">
                                    <Button variant="secondary" className="rounded-0 py-2 px-4 me-3" disabled={!isDirty || !isValid || isLoading} onClick={handleSubmit(onHandleForgotPassword)}>
                                        {isLoading && <Spinner
                                            className="me-2"
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />}
                                        SEND EMAIL
                                    </Button>
                                    <Button variant="secondary" className="rounded-0 py-2 px-4" onClick={() => navigate(pageRoutes.LOGIN)}>CANCEL</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default ForgotPassword;