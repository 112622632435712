import Form from "react-bootstrap/Form";
import Title from "../../shared/components/Title";
import "../../assets/scss/scheduler.scss";
import Button from "react-bootstrap/Button";
import refreshIcon from "../../assets/images/refresh.png";
import ScheduleReport from "./ScheduleReport";
import { useEffect, useState } from "react";
import FormRadio from "../../shared/components/form/FormRadio";
import { useForm } from "react-hook-form";
import { reportFormat } from "../../shared/config/array-config";
import FormSelect from "../../shared/components/form/FormSelect";
import FormText from "../../shared/components/form/FormText";
import { useReportDesigner } from "../../shared/hooks-context/report-designer-context-hook";
import { pageRoutes } from "../../shared/constants/constants";

const Scheduler = () => {
  const {
    register,
    setValue,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const [isSchedule, setIsSchedule] = useState(false);
  const [isPreviewDisabled, setIsPreviewDisabled] = useState(true);

  const { userReportsDesignerLists, getUserReportsDesigner } =
    useReportDesigner();

  const reportTemplate = userReportsDesignerLists?.map((data) => ({
    value: data.id,
    label: data.reportName,
  }));

  const { reportId, format, custom_report_name } = watch();

  const reportName = reportTemplate?.find(
    (data) => data?.value === Number(reportId)
  )?.label;

  useEffect(() => {
    let getFetchData = async () => {
      await getUserReportsDesigner();
    };
    getFetchData();
  }, []);

  useEffect(() => {
    if (reportName) {
      setValue("custom_report_name", reportName, { shouldValidate: true });
    }
  }, [reportName, setValue]);

  useEffect(() => {
    // Disable preview button if report template is not selected
    setIsPreviewDisabled(!reportId);
  }, [reportId]);

  const onSchedule = (formData) => {
    if (formData) {
      setIsSchedule(true);
    }
  };

  const data = {
    reportId,
    format,
    custom_report_name,
  };

  const handlePreviewClick = () => {
    const url = `${pageRoutes.REPORT_DESIGNER}/view/${watch("reportId")}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <Title title="Schedule" />
      <div className="card full-card">
        <Form autoComplete="off">
          <div className="report-form">
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center">
                  <FormSelect
                    name="reportId"
                    label="Report Template"
                    options={reportTemplate}
                    register={register}
                    validationSchema={{
                      required: "Report template is required.",
                    }}
                    errors={errors}
                    inlineForm
                  />
                  <Button
                    className="icon-btn ms-2 position-relative"
                    onClick={() =>
                      reset({ reportId: "", custom_report_name: "", format })
                    }
                    style={{
                      top:
                        errors["reportId"]?.type === "required"
                          ? "-34px"
                          : "-12px",
                    }}
                  >
                    <img src={refreshIcon} alt="Refresh Icon" />
                  </Button>
                </div>
                <FormRadio
                  type="radio"
                  name="format"
                  label="Report Format"
                  radioOptions={reportFormat}
                  register={register}
                  validationSchema={{
                    required: "Format is required.",
                  }}
                  errors={errors}
                  inlineForm
                />
                <div className="d-flex align-items-center">
                  <FormText
                    type="text"
                    name="custom_report_name"
                    label="Report Name"
                    placeholder="Enter Report Name"
                    register={register}
                    maxLength={50}
                    validationSchema={{
                      required: "Report name is required.",
                    }}
                    errors={errors}
                    inlineForm
                  />
                </div>
                <div className="report-form__report-grp-btn">
                  <Button
                    variant="secondary"
                    onClick={handlePreviewClick}
                    disabled={isPreviewDisabled} // Disable button if no report template is selected
                  >
                    Preview
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={handleSubmit(onSchedule)}
                  >
                    Schedule
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
        {isSchedule && (
          <div className="row">
            <div className="col-12">
              <ScheduleReport setIsSchedule={setIsSchedule} data={data} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Scheduler;
