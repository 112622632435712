import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ComposeEmailModal from "./ComposeEmailModal";
import { frequencyOptions, radioOptions, repeatModeLists, weekly } from "../../shared/config/array-config";
import { convertBooleanValue, getCurrentDateTime, getCurrentTimezoneUTC, getCurrentTimezoneOffset, setSelectedTimezoneByOffset } from "../../shared/shared-method";
import { useForm } from "react-hook-form";
import FormRadio from "../../shared/components/form/FormRadio";
import FormSelect from "../../shared/components/form/FormSelect";
import FormDateTimePicker from "../../shared/components/form/FormDateTimePicker";
import FormReactSelect from "../../shared/components/form/FormReactSelect";
import { useDistribution } from "../../shared/hooks-context/distribution-context-hook";
import { useScheduler } from "../../shared/hooks-context/scheduler-context-hook";
import FormText from "../../shared/components/form/FormText";
import { useReportDesigner } from "../../shared/hooks-context/report-designer-context-hook";
import { Spinner } from "react-bootstrap";

const ScheduleReport = ({ setIsSchedule, data }) => {
    const { watch, register, reset, setValue, handleSubmit, formState: { errors }, } = useForm({
        mode: "onChange", defaultValues: {
            frequency: "ONCE",
            weekDaysList: [], interval: 0, Distribution: {
                sftp: [],
                aws: []
            }
        }
    });
    const [isSendEmailModal, setIsSendEmailModal] = useState(false);
    const [resetValue, setResetValue] = useState(null);
    const [isClear, setIsClear] = useState(false);
    const { sftpLists, getDistributionSFTP } = useDistribution();
    const { isSchedulerLoading, scheduler } = useScheduler();
    const { timezonesLists, getTimezones } = useReportDesigner();
    const [onceError, setOnceError] = useState("");
    const [recurringStartError, setRecurringStartError] = useState("");
    const [recurringEndError, setRecurringEndError] = useState("");
    const [distributionError, setDistributionError] = useState("");
    const offset = getCurrentTimezoneOffset();
    const abbbr = getCurrentTimezoneUTC();

    useEffect(() => {
        let getFetchData = async () => {
            await getDistributionSFTP();
            await getTimezones();
        };
        getFetchData();
    }, []);

    const sftpOptions = sftpLists?.map((data) => ({
        value: data.id,
        label: data.name,
        subValue: "sftp"
    }))

    const distributionLocations = [
        {
            label: "SFTP",
            options: sftpOptions
        }
    ];

    const timezoneList = timezonesLists?.map((data) => ({
        value: data.value,
        label: data.text,
        offset: data.offset,
        utc: data.utc
    }))

    useEffect(() => {
        setResetValue({
            frequency: "ONCE",
            timezone: setSelectedTimezoneByOffset(offset, abbbr, timezoneList)?.value,
            reportGenerationTime: watch("frequency") === "ONCE" ? new Date() : "",
            startTime: watch("frequency") === "ONCE" ? "" : new Date(),
            endTime: watch("frequency") === "ONCE" ? "" : new Date(),
            repeatMode: "",
            weekDaysList: [],
            isMail: "No",
            mailDistribution: {
                to: [],
                cc: [],
                bcc: [],
                subject: "",
                message: ""
            },
            Distribution: {
                sftp: [],
                aws: []
            },
            interval: 0
        });
    }, [timezonesLists]);

    useEffect(() => {
        reset(resetValue)
    }, [resetValue])

    const selectedButtons = watch("weekDaysList", []);

    const onScheduleReport = async (formData) => {
        const currentTime = new Date();
        let mailDistribution = formData?.mailDistribution;
        if (formData?.isMail === "No" || !formData?.isMail) {
            mailDistribution = {
                to: [],
                subject: "",
                message: "",
                cc: [],
                bcc: []
            };
        }

        let payload = {
            ...data,
            ...formData,
            mailDistribution: mailDistribution,
            startTime: formData?.startTime ? getCurrentDateTime(formData?.startTime) : "",
            endTime: formData?.endTime ? getCurrentDateTime(formData?.endTime) : "",
            reportGenerationTime: formData?.reportGenerationTime ? getCurrentDateTime(formData?.reportGenerationTime) : "",
        }
        if ((!formData?.Distribution || formData?.Distribution?.sftp?.length === 0) && (formData.isMail === "No")) {
            setDistributionError("Please select distribution location or email")
        }
        if (formData?.frequency === "ONCE") {
            if (formData?.reportGenerationTime <= currentTime) {
                setOnceError("Selected time must be greater than the current time")
            } else if ((!formData?.Distribution || formData?.Distribution?.sftp?.length === 0) && (formData.isMail === "No")) {
                setDistributionError("Please select distribution location or email")
            } else {
                const response = await scheduler({
                    ...payload,
                    ...convertBooleanValue(["isMail"], watch),
                })
                if (response?.success) {
                    onResetFormData();
                }
            }
        }

        if (formData?.frequency === "RECURRING") {
            if (formData?.startTime <= currentTime) {
                setRecurringStartError("Selected time must be greater than the current time")
            } else if (formData?.endTime <= formData?.startTime) {
                setRecurringEndError("End time must be greater than start time")
            } else if ((!formData?.Distribution || formData?.Distribution?.sftp?.length === 0) && (formData.isMail === "No")) {
                setDistributionError("Please select distribution location or email")
            } else {
                const response = await scheduler({
                    ...payload,
                    ...convertBooleanValue(["isMail"], watch),
                })
                if (response?.success) {
                    onResetFormData();
                }
            }
        }
    }

    const onChangeRadio = (value) => {
        if (value === "Yes") {
            setIsSendEmailModal(true)
            setDistributionError("");
        }
    }

    const setEmailFormData = (data) => {
        setValue("mailDistribution[to]", data?.to ? data?.to : [])
        setValue("mailDistribution[subject]", data?.subject)
        setValue("mailDistribution[message]", data?.message)
        setValue("mailDistribution[cc]", data?.cc ? data?.cc : [])
        setValue("mailDistribution[bcc]", data?.bcc ? data?.bcc : [])
    }

    const onResetFormData = () => {
        setIsClear(true);
        reset();
    }

    return (
        <>
            <div className="schedule-report-form">
                <div className="position-relative">
                    <h2 className="schedule-report-form__title">Schedule Report</h2>
                    <button type="button" className="btn-close schedule-report-form__close-button" aria-label="Close" onClick={() => setIsSchedule(false)} />
                </div>
                <Form autoComplete="off">
                    <div className="row pt-5">
                        <div className="col-lg-4 col-12 pb-5">
                            <FormRadio
                                type="radio"
                                name="frequency"
                                label="Frequency"
                                radioOptions={frequencyOptions}
                                setValue={setValue}
                                register={register}
                                validationSchema={{
                                    required: "Frequency is required.",
                                }}
                                errors={errors}
                                inlineForm
                                isReset={isClear}
                                defaultValues={watch("frequency")}
                            />
                            {watch("frequency") === "RECURRING" &&
                                <>
                                    <FormSelect
                                        name="timezone"
                                        label="Time Zone"
                                        options={timezoneList}
                                        register={register}
                                        validationSchema={watch("frequency") === "RECURRING" && {
                                            required: "TimeZone is required.",
                                        }}
                                        errors={errors}
                                        inlineForm
                                    />
                                    <FormDateTimePicker
                                        label="Start Time"
                                        inlineForm
                                        name="startTime"
                                        setError={setRecurringStartError}
                                        errors={recurringStartError}
                                        setValue={setValue}
                                        register={register}
                                        isClear={isClear} setIsClear={setIsClear}
                                    />
                                    <FormDateTimePicker
                                        label="End Time"
                                        inlineForm
                                        name="endTime"
                                        setError={setRecurringEndError}
                                        errors={recurringEndError}
                                        setValue={setValue}
                                        register={register}
                                        isClear={isClear} setIsClear={setIsClear}
                                    />
                                </>
                            }
                        </div>
                        <div className="col-lg-4 col-12 pb-5">
                            {watch("frequency") === "RECURRING" ? <>
                                <FormSelect
                                    name="repeatMode"
                                    label="Repeat"
                                    options={repeatModeLists}
                                    register={register}
                                    validationSchema={{
                                        required: "Repeat is required.",
                                    }}
                                    errors={errors}
                                    inlineForm
                                />
                                <div className=" d-flex align-items-center">
                                    <FormText type="number" widthClass="w-120" label="Repeat Every" name="interval" register={register} inlineForm />
                                    <span className="ms-3 fw-bold mb-4" style={{ color: "#757575" }}>{watch("repeatMode") === "Weekly" ? "Week(s)" : watch("repeatMode") === "Daily" ? "Day(s)" : "Hour(s)"}</span>
                                </div>
                                {watch("repeatMode") === "Weekly" &&
                                    <Form.Group className="d-flex align-items-start mb-4 inline-form">
                                        <Form.Label className="inline-form__label">
                                            <span>Repeat On</span><span>:</span>
                                        </Form.Label>
                                        <div className="schedule-report-job-form__controls d-flex align-items-center">
                                            <div className="weekly-name d-flex align-items-center flex-wrap">
                                                {weekly.map((week, index) => (
                                                    <Button
                                                        key={index}
                                                        className={`weekly-name__btn ${selectedButtons.includes(week.value) ? "active" : ""}`}
                                                        variant="outline-primary"
                                                        onClick={() => {
                                                            const newSelectedButtons = selectedButtons.includes(week.value)
                                                                ? selectedButtons.filter((btn) => btn !== week.value)
                                                                : [...selectedButtons, week.value];
                                                            setValue("weekDaysList", newSelectedButtons);
                                                        }}
                                                    >
                                                        {week.label}
                                                    </Button>
                                                ))}
                                            </div>
                                        </div>
                                    </Form.Group>
                                }
                            </> : watch("frequency") === "ONCE" ?
                                <>
                                    <FormSelect
                                        name="timezone"
                                        label="Time Zone"
                                        options={timezoneList}
                                        register={register}
                                        validationSchema={watch("frequency") === "ONCE" && {
                                            required: "TimeZone is required.",
                                        }}
                                        errors={errors}
                                        inlineForm
                                    />
                                    <FormDateTimePicker label="Report Generation Time" inlineForm name="reportGenerationTime"
                                        setError={setOnceError}
                                        errors={onceError} setValue={setValue} register={register} isClear={isClear} setIsClear={setIsClear}
                                    />
                                </> : null
                            }
                        </div>
                        <div className="col-lg-4 col-12 pb-5">
                            <FormReactSelect label="Distribution Location" inlineForm name="Distribution" options={distributionLocations} placeholder="Select Distribution" setValue={setValue}
                                register={register} setError={setDistributionError} isClear={isClear} setIsClear={setIsClear} />
                            <FormRadio
                                type="radio"
                                name="isMail"
                                label="Send Email"
                                radioOptions={radioOptions}
                                register={register}
                                validationSchema={{
                                    required: "Send email is required.",
                                }}
                                errors={errors}
                                inlineForm
                                setValue={setValue}
                                onChangeRadio={onChangeRadio}
                                isReset={isClear}
                                defaultValues={watch("isMail")}
                            />
                            {distributionError && (
                                <p className={`text-danger text-sm pt-1`}>{distributionError}</p>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex align-items-center">
                            <Button variant="secondary me-4" disabled={isSchedulerLoading.isLoading} onClick={handleSubmit(onScheduleReport)}>{isSchedulerLoading.isLoading && <Spinner
                                className="me-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />}Schedule</Button>
                            <Button variant="secondary" onClick={() => onResetFormData()}>Reset</Button>
                        </div>
                    </div>
                </Form>
            </div>
            <ComposeEmailModal show={isSendEmailModal} setEmailFormData={setEmailFormData} setIsSendEmailModal={setIsSendEmailModal}
                onHide={() => {
                    setIsSendEmailModal(false)
                    setValue("isMail", "No")
                }} isNotSendMail={watch("isMail")} />
        </>
    )
}

export default ScheduleReport;