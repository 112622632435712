import FormText from "../../../shared/components/form/FormText";

const AWS = ({ register, errors }) => {
    return (
        <>
            <FormText
                type="text"
                name="bucketName"
                label="Bucket Name"
                placeholder="Enter Bucket Name"
                register={register}
                validationSchema={{
                    required: "Bucket name is required.",
                }}
                errors={errors}
                inlineForm />
            <FormText
                type="text"
                name="accessKey"
                label="Access Key"
                placeholder="Enter Access Key"
                register={register}
                validationSchema={{
                    required: "Access key is required.",
                }}
                errors={errors}
                inlineForm />
            <FormText
                type="text"
                name="secretkey"
                label="Enter Secret Key"
                placeholder="Enter Secret Key"
                register={register}
                validationSchema={{
                    required: "Secret Key is required.",
                }}
                errors={errors}
                inlineForm />
            <FormText
                type="text"
                name="region"
                label="Region"
                placeholder="Enter Region"
                register={register}
                validationSchema={{
                    required: "Region is required.",
                }}
                errors={errors}
                inlineForm />
        </>
    )
}

export default AWS;