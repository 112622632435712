import React, { useEffect, useRef } from "react";
import { DxReportViewer } from "devexpress-reporting/dx-webdocumentviewer";
import { useParams } from "react-router-dom";
import { BASE_URL, REPORT_BASE_URL } from "../..";
import notify from "devextreme/ui/notify";
import { ajaxSetup } from "@devexpress/analytics-core/analytics-utils";
import { tokens } from "../../shared/constants/constants";

const ReportViewer = () => {
  const params = useParams();
  const reportUrl = JSON.stringify({ reportId: 5 });
  const viewerRef = useRef();

  const requestOptions =
    window.location.hostname === "localhost"
      ? {
          host: `${process.env.REACT_APP_API_URL}/`,
          invokeAction: `DXXRDV`,
        }
      : {
          host: `${process.env.REACT_APP_API_URL}`.replace("/api", "/"),
          invokeAction: `api/DXXRDV`,
        };

  useEffect(() => {
    ajaxSetup.ajaxSettings = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(tokens.AUTH_TOKEN)}`,
      },
      beforeSend: (requestParameters) => {
        requestParameters.credentials = "include";
      },
    };
    let viewer;
    viewer = new DxReportViewer(viewerRef.current, {
      reportUrl: JSON.stringify({
        reportId: params.reportId,
        preview: true,
      }),
      requestOptions,

      callbacks: {
        onDocumentReady: (e) => {
          //alert("Document is ready:", e);
        },
        OnServerError: (error) => {
          console.log(error);
          if (error) {
            if (error.jqXHR) {
              const { responseJSON } = error.jqXHR;
              notify(
                responseJSON?.message || responseJSON?.error,
                "error",
                100000
              );
            }
          }
          // ... your error handling code ...
        },
        CustomizeExportOptions: (s, e) => {
          OnWebDocumentViewerInit(s, e);
        },
      },
    });
    viewer.render();

    return () => {
      viewer?.dispose();
    };
  }, [params]);

  return <div ref={viewerRef}></div>;
};

export default ReportViewer;

export const OnWebDocumentViewerInit = (s, e) => {
  var currentExportOptions =
    s.GetPreviewModel().reportPreview.exportOptionsModel;
  var optionsUpdating = false;
  var fixExportOptions = function (options) {
    try {
      optionsUpdating = true;
      if (!options) {
        currentExportOptions(null);
      } else {
        delete options["csv"];
        delete options["xls"];
        delete options["mht"];
        delete options["html"];
        delete options["textExportOptions"];
        delete options["rtf"];
        delete options["image"];
        delete options["docx"];
        currentExportOptions(options);
      }
    } finally {
      optionsUpdating = false;
    }
  };
  currentExportOptions.subscribe(function (newValue) {
    !optionsUpdating && fixExportOptions(newValue);
  });
  fixExportOptions(currentExportOptions());
};
