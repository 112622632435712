import { jwtDecode } from "jwt-decode";
import notify from "devextreme/ui/notify";

export const validEmail = (value) => {
  let errors;
  const validEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;

  if (!validEmail.test(value)) {
    return "Please enter a valid email";
  }

  return errors;
};

export const validatePwd = (password) => {
  let errors;
  const validPassword =
    /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9]).{7,7}/;
  if (validPassword.test(password) === false) {
    return "Password must contain Uppercase, Lowercase, Number and special symbol(@/$/&)";
  } else if (password.length < 8) {
    return "Password must have 8 characters";
  }
  return errors;
};

export const validateConnection = (obj) => {
  var result = false;
  if (!obj) notify("Invalid Request", "warning");
  // else if (!obj.newReportName || String(obj.newReportName).trim() == "") {
  //   notify("Template name is required.", "warning");
  // }
  else if (!obj.accountName || String(obj.accountName).trim() == "") {
    notify("Account name is missing", "warning");
  } else if (!obj.sfUserName || String(obj.sfUserName).trim() == "") {
    notify("UserName is missing", "warning");
  } else if (!obj.sfPassword || String(obj.sfPassword).trim() == "") {
    notify("Password is missing", "warning");
  } else if (!obj.sourceName || String(obj.sourceName).trim() == "") {
    notify("Connection name is missing", "warning");
  } else if (!obj.Role || String(obj.Role).trim() == "") {
    notify("Role is missing", "warning");
  } else if (!obj.Database || String(obj.Database).trim() == "") {
    notify("Database is missing", "warning");
  } else if (!obj.Schema || String(obj.Schema).trim() == "") {
    notify("Schema is missing", "warning");
  } else if (!obj.Tables || String(obj.Tables).trim() == "") {
    notify("Tables is missing", "warning");
  } else {
    result = true;
  }
  return result;
};

export const validateDateTime = (date) => {
  const selectedTime = new Date(date);
  const currentTime = new Date();
  if (selectedTime <= currentTime) {
    return "Selected time must be greater than the current time";
  }
};

export const validateEndDateTime = (date, startTime) => {
  const selectedTime = new Date(date);
  const currentTime = new Date();
  if (selectedTime <= currentTime) {
    return "Selected time must be greater than the current time";
  } else if (selectedTime > startTime) {
    return "End time must be greater than start time";
  }
};

export const token = () => {
  return localStorage.getItem("auth_token");
};

export const userInfo = () => {
  const current_user = localStorage.getItem("auth_token");
  if (!current_user) {
    return {};
  }
  try {
    if (current_user) {
      return jwtDecode(current_user);
    } else {
      return {};
    }
  } catch (e) {}
};

export const emailCharterLimit = (value) => {
  if (value.length >= 255) {
    return `(${value.length}/255) maximum character length exceeded.`;
  }
};

export const hostCharterLimit = (value) => {
  if (value.length >= 260) {
    return `(${value.length}/260) maximum character length exceeded.`;
  }
};

export const nameCharterLimit = (value) => {
  if (value.length >= 50) {
    return `(${value.length}/50) maximum character length exceeded.`;
  }
};
