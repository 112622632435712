import { useEffect, useMemo, useState } from "react";
import ReactDataTable from "../../shared/components/table/ReactDataTable";
import { userManagementColumns } from "../../shared/constants/data-columns";
import SearchFilter from "../../shared/components/table/SearchFilter";
import { Button, Form } from "react-bootstrap";
import Title from "../../shared/components/Title";
import AddUserModal from "./AddUserModal";
import { useUserManagement } from "../../shared/hooks-context/user-management-context-hook";
import ReactSweetAlert from "../../shared/components/ReactSweetAlert";

const UserManagement = () => {
    const { isLoading, userLists, getUser, activeInactiveUser, deleteUser } = useUserManagement();
    const [user, setUser] = useState({ isModal: false, id: [], name: "" });
    const [filterText, setFilterText] = useState("");
    const [isAddUserModal, setIsAddUserModal] = useState(false);

    const filteredItems = userLists?.filter((item) => {
        return item?.username?.toLowerCase().includes(filterText.toLowerCase()) ||
            item?.name?.toLowerCase().includes(filterText.toLowerCase());
    });

    const onFilter = (value) => {
        setFilterText(value);
    };

    useEffect(() => {
        let getFetchData = async () => {
            await getUser();
        };
        getFetchData();
    }, [])

    const subHeaderComponent = useMemo(() => {
        return (
            <div className="d-flex align-items-center justify-content-between w-100">
                <div className="text-start pe-3">
                    <Button variant="secondary" onClick={() => setIsAddUserModal(true)}>Add User</Button>
                </div>
                <div className="text-end">
                    <Form className="d-flex align-items center">
                        <SearchFilter
                            onFilter={onFilter}
                            filterText={filterText}
                        />
                    </Form>
                </div>
            </div>
        );
    }, [filterText]);

    const onOpenUserDeleteModal = (item) => {
        if (item) {
            setUser({ isModal: true, id: item?.id, name: item?.name });
        }
    };

    const onCancelUserModal = () => {
        setUser({ isModal: false, id: [], name: "" });
    }

    const onDeleteUser = async () => {
        const response = await deleteUser({ id: user?.id });
        if (response?.success) {
            onCancelUserModal();
        }
    }

    const onUserActiveInactive = async (id) => {
        await activeInactiveUser({ id })
    }

    const columns = userManagementColumns({ onUserActiveInactive, onOpenUserDeleteModal });

    return (
        <>
            <Title title="User Management" />
            <div className="card-table user-management">
                <ReactDataTable columns={columns}
                    data={filteredItems}
                    selectableRows
                    subHeader={true}
                    subHeaderComponent={subHeaderComponent}
                    progressPending={isLoading?.isList}
                    pagination
                />
            </div >
            <AddUserModal show={isAddUserModal} onHide={() => setIsAddUserModal(false)} />
            {user?.isModal && (
                <ReactSweetAlert
                    onDeleteModal={onDeleteUser}
                    onCancelModal={onCancelUserModal}
                    name={user?.name}
                />
            )}
        </>
    )
}

export default UserManagement;