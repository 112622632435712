import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const FormRadio = (props) => {
    const { name, label, register, errors, required, validationSchema, radioOptions, inlineForm, onChangeRadio, isClear, setValue, setIsRadioClear, defaultValues, isReset } = props;
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        if (isClear) {
            setSelectedValue(null);
            if (setValue) {
                setValue(name, "")
            }
        }
    }, [isClear])

    useEffect(() => {
        if (isReset) {
            setSelectedValue(defaultValues);
        }
    }, [isReset])

    useEffect(() => {
        if (defaultValues !== null || defaultValues !== "undefined") {
            setSelectedValue(defaultValues)
        }
    }, [defaultValues])

    useEffect(() => {
        if (selectedValue) {
            setSelectedValue(selectedValue)
        }
    }, [selectedValue])


    return (
        <div className="mb-4">
            <div className={`${inlineForm ? "d-flex align-items-center" : ""}`}>
                <p className={`${inlineForm ? "inline-form__label mb-0 form-label" : "block"}`} >
                    <span>{label}</span> {required && <span className="text-danger">*</span>} {inlineForm && <span>:</span>}
                </p>
                <div className={`${inlineForm ? "inline-form__input w-100" : ""}`}>
                    {radioOptions?.map((option, idx) => {
                        return <label htmlFor={`${option?.value}-${name}`} className="me-2" key={idx}>
                            <input
                                {...register(name, validationSchema)}
                                type="radio"
                                name={name}
                                value={option?.value}
                                className="form-check-input me-2 box-shadow-none"
                                id={`${option?.value}-${name}`}
                                checked={option?.value === selectedValue}
                                onChange={(event) => {
                                    setSelectedValue(event.target.value)
                                    if (setIsRadioClear) {
                                        setIsRadioClear(false)
                                    }
                                    if (setValue) {
                                        setValue(name, event.target.value)
                                    }
                                    if (onChangeRadio) {
                                        onChangeRadio(event.target.value)
                                    }
                                }}
                            />
                            {option?.label}
                        </label>
                    })}
                </div>
            </div>
            {selectedValue === null || selectedValue === undefined && errors && (errors[name]?.type === "required" || errors[name]?.type === "validate") && (
                <p className={`${inlineForm && "inline-form__error"} text-danger text-sm pt-1`}>{errors[name]?.message}</p>
            )}
        </div>
    );
};

FormRadio.propTypes = {
    radioOptions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.any.isRequired
        })
    ),
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    inlineForm: PropTypes.bool,
    errors: PropTypes.object,
    validationSchema: PropTypes.object,
    register: PropTypes.any.isRequired,
    onChangeRadio: PropTypes.func,
    isClear: PropTypes.bool,
    setValue: PropTypes.func,
};

export default FormRadio;
