import PropTypes from 'prop-types';
import { Form } from "react-bootstrap";

const FormSelect = (props) => {
    const { name, label, register, errors, required, validationSchema, options, inlineForm } = props;
    return (
        <div className="mb-4 w-100">
            <Form.Group className={`${inlineForm ? "d-flex align-items-center" : ""}`}>
                {label &&
                    <Form.Label className={`${inlineForm ? "inline-form__label" : "block"}`} htmlFor={name} >
                        <span>{label}</span> {required && <span className="text-danger">*</span>} {inlineForm && <span>:</span>}
                    </Form.Label>
                }
                <div className={`${inlineForm ? "inline-form__input w-100" : ""}`}>
                    <Form.Select
                        id={name}
                        name={name}
                        {...register(name, validationSchema)}
                    >
                        <option value="">Please Select</option>
                        {options?.map((option, idx) => {
                            return (
                                <option
                                    key={idx}
                                    value={option.value}
                                >
                                    {option.label}
                                </option>
                            )
                        }
                        )}
                    </Form.Select >
                </div>
            </Form.Group>
            {errors && (errors[name]?.type === "required" || errors[name]?.type === "validate") && (
                <p className={`${inlineForm && "inline-form__error"} text-danger text-sm pt-1`}>{errors[name]?.message}</p>
            )}
        </div>
    )
};

FormSelect.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.any.isRequired
        })
    ),
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    inlineForm: PropTypes.bool,
    errors: PropTypes.object.isRequired,
    validationSchema: PropTypes.object.isRequired,
    register: PropTypes.any.isRequired,
};

export default FormSelect;
