import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./assets/scss/style.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ProvidersWrapper from "./shared/components/ProvidersWrapper";
import AlertNotification from "./shared/components/Alert";

export const BASE_URL = `${process.env.REACT_APP_API_URL}`;
export const REPORT_BASE_URL = `${process.env.REACT_APP_API_URL}`.replace(
  "/api",
  "/"
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ProvidersWrapper>
      <App />
      <AlertNotification />
    </ProvidersWrapper>
  </BrowserRouter>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
