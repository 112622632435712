import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import "../../assets/scss/layout.scss";

const Layout = ({ children }) => {
    return (
        <>
            <Topbar />
            <div className="d-flex align-items-stretch wrapper">
                <Sidebar />
                <main className="main">{children}</main>
            </div >
        </>
    )
}

export default Layout;