import { useEffect, useReducer, useState } from "react";
import "./dxreport.scss";
import { Button, Modal } from "react-bootstrap";
import ConnectionForm from "./ConnetionForm";
import ReportEngine from "./ReportEngine";
import Title from "../../shared/components/Title";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { pageRoutes } from "../../shared/constants/constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useReportDesigner } from "../../shared/hooks-context/report-designer-context-hook";
import ChangeConnectionModal from "../report-designer/ChangeConnectionModal";
import { validateConnection } from "../../shared/utils/validation";
import AppLoading from "../../shared/components/loader/AppLoader";
// Action types
export const NEXT_STEP = "NEXT_STEP";
export const PREV_STEP = "PREV_STEP";
export const UPDATE_FIELD = "UPDATE_FIELD";
export const OPEN_POPUP = "OPEN_POPUP";
export const CLOSE_POPUP = "CLOSE_POPUP";
export const UPDATE_REPORT_BODY = "UPDATE_REPORT_BODY";
export const UPDATE_ACCOUNT_INPUTS = "UPDATE_ACCOUNT_INPUTS";
export const UPDATE_ACCOUNT_INFO = "UPDATE_ACCOUNT_INFO";
export const UPDATE_CONNECTIONS_LIST = "UPDATE_CONNECTIONS_LIST";

export const UPDATE_META_INPUTS = "UPDATE_META_INPUTS";
export const LOADING = "LOADING";
export const SET_DATASOURCE_ID = "SET_DATASOURCE_ID";
export const SET_DATASOURCE = "SET_DATASOURCE";
export const SKIP_FINISH = "SKIP_FINISH";
export const IS_COPY_REPORT = "IS_COPY_REPORT";
export const IS_CHANGE_CONNECTION = "IS_CHANGE_CONNECTION";

// Reducer function
export const formReducer = (state, action) => {
  switch (action.type) {
    case NEXT_STEP:
      // return { ...state, step: state.step > 2 ? 2 : state.step + 1 };
      return { ...state, step: 2 };

    case PREV_STEP:
      // return { ...state, step: state.step - 1 };
      return { ...state, step: 1 };

    case OPEN_POPUP:
      return { ...state, connectionModal: true };
    case CLOSE_POPUP:
      return {
        ...state,
        connectionModal: false,
        AccountInfo: {},
        MetaInfo: {},
        step: 1,
      };
    case UPDATE_FIELD:
      return {
        ...state,
        formData: { ...state.formData, [action.field]: action.value },
      };
    case UPDATE_ACCOUNT_INPUTS:
      return {
        ...state,
        AccountInfo: { ...state.AccountInfo, [action.field]: action.value },
      };

    case UPDATE_ACCOUNT_INFO:
      return {
        ...state,
        AccountInfo: action.payload,
      };
    case UPDATE_META_INPUTS:
      return {
        ...state,
        MetaInfo: { ...state.MetaInfo, [action.field]: action.value },
      };

    case UPDATE_CONNECTIONS_LIST:
      return {
        ...state,
        ConnectionsList: action.payload,
      };
    case UPDATE_REPORT_BODY:
      return {
        ...state,
        reportRequestBody: { ...action.value },
      };
    case LOADING:
      return {
        ...state,
        loading: action.value,
      };
    case SET_DATASOURCE_ID:
      return {
        ...state,
        dataSourceId: action.value,
      };
    case SET_DATASOURCE:
      return {
        ...state,
        dataSource: action.payload,
      };
    case SKIP_FINISH:
      return {
        ...state,
        skip_finish: action.value,
      };
    case IS_COPY_REPORT:
      return {
        ...state,
        copy_report: action.value,
      };
    case IS_CHANGE_CONNECTION:
      return {
        ...state,
        change_connection: action.value,
      };
    default:
      return state;
  }
};
export const initialState = {
  step: 1,
  connectionModal: false,
  ConnectionsList: [],
  reportRequestBody: {},
  AccountInfo: {
    accountName: "",
    sfUserName: "",
    sfPassword: "",
    sourceName: "",
  },
  MetaInfo: {
    Role: "",
    Database: "",
    Schema: "",
    Warehouse: "",
    Table: "",
  },
  loading: false,
  dataSourceId: "",
  dataSource: null,
  skip_finish: false,
  copy_report: false,
  change_connection: false,
};

const DxReport = () => {
  const { reportCheckConnection, setReportCheckConnection, updateConnection, getCheckConnection } = useReportDesigner();
  const [state, dispatch] = useReducer(formReducer, initialState);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  console.log('params', params)
  const [isConnectionModal, setConnectionString] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = () => {
      setPageLoading(true);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (reportCheckConnection && !reportCheckConnection?.success) {
      setConnectionString(true);
    }
  }, [reportCheckConnection])

  useEffect(() => {
    let getFetchData = async () => {
      if (params?.reportId) {
        await getCheckConnection({ reportId: params?.reportId });
      }
    };
    getFetchData();

    return () => {
      setReportCheckConnection(null)
    }
  }, []);

  const onCancelConnectionModal = () => {
    setConnectionString(false);
    navigate(pageRoutes.REPORT_DESIGNER);
  }

  const updateDataSource = () => {
    const obj = { ...state.AccountInfo, ...state.MetaInfo };
    obj["Tables"] =
      Array.isArray(state.MetaInfo.Tables) && state.MetaInfo.Tables.length > 0
        ? state.MetaInfo.Tables.map((x) => x.value)
        : [];
    return obj;
  };

  const onChangeConnection = async () => {
    let reportRequestBody = updateDataSource();
    if (validateConnection(reportRequestBody) == true) {
      reportRequestBody["connectionObj"] = JSON.stringify(reportRequestBody);
      dispatch({ type: LOADING, value: true });
      const result = await updateConnection(reportRequestBody);
      if (result?.success) {
        setConnectionString(false);
        dispatch({ type: LOADING, value: false });
        dispatch({ type: CLOSE_POPUP, value: false });
        dispatch({ type: IS_CHANGE_CONNECTION, value: false });
        window.location.reload();

      } else {
        dispatch({ type: LOADING, value: false });
      }
    }
  };

  useEffect(() => {
    const btn = document.getElementById("changeConnectionSubmitBtn");
    const clickHandler = (e) => {
      e.preventDefault();
      onChangeConnection();
    };

    if (state.step === 2) {
      btn?.addEventListener("click", clickHandler);
    }

    return () => {
      if (state.step === 2) {
        btn?.removeEventListener("click", clickHandler);
      }
    };
  }, [state.step, onChangeConnection]);

  return (
    <>
      <Title title="Report Designer" />
      {pageLoading ? <AppLoading /> : <>
        <div className="card px-0 pb-0 report-designer-card">
          <Breadcrumb className="px-4">
            <Breadcrumb.Item onClick={() => navigate(pageRoutes.REPORT_DESIGNER)}>
              Report Designer
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {location?.pathname.includes("edit")
                ? "Edit Template"
                : "Create New"}
            </Breadcrumb.Item>
          </Breadcrumb>
          {location?.pathname.includes("edit") && reportCheckConnection?.success && (
            <ReportEngine
              dispatch={dispatch}
              state={state}
              openPopup={(value) => {
                dispatch({ type: OPEN_POPUP });
              }} />
          )}
          {location?.pathname.includes("create") && (
            <ReportEngine
              dispatch={dispatch}
              state={state}
              openPopup={(value) => {
                dispatch({ type: OPEN_POPUP });
              }} />
          )}
          <Modal
            style={{
              maxHeight: "100vh",
            }}
            show={state.connectionModal}
            size="lg"
          >
            <Modal.Header
              closeButton
              onClick={() => {
                dispatch({ type: CLOSE_POPUP });
              }}
            >
              <Modal.Title>Connection </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ConnectionForm dispatch={dispatch} state={state} />
            </Modal.Body>
          </Modal>
        </div>
        <ChangeConnectionModal show={isConnectionModal} onHide={onCancelConnectionModal} openConnection={() => {
          dispatch({ type: OPEN_POPUP });
          dispatch({ type: SET_DATASOURCE_ID, value: reportCheckConnection?.data?.id });
          dispatch({ type: IS_CHANGE_CONNECTION, value: true });
        }} />
      </>}
    </>
  );
};

export default DxReport;